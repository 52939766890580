module.exports={
  "bettercare": {
    "title": "General help and advice",
    "links": [
      {
        "title": "Arrangement fees",
        "fileName": "bettercare/TODO.md",
        "whitelist": [
          "demo"
        ]
      },
      {
        "title": "Paying for your own care",
        "fileName": "customer/southampton/cfa_paying_for_own_care.md",
        "whitelist": [
          "southampton"
        ]
      },
      {
        "title": "Arrangement fees and paying for your own care",
        "fileName": "customer/wakefield/cfa_paying_for_own_care.md",
        "whitelist": [
          "wakefield"
        ]
      },
      {
        "title": "Paying for your own care",
        "fileName": "customer/doncaster/cfa_paying_for_own_care.md",
        "whitelist": [
          "doncaster"
        ]
      },
      {
        "title": "Paying for your own care",
        "fileName": "customer/ealing/cfa_paying_for_own_care.md",
        "whitelist": [
          "ealing"
        ]
      },
      {
        "title": "Paying for your own care",
        "fileName": "customer/walsall/cfa_paying_for_own_care.md",
        "whitelist": [
          "walsall"
        ]
      },
      {
        "title": "Paying for your own care",
        "fileName": "customer/wokingham/cfa_paying_for_own_care.md",
        "whitelist": [
          "wokingham"
        ]
      },
      {
        "title": "Paying for your own care",
        "fileName": "customer/wolverhampton/cfa_paying_for_own_care.md",
        "whitelist": [
          "wolverhampton"
        ]
      },
      {
        "title": "Paying for your own care",
        "fileName": "customer/calderdale/cfa_paying_for_own_care.md",
        "whitelist": [
          "calderdale"
        ]
      },
      {
        "title": "Paying for your own care",
        "fileName": "customer/cornwall/cfa_paying_for_own_care.md",
        "whitelist": [
          "cornwall"
        ]
      },
      {
        "title": "Paying for your own care",
        "fileName": "customer/westnorthants/cfa_paying_for_own_care.md",
        "whitelist": [
          "westnorthants"
        ]
      },
      {
        "title": "Paying for your own care",
        "fileName": "customer/blackpool/cfa_paying_for_own_care.md",
        "whitelist": [
          "blackpool"
        ]
      },
      {
        "title": "Paying for your own care",
        "fileName": "customer/warwickshire/cfa_paying_for_own_care.md",
        "whitelist": [
          "warwickshire"
        ]
      },
      {
        "title": "Paying for your own care",
        "fileName": "customer/nottinghamshire/cfa_paying_for_own_care.md",
        "whitelist": [
          "nottinghamshire"
        ]
      },
      {
        "title": "Paying for your own care",
        "fileName": "customer/halton/cfa_paying_for_own_care.md",
        "whitelist": [
          "halton"
        ]
      },
      {
        "title": "Paying for your own care",
        "fileName": "customer/hampshire/cfa_paying_for_own_care.md",
        "whitelist": [
          "hampshire"
        ]
      },
      {
        "title": "Paying for your own care",
        "fileName": "customer/somerset/cfa_paying_for_own_care.md",
        "whitelist": [
          "somerset"
        ]
      },
      {
        "title": "Paying for care",
        "fileName": "customer/cambridgeshire/cfa_paying_for_own_care.md",
        "whitelist": [
          "cambridgeshire"
        ]
      },
      {
        "title": "Paying for your own care",
        "fileName": "customer/peterborough/cfa_paying_for_own_care.md",
        "whitelist": [
          "peterborough"
        ]
      },
      {
        "title": "Paying for your own care",
        "fileName": "customer/southglos/cfa_paying_for_own_care.md",
        "whitelist": [
          "southglos"
        ]
      },
      {
        "title": "Paying for your own care",
        "fileName": "customer/torbay/cfa_paying_for_own_care.md",
        "whitelist": [
          "torbay"
        ]
      },
      {
        "title": "Self-funding",
        "fileName": "customer/northlincs/nlincs_cfa_self_funders.md",
        "whitelist": [
          "northlincs"
        ]
      },
      {
        "title": "Administration charges for self-funders",
        "fileName": "customer/coventry/cov_fca_admin_charges.md",
        "whitelist": [
          "coventry"
        ]
      },
      {
        "title": "Evidence",
        "fileName": "bettercare/TODO.md",
        "whitelist": [
          "demo"
        ]
      },
      {
        "title": "Evidence",
        "fileName": "customer/northlincs/nlincs_cfa_evidence.md",
        "whitelist": [
          "northlincs"
        ]
      },
      {
        "title": "Evidence",
        "fileName": "customer/coventry/cov_fca_evidence.md",
        "whitelist": [
          "coventry"
        ]
      },
      {
        "title": "Evidence",
        "fileName": "customer/southampton/cfa_evidence.md",
        "whitelist": [
          "southampton"
        ]
      },
      {
        "title": "Evidence",
        "fileName": "customer/wakefield/cfa_evidence.md",
        "whitelist": [
          "wakefield"
        ]
      },
      {
        "title": "Evidence",
        "fileName": "customer/doncaster/cfa_evidence.md",
        "whitelist": [
          "doncaster"
        ]
      },
      {
        "title": "Evidence",
        "fileName": "customer/ealing/cfa_evidence.md",
        "whitelist": [
          "ealing"
        ]
      },
      {
        "title": "Evidence",
        "fileName": "customer/blackpool/cfa_evidence.md",
        "whitelist": [
          "blackpool"
        ]
      },
      {
        "title": "Evidence",
        "fileName": "customer/warwickshire/cfa_evidence.md",
        "whitelist": [
          "warwickshire"
        ]
      },
      {
        "title": "Evidence",
        "fileName": "customer/nottinghamshire/cfa_evidence.md",
        "whitelist": [
          "nottinghamshire"
        ]
      },
      {
        "title": "Evidence",
        "fileName": "customer/walsall/cfa_evidence.md",
        "whitelist": [
          "walsall"
        ]
      },
      {
        "title": "Evidence",
        "fileName": "customer/wokingham/cfa_evidence.md",
        "whitelist": [
          "wokingham"
        ]
      },
      {
        "title": "Evidence",
        "fileName": "customer/wolverhampton/cfa_evidence.md",
        "whitelist": [
          "wolverhampton"
        ]
      },
      {
        "title": "Evidence",
        "fileName": "customer/calderdale/cfa_evidence.md",
        "whitelist": [
          "calderdale"
        ]
      },
      {
        "title": "Evidence",
        "fileName": "customer/cornwall/cfa_evidence.md",
        "whitelist": [
          "cornwall"
        ]
      },
      {
        "title": "Evidence",
        "fileName": "customer/westnorthants/cfa_evidence.md",
        "whitelist": [
          "westnorthants"
        ]
      },
      {
        "title": "Evidence",
        "fileName": "customer/halton/cfa_evidence.md",
        "whitelist": [
          "halton"
        ]
      },
      {
        "title": "Evidence",
        "fileName": "customer/hampshire/cfa_evidence.md",
        "whitelist": [
          "hampshire"
        ]
      },
      {
        "title": "Evidence",
        "fileName": "customer/somerset/cfa_evidence.md",
        "whitelist": [
          "somerset"
        ]
      },
      {
        "title": "Evidence",
        "fileName": "customer/peterborough/cfa_evidence.md",
        "whitelist": [
          "peterborough"
        ]
      },
      {
        "title": "Evidence",
        "fileName": "customer/cambridgeshire/cfa_evidence.md",
        "whitelist": [
          "cambridgeshire"
        ]
      },
      {
        "title": "Sending evidence to us",
        "fileName": "customer/southglos/cfa_evidence.md",
        "whitelist": [
          "southglos"
        ]
      },
      {
        "title": "Evidence",
        "fileName": "customer/torbay/cfa_evidence.md",
        "whitelist": [
          "torbay"
        ]
      },
      {
        "title": "Deferred payments",
        "fileName": "bettercare/TODO.md",
        "whitelist": [
          "demo"
        ]
      },
      {
        "title": "Deferred payments scheme",
        "fileName": "customer/northlincs/nlincs_cfa_deferred_payments.md",
        "whitelist": [
          "northlincs"
        ]
      },
      {
        "title": "Deferred payments",
        "fileName": "customer/coventry/cov_fca_deferred_payments.md",
        "whitelist": [
          "coventry"
        ]
      },
      {
        "title": "Deferred payments loan scheme and third party top-ups",
        "fileName": "customer/southampton/cfa_alternative_payments.md",
        "whitelist": [
          "southampton"
        ]
      },
      {
        "title": "Deferred payments and third party top-ups",
        "fileName": "customer/wakefield/cfa_alternative_payments.md",
        "whitelist": [
          "wakefield"
        ]
      },
      {
        "title": "Deferred payments and third party top-ups",
        "fileName": "customer/doncaster/cfa_alternative_payments.md",
        "whitelist": [
          "doncaster"
        ]
      },
      {
        "title": "Deferred payments",
        "fileName": "customer/southglos/cfa_deferred_payments.md",
        "whitelist": [
          "southglos"
        ]
      },
      {
        "title": "Deferred payments",
        "fileName": "customer/torbay/cfa_deferred_payments.md",
        "whitelist": [
          "torbay"
        ]
      },
      {
        "title": "Deferred payments",
        "fileName": "customer/walsall/cfa_deferred_payments.md",
        "whitelist": [
          "walsall"
        ]
      },
      {
        "title": "Deferred payment agreement",
        "fileName": "customer/wokingham/cfa_deferred_payments.md",
        "whitelist": [
          "wokingham"
        ]
      },
      {
        "title": "Deferred payment agreement",
        "fileName": "customer/wolverhampton/cfa_deferred_payments.md",
        "whitelist": [
          "wolverhampton"
        ]
      },
      {
        "title": "Deferred payments",
        "fileName": "customer/calderdale/cfa_deferred_payments.md",
        "whitelist": [
          "calderdale"
        ]
      },
      {
        "title": "Deferred payments",
        "fileName": "customer/cornwall/cfa_deferred_payments.md",
        "whitelist": [
          "cornwall"
        ]
      },
      {
        "title": "Deferred payments",
        "fileName": "customer/ealing/cfa_deferred_payments.md",
        "whitelist": [
          "ealing"
        ]
      },
      {
        "title": "Deferred payments",
        "fileName": "customer/westnorthants/cfa_deferred_payments.md",
        "whitelist": [
          "westnorthants"
        ]
      },
      {
        "title": "Top-up fees for care homes",
        "fileName": "customer/southglos/cfa_top-up_payments.md",
        "whitelist": [
          "southglos"
        ]
      },
      {
        "title": "Top-up fees for care homes",
        "fileName": "customer/walsall/cfa_top-up_payments.md",
        "whitelist": [
          "walsall"
        ]
      },
      {
        "title": "Top-ups or third party agreements",
        "fileName": "customer/westnorthants/cfa_top-up_payments.md",
        "whitelist": [
          "westnorthants"
        ]
      },
      {
        "title": "Third-party contributions (Top-up payments)",
        "fileName": "customer/cambridgeshire/cfa_top-up_payments.md",
        "whitelist": [
          "cambridgeshire"
        ]
      },
      {
        "title": "Top-up payments",
        "fileName": "customer/calderdale/cfa_top-up_payments.md",
        "whitelist": [
          "calderdale"
        ]
      },
      {
        "title": "Top-ups or third party agreements",
        "fileName": "customer/ealing/cfa_top-up_payments.md",
        "whitelist": [
          "ealing"
        ]
      },
      {
        "title": "Deferred payments and third party top-ups",
        "fileName": "customer/blackpool/cfa_alternative_payments.md",
        "whitelist": [
          "blackpool"
        ]
      },
      {
        "title": "Deferred payments and top-ups",
        "fileName": "customer/warwickshire/cfa_alternative_payments.md",
        "whitelist": [
          "warwickshire"
        ]
      },
      {
        "title": "Deferred payments and third-party top-ups",
        "fileName": "customer/nottinghamshire/cfa_alternative_payments.md",
        "whitelist": [
          "nottinghamshire"
        ]
      },
      {
        "title": "Deferred payments and third party top-ups",
        "fileName": "customer/halton/cfa_alternative_payments.md",
        "whitelist": [
          "halton"
        ]
      },
      {
        "title": "Deferred payment agreement",
        "fileName": "customer/hampshire/cfa_deferred_payments.md",
        "whitelist": [
          "hampshire"
        ]
      },
      {
        "title": "Deferred payment agreement",
        "fileName": "customer/somerset/cfa_deferred_payments.md",
        "whitelist": [
          "somerset"
        ]
      },
      {
        "title": "Deferred payments",
        "fileName": "customer/peterborough/cfa_deferred_payments.md",
        "whitelist": [
          "peterborough"
        ]
      },
      {
        "title": "Deferred payment agreement",
        "fileName": "customer/cambridgeshire/cfa_deferred_payments.md",
        "whitelist": [
          "cambridgeshire"
        ]
      },
      {
        "title": "Direct payments",
        "fileName": "customer/calderdale/cfa_direct_payments.md",
        "whitelist": [
          "calderdale"
        ]
      },
      {
        "title": "Personal budgets",
        "fileName": "customer/calderdale/cfa_personal_budgets.md",
        "whitelist": [
          "calderdale"
        ]
      },
      {
        "title": "Personal budgets",
        "fileName": "customer/blackpool/cfa_personal_budgets.md",
        "whitelist": [
          "blackpool"
        ]
      },
      {
        "title": "Direct payments",
        "fileName": "customer/warwickshire/cfa_direct_payments.md",
        "whitelist": [
          "warwickshire"
        ]
      },
      {
        "title": "Direct payments",
        "fileName": "customer/cornwall/cfa_direct_payments.md",
        "whitelist": [
          "cornwall"
        ]
      },
      {
        "title": "Direct payments and personal budgets",
        "fileName": "customer/westnorthants/cfa_direct_payments.md",
        "whitelist": [
          "westnorthants"
        ]
      },
      {
        "title": "Direct payments",
        "fileName": "customer/nottinghamshire/cfa_direct_payments.md",
        "whitelist": [
          "nottinghamshire"
        ]
      },
      {
        "title": "Direct payments",
        "fileName": "customer/hampshire/cfa_direct_payments.md",
        "whitelist": [
          "hampshire"
        ]
      },
      {
        "title": "Direct payments",
        "fileName": "customer/cambridgeshire/cfa_direct_payments.md",
        "whitelist": [
          "cambridgeshire"
        ]
      },
      {
        "title": "Direct payments",
        "fileName": "customer/somerset/cfa_direct_payments.md",
        "whitelist": [
          "somerset"
        ]
      },
      {
        "title": "Personal budgets",
        "fileName": "customer/halton/cfa_personal_budgets.md",
        "whitelist": [
          "halton"
        ]
      },
      {
        "title": "Personal budgets and direct payments",
        "fileName": "customer/walsall/cfa_personal_budgets.md",
        "whitelist": [
          "walsall"
        ]
      },
      {
        "title": "Personal budgets",
        "fileName": "customer/torbay/cfa_personal_budgets.md",
        "whitelist": [
          "torbay"
        ]
      },
      {
        "title": "Paying for your own care",
        "fileName": "bettercare/TODO.md",
        "whitelist": [
          "demo"
        ]
      },
      {
        "title": "Paying for your own care",
        "fileName": "customer/northlincs/nlincs_cfa_paying_for_own_care.md",
        "whitelist": [
          "northlincs"
        ]
      },
      {
        "title": "Paying for your own care",
        "fileName": "customer/coventry/cov_fca_paying_for_own_care.md",
        "whitelist": [
          "coventry"
        ]
      },
      {
        "title": "Third-party top-up",
        "fileName": "customer/northlincs/nlincs_cfa_top-up_fees.md",
        "whitelist": [
          "northlincs"
        ]
      },
      {
        "title": "Top-up fees",
        "fileName": "customer/coventry/cov_fca_top-up_fees.md",
        "whitelist": [
          "coventry"
        ]
      },
      {
        "title": "Top-up payments for care homes",
        "fileName": "customer/hampshire/cfa_top-up_payments.md",
        "whitelist": [
          "hampshire"
        ]
      },
      {
        "title": "Top-up payments for care homes",
        "fileName": "customer/somerset/cfa_top-up_payments.md",
        "whitelist": [
          "somerset"
        ]
      },
      {
        "title": "Care home costs and top ups",
        "fileName": "customer/peterborough/cfa_top-up_payments.md",
        "whitelist": [
          "peterborough"
        ]
      },
      {
        "title": "Care home costs and third party top-ups",
        "fileName": "customer/wokingham/cfa_top-up_payments.md",
        "whitelist": [
          "wokingham"
        ]
      },
      {
        "title": "Care home costs, personal budgets and top-ups",
        "fileName": "customer/wolverhampton/cfa_top-up_payments.md",
        "whitelist": [
          "wolverhampton"
        ]
      },
      {
        "title": "Independent financial advice",
        "fileName": "customer/southampton/cfa_financial_advice.md",
        "whitelist": [
          "southampton"
        ]
      },
      {
        "title": "Independent financial advice",
        "fileName": "customer/wakefield/cfa_financial_advice.md",
        "whitelist": [
          "wakefield"
        ]
      },
      {
        "title": "Independent financial advice",
        "fileName": "customer/doncaster/cfa_financial_advice.md",
        "whitelist": [
          "doncaster"
        ]
      },
      {
        "title": "Independent financial advice",
        "fileName": "customer/ealing/cfa_financial_advice.md",
        "whitelist": [
          "ealing"
        ]
      },
      {
        "title": "Independent financial advice",
        "fileName": "customer/blackpool/cfa_financial_advice.md",
        "whitelist": [
          "blackpool"
        ]
      },
      {
        "title": "Independent financial advice",
        "fileName": "customer/warwickshire/cfa_financial_advice.md",
        "whitelist": [
          "warwickshire"
        ]
      },
      {
        "title": "Independent financial advice",
        "fileName": "customer/nottinghamshire/cfa_financial_advice.md",
        "whitelist": [
          "nottinghamshire"
        ]
      },
      {
        "title": "Independent financial advice",
        "fileName": "customer/halton/cfa_financial_advice.md",
        "whitelist": [
          "halton"
        ]
      },
      {
        "title": "Independent financial advice",
        "fileName": "customer/hampshire/cfa_financial_advice.md",
        "whitelist": [
          "hampshire"
        ]
      },
      {
        "title": "Independent financial advice",
        "fileName": "customer/somerset/cfa_financial_advice.md",
        "whitelist": [
          "somerset"
        ]
      },
      {
        "title": "Independent financial advice",
        "fileName": "customer/peterborough/cfa_financial_advice.md",
        "whitelist": [
          "peterborough"
        ]
      },
      {
        "title": "Independent financial and legal advice",
        "fileName": "customer/cambridgeshire/cfa_financial_advice.md",
        "whitelist": [
          "cambridgeshire"
        ]
      },
      {
        "title": "Independent financial advice",
        "fileName": "customer/southglos/cfa_financial_advice.md",
        "whitelist": [
          "southglos"
        ]
      },
      {
        "title": "Independent financial advice",
        "fileName": "customer/torbay/cfa_financial_advice.md",
        "whitelist": [
          "torbay"
        ]
      },
      {
        "title": "Independent financial advice",
        "fileName": "customer/walsall/cfa_financial_advice.md",
        "whitelist": [
          "walsall"
        ]
      },
      {
        "title": "Independent financial advice",
        "fileName": "customer/wokingham/cfa_financial_advice.md",
        "whitelist": [
          "wokingham"
        ]
      },
      {
        "title": "Independent financial advice",
        "fileName": "customer/wolverhampton/cfa_financial_advice.md",
        "whitelist": [
          "wolverhampton"
        ]
      },
      {
        "title": "Independent financial advice",
        "fileName": "customer/calderdale/cfa_financial_advice.md",
        "whitelist": [
          "calderdale"
        ]
      },
      {
        "title": "Independent financial advice",
        "fileName": "customer/cornwall/cfa_financial_advice.md",
        "whitelist": [
          "cornwall"
        ]
      },
      {
        "title": "Independent financial advice",
        "fileName": "customer/westnorthants/cfa_financial_advice.md",
        "whitelist": [
          "westnorthants"
        ]
      },
      {
        "title": "Complain about a financial assessment",
        "fileName": "bettercare/TODO.md",
        "whitelist": [
          "demo"
        ]
      },
      {
        "title": "Complain about a financial assessment",
        "fileName": "customer/northlincs/nlincs_cfa_complain.md",
        "whitelist": [
          "northlincs"
        ]
      },
      {
        "title": "Appeal a financial assessment",
        "fileName": "customer/southampton/cfa_appeal.md",
        "whitelist": [
          "southampton"
        ]
      },
      {
        "title": "Appeal a financial assessment",
        "fileName": "customer/wakefield/cfa_appeal.md",
        "whitelist": [
          "wakefield"
        ]
      },
      {
        "title": "Disputes and complaints",
        "fileName": "customer/doncaster/cfa_disputes_complaints.md",
        "whitelist": [
          "doncaster"
        ]
      },
      {
        "title": "Disputes and appeals",
        "fileName": "customer/southglos/cfa_disputes_appeals.md",
        "whitelist": [
          "southglos"
        ]
      },
      {
        "title": "Disputes and appeals",
        "fileName": "customer/walsall/cfa_disputes_appeals.md",
        "whitelist": [
          "walsall"
        ]
      },
      {
        "title": "Appeal a decision about what you must pay for your care",
        "fileName": "customer/wokingham/cfa_appeal.md",
        "whitelist": [
          "wokingham"
        ]
      },
      {
        "title": "Appeal a decision about what you must pay for your care",
        "fileName": "customer/hampshire/cfa_appeal.md",
        "whitelist": [
          "hampshire"
        ]
      },
      {
        "title": "Appeal against a decision",
        "fileName": "customer/torbay/cfa_appeal.md",
        "whitelist": [
          "torbay"
        ]
      },
      {
        "title": "Reviews and appeals",
        "fileName": "customer/blackpool/cfa_reviews_appeals.md",
        "whitelist": [
          "blackpool"
        ]
      },
      {
        "title": "Appeal against the charge you've been asked to pay",
        "fileName": "customer/coventry/cov_fca_complain.md",
        "whitelist": [
          "coventry"
        ]
      }
    ]
  },
  "jobsearch": {
    "main": {
      "finding": {
        "title": "Finding a job",
        "links": [
          {
            "title": "Public sector jobs in the north west",
            "fileName": "customer/salford/public_sector_jobs_nw.md",
            "whitelist": [
              "salford"
            ]
          },
          {
            "title": "Returning to work after taking time-out",
            "fileName": "returning_to_work.md"
          },
          {
            "title": "Help getting into work",
            "fileName": "customer/kirklees/help_getting_work.md",
            "whitelist": [
              "kirklees"
            ]
          },
          {
            "title": "Help to find work – Lewisham Works",
            "fileName": "customer/lewisham/lewisham_works.md",
            "whitelist": [
              "lewisham"
            ]
          },
          {
            "title": "Work for the council",
            "fileName": "customer/lewisham/work_lewisham_council.md",
            "whitelist": [
              "lewisham"
            ]
          },
          {
            "title": "Work for the NHS",
            "fileName": "customer/lewisham/work_for_nhs.md",
            "whitelist": [
              "lewisham"
            ]
          },
          {
            "title": "Working at the council",
            "fileName": "customer/leicester/working_at_LCC.md",
            "whitelist": [
              "leicester"
            ]
          },
          {
            "title": "Help to find work - Leicester Employment Hub",
            "fileName": "customer/leicester/leicester_employment_hub.md",
            "whitelist": [
              "leicester"
            ]
          },
          {
            "title": "Find a job (GOV.UK)",
            "fileName": "find_a_job.md",
            "whitelist": [
              "leicester"
            ]
          },
          {
            "title": "NHS jobs",
            "fileName": "nhs_jobs.md",
            "whitelist": [
              "leicester"
            ]
          },
          {
            "title": "Childcare",
            "fileName": "childcare.md",
            "blacklist": [
              "enfield",
              "stirling"
            ]
          },
          {
            "title": "Childcare",
            "fileName": "childcare_enfield.md",
            "whitelist": [
              "enfield"
            ]
          },
          {
            "title": "Childcare",
            "fileName": "childcare_scotland.md",
            "whitelist": [
              "stirling"
            ]
          },
          {
            "title": "Help with childcare costs",
            "fileName": "help_with_childcare_costs.md",
            "blacklist": [
              "stirling",
              "leicester"
            ]
          },
          {
            "title": "Help with childcare costs",
            "fileName": "help_with_childcare_costs_leicester.md",
            "whitelist": [
              "leicester"
            ]
          },
          {
            "title": "Help with childcare costs",
            "fileName": "help_with_childcare_costs_scotland.md",
            "whitelist": [
              "stirling"
            ]
          },
          {
            "title": "Tax-free childcare",
            "fileName": "tax_free_childcare.md"
          },
          {
            "title": "Volunteering",
            "fileName": "volunteering.md"
          },
          {
            "title": "How will volunteering affect my benefits?",
            "fileName": "volunteering_and_benefits.md"
          },
          {
            "title": "Learning and Employability Team",
            "fileName": "customer/stirling/stirling_council_employability.md",
            "whitelist": [
              "stirling"
            ]
          },
          {
            "title": "Help and support from local Housing Providers",
            "fileName": "customer/salford/local_housing_providers.md",
            "whitelist": [
              "salford"
            ]
          },
          {
            "title": "Help for people with a criminal conviction",
            "fileName": "unlock.md",
            "blacklist": [
              "stirling"
            ]
          },
          {
            "title": "Social Media",
            "fileName": "social_media.md"
          },
          {
            "title": "Networking",
            "fileName": "networking.md"
          }
        ]
      },
      "budgeting": {
        "title": "Debt advice and money management",
        "links": [
          {
            "title": "Budgeting guidance",
            "fileName": "budgeting_and_debt.md",
            "whitelist": [
              "kirklees",
              "enfield"
            ]
          },
          {
            "title": "Debt advice",
            "fileName": "debt_advice.md",
            "whitelist": [
              "kirklees",
              "enfield"
            ]
          },
          {
            "title": "Managing money",
            "fileName": "customer/herefordshire/managing_money.md",
            "whitelist": [
              "herefordshire"
            ]
          },
          {
            "title": "Debt solutions",
            "fileName": "customer/herefordshire/debt_solutions.md",
            "whitelist": [
              "herefordshire"
            ]
          },
          {
            "title": "Bailiffs/Enforcement agents",
            "fileName": "customer/herefordshire/bailiffs_enforcement_agents.md",
            "whitelist": [
              "herefordshire"
            ]
          },
          {
            "title": "Rent or mortgage arrears",
            "fileName": "customer/herefordshire/rent_mortgage_arrears.md",
            "whitelist": [
              "herefordshire"
            ]
          },
          {
            "title": "Council Tax arrears",
            "fileName": "customer/herefordshire/council_tax_arrears.md",
            "whitelist": [
              "herefordshire"
            ]
          },
          {
            "title": "Credit Unions",
            "fileName": "customer/herefordshire/credit_unions.md",
            "whitelist": [
              "herefordshire"
            ]
          },
          {
            "title": "Managing money",
            "fileName": "customer/stirling/managing_money.md",
            "whitelist": [
              "stirling"
            ]
          },
          {
            "title": "Debt solutions",
            "fileName": "customer/stirling/debt_solutions.md",
            "whitelist": [
              "stirling"
            ]
          },
          {
            "title": "Sheriff officers",
            "fileName": "customer/stirling/sheriff_officers.md",
            "whitelist": [
              "stirling"
            ]
          },
          {
            "title": "Rent or mortgage arrears",
            "fileName": "customer/stirling/rent_mortgage_arrears.md",
            "whitelist": [
              "stirling"
            ]
          },
          {
            "title": "Council Tax arrears",
            "fileName": "customer/stirling/council_tax_arrears.md",
            "whitelist": [
              "stirling"
            ]
          },
          {
            "title": "Stirling Credit Union",
            "fileName": "customer/stirling/stirling_credit_union.md",
            "whitelist": [
              "stirling"
            ]
          },
          {
            "title": "Organisations that can help",
            "fileName": "customer/stirling/organisations_that_can_help.md",
            "whitelist": [
              "stirling"
            ]
          },
          {
            "title": "Managing money",
            "fileName": "customer/incommunities/managing_money.md",
            "whitelist": [
              "incommunities"
            ]
          },
          {
            "title": "Debt solutions",
            "fileName": "customer/incommunities/debt_solutions.md",
            "whitelist": [
              "incommunities"
            ]
          },
          {
            "title": "Bailiffs/Enforcement agents",
            "fileName": "customer/incommunities/bailiffs_enforcement_agents.md",
            "whitelist": [
              "incommunities"
            ]
          },
          {
            "title": "Rent or mortgage arrears",
            "fileName": "customer/incommunities/rent_mortgage_arrears.md",
            "whitelist": [
              "incommunities"
            ]
          },
          {
            "title": "Council Tax arrears",
            "fileName": "customer/incommunities/council_tax_arrears.md",
            "whitelist": [
              "incommunities"
            ]
          },
          {
            "title": "Bradford District Credit Union",
            "fileName": "customer/incommunities/bradford_district_credit_union.md",
            "whitelist": [
              "incommunities"
            ]
          },
          {
            "title": "Managing money",
            "fileName": "customer/lincolnshire/managing_money.md",
            "whitelist": [
              "lincolnshire"
            ]
          },
          {
            "title": "Debt solutions",
            "fileName": "customer/lincolnshire/debt_solutions.md",
            "whitelist": [
              "lincolnshire"
            ]
          },
          {
            "title": "Bailiffs/Enforcement agents",
            "fileName": "customer/lincolnshire/bailiffs_enforcement_agents.md",
            "whitelist": [
              "lincolnshire"
            ]
          },
          {
            "title": "Rent or mortgage arrears",
            "fileName": "customer/lincolnshire/rent_mortgage_arrears.md",
            "whitelist": [
              "lincolnshire"
            ]
          },
          {
            "title": "Council Tax arrears",
            "fileName": "customer/lincolnshire/council_tax_arrears.md",
            "whitelist": [
              "lincolnshire"
            ]
          },
          {
            "title": "Credit Unions",
            "fileName": "customer/lincolnshire/credit_unions.md",
            "whitelist": [
              "lincolnshire"
            ]
          },
          {
            "title": "Managing money",
            "fileName": "customer/leicester/managing_money.md",
            "whitelist": [
              "leicester"
            ]
          },
          {
            "title": "Debt solutions",
            "fileName": "customer/leicester/debt_solutions.md",
            "whitelist": [
              "leicester"
            ]
          },
          {
            "title": "If you are struggling to pay",
            "fileName": "customer/leicester/struggling_to_pay.md",
            "whitelist": [
              "leicester"
            ]
          },
          {
            "title": "Bailiffs/Enforcement agents",
            "fileName": "customer/leicester/bailiffs_enforcement_agents.md",
            "whitelist": [
              "leicester"
            ]
          },
          {
            "title": "Rent or mortgage arrears",
            "fileName": "customer/leicester/rent_mortgage_arrears.md",
            "whitelist": [
              "leicester"
            ]
          },
          {
            "title": "Council Tax arrears",
            "fileName": "customer/leicester/council_tax_arrears.md",
            "whitelist": [
              "leicester"
            ]
          },
          {
            "title": "Clockwise credit union",
            "fileName": "customer/leicester/clockwise_credit_union.md",
            "whitelist": [
              "leicester"
            ]
          },
          {
            "title": "Healthy Start vouchers",
            "fileName": "customer/leicester/healthy_start_vouchers.md",
            "whitelist": [
              "leicester"
            ]
          },
          {
            "title": "Advice Lewisham",
            "fileName": "customer/lewisham/advice_lewisham.md",
            "whitelist": [
              "lewisham"
            ]
          },
          {
            "title": "Managing money",
            "fileName": "customer/lewisham/managing_money.md",
            "whitelist": [
              "lewisham"
            ]
          },
          {
            "title": "Debt solutions",
            "fileName": "customer/lewisham/debt_solutions.md",
            "whitelist": [
              "lewisham"
            ]
          },
          {
            "title": "Get help with debt",
            "fileName": "customer/lewisham/debt_help_advice.md",
            "whitelist": [
              "lewisham"
            ]
          },
          {
            "title": "Bailiffs/Enforcement agents",
            "fileName": "customer/lewisham/bailiffs_enforcement_agents.md",
            "whitelist": [
              "lewisham"
            ]
          },
          {
            "title": "Council Tax arrears",
            "fileName": "customer/lewisham/council_tax_arrears.md",
            "whitelist": [
              "lewisham"
            ]
          },
          {
            "title": "Lewisham Plus Credit Union",
            "fileName": "customer/lewisham/lewisham_plus_credit_union.md",
            "whitelist": [
              "lewisham"
            ]
          },
          {
            "title": "Managing money",
            "fileName": "customer/derbyshire/managing_money.md",
            "whitelist": [
              "derbyshire"
            ]
          },
          {
            "title": "Debt solutions",
            "fileName": "customer/derbyshire/debt_solutions.md",
            "whitelist": [
              "derbyshire"
            ]
          },
          {
            "title": "Bailiffs/Enforcement agents",
            "fileName": "customer/derbyshire/bailiffs_enforcement_agents.md",
            "whitelist": [
              "derbyshire"
            ]
          },
          {
            "title": "Rent or mortgage arrears",
            "fileName": "customer/derbyshire/rent_mortgage_arrears.md",
            "whitelist": [
              "derbyshire"
            ]
          },
          {
            "title": "Council Tax arrears",
            "fileName": "customer/derbyshire/council_tax_arrears.md",
            "whitelist": [
              "derbyshire"
            ]
          },
          {
            "title": "Community Banks",
            "fileName": "customer/derbyshire/community_banks.md",
            "whitelist": [
              "derbyshire"
            ]
          },
          {
            "title": "Spirit Of Salford – Tell us about any help needed",
            "fileName": "customer/salford/support_coronavirus_crisis.md",
            "whitelist": [
              "salford"
            ]
          },
          {
            "title": "Managing money",
            "fileName": "customer/salford/managing_money.md",
            "whitelist": [
              "salford"
            ]
          },
          {
            "title": "Debt solutions",
            "fileName": "customer/salford/debt_solutions.md",
            "whitelist": [
              "salford"
            ]
          },
          {
            "title": "Bailiffs/Enforcement agents",
            "fileName": "customer/salford/bailiffs_enforcement_agents.md",
            "whitelist": [
              "salford"
            ]
          },
          {
            "title": "Rent or mortgage arrears",
            "fileName": "customer/salford/rent_mortgage_arrears.md",
            "whitelist": [
              "salford"
            ]
          },
          {
            "title": "Council Tax arrears",
            "fileName": "customer/salford/council_tax_arrears.md",
            "whitelist": [
              "salford"
            ]
          },
          {
            "title": "Borrowing money",
            "fileName": "customer/salford/borrowing_money.md",
            "whitelist": [
              "salford"
            ]
          },
          {
            "title": "Local organisations for help and support",
            "fileName": "customer/salford/local_organisations_money_salford.md",
            "whitelist": [
              "salford"
            ]
          },
          {
            "title": "BetterOff coffee and chat sessions",
            "fileName": "customer/salford/coffee_and_chat.md",
            "whitelist": [
              "salford"
            ]
          },
          {
            "title": "Help for students",
            "fileName": "customer/salford/students.md",
            "whitelist": [
              "salford"
            ]
          },
          {
            "title": "Salford Credit Union",
            "fileName": "customer/salford/salford_credit_union.md",
            "whitelist": [
              "salford"
            ]
          },
          {
            "title": "Salford Welfare Rights and Debt Advice Service",
            "fileName": "customer/salford/salford_welfare_rights.md",
            "whitelist": [
              "salford"
            ]
          },
          {
            "title": "Citizens Advice Salford",
            "fileName": "customer/salford/citizens_advice_salford.md",
            "whitelist": [
              "salford"
            ]
          },
          {
            "title": "Help to Save",
            "fileName": "help_to_save.md"
          }
        ]
      },
      "tools": {
        "title": "Budgeting tools and resources",
        "links": [
          {
            "title": "Budget planners",
            "fileName": "budget_planners.md"
          },
          {
            "title": "Budgeting support for Universal Credit",
            "fileName": "budgeting_support_uc.md",
            "blacklist": [
              "stirling",
              "leicester",
              "lewisham",
              "salford"
            ]
          },
          {
            "title": "Budgeting support for Universal Credit",
            "fileName": "budgeting_support_uc_leicester.md",
            "whitelist": [
              "leicester"
            ]
          },
          {
            "title": "Budgeting support for Universal Credit",
            "fileName": "budgeting_support_uc_lewisham.md",
            "whitelist": [
              "lewisham"
            ]
          },
          {
            "title": "Budgeting support for Universal Credit",
            "fileName": "budgeting_support_uc_salford.md",
            "whitelist": [
              "salford"
            ]
          },
          {
            "title": "Budgeting support for Universal Credit",
            "fileName": "budgeting_support_uc_scotland.md",
            "whitelist": [
              "stirling"
            ]
          },
          {
            "title": "Energy Advice",
            "fileName": "customer/lewisham/energy_advice_lewisham.md",
            "whitelist": [
              "lewisham"
            ]
          },
          {
            "title": "Energy grants calculator",
            "fileName": "energy_grants_calculator.md",
            "blacklist": [
              "stirling"
            ]
          },
          {
            "title": "Energy grants calculator",
            "fileName": "energy_grants_calculator_scotland.md",
            "whitelist": [
              "stirling"
            ]
          },
          {
            "title": "Go energy shopping",
            "fileName": "go_energy_shopping.md"
          },
          {
            "title": "Get more from your broadband",
            "fileName": "boost_your_broadband.md"
          },
          {
            "title": "United Utilities",
            "fileName": "united_utilities.md",
            "whitelist": [
              "salford"
            ]
          },
          {
            "title": "MoneySavingExpert.com",
            "fileName": "moneysavingexpert.md"
          },
          {
            "title": "Debt solution tool",
            "fileName": "debt_solution_tool.md",
            "blacklist": [
              "stirling"
            ]
          },
          {
            "title": "Local organisations for help and support",
            "fileName": "customer/salford/local_organisations_budgeting_salford.md",
            "whitelist": [
              "salford"
            ]
          }
        ]
      },
      "skills": {
        "title": "What are your skills?",
        "links": [
          {
            "title": "What skills do I have?",
            "fileName": "what_skills_do_you_really_have.md"
          },
          {
            "title": "Training and qualifications",
            "fileName": "training.md"
          },
          {
            "title": "Digital You",
            "fileName": "customer/salford/digital_you.md",
            "whitelist": [
              "salford"
            ]
          }
        ]
      },
      "interviews": {
        "title": "Interviews",
        "links": [
          {
            "title": "Interview basics",
            "fileName": "interviews_the_basics.md"
          },
          {
            "title": "Preparing for interviews",
            "fileName": "preparing_for_interviews.md"
          },
          {
            "title": "Combat interview nerves",
            "fileName": "combating_interview_nerves.md"
          },
          {
            "title": "Interview do's and don'ts",
            "fileName": "interview_dos_and_donts.md"
          },
          {
            "title": "Practice interview questions",
            "fileName": "practice_interview_questions.md"
          },
          {
            "title": "Strange interview questions",
            "fileName": "strange_interview_questions.md"
          },
          {
            "title": "What to wear at interviews",
            "fileName": "what_to_wear_at_interview.md"
          }
        ]
      },
      "cvs": {
        "title": "CVs",
        "links": [
          {
            "title": "CVs - The basics",
            "fileName": "CV_the_basics.md"
          },
          {
            "title": "C+K Careers",
            "fileName": "customer/kirklees/ck_careers.md",
            "whitelist": [
              "kirklees"
            ]
          },
          {
            "title": "National Careers Service",
            "fileName": "national_careers_service.md",
            "whitelist": [
              "herefordshire",
              "salford",
              "incommunities"
            ]
          },
          {
            "title": "CV builder - My World of Work ",
            "fileName": "my_world_of_work.md",
            "whitelist": [
              "stirling"
            ]
          },
          {
            "title": "Tips on CV writing and job applications",
            "fileName": "tips_on_cvs_and_job_applications.md"
          }
        ]
      }
    }
  },
  "forms": {
    "PIP": {
      "title": "Further information",
      "links": [
        {
          "title": "Top tips for completing the PIP form",
          "fileName": "forms/PIP_tips.md"
        },
        {
          "title": "How to make a claim",
          "fileName": "forms/PIP_how_to_claim.md"
        },
        {
          "title": "Where to send completed claim form",
          "fileName": "forms/PIP_send.md"
        },
        {
          "title": "How much will you get?",
          "fileName": "forms/PIP_how_much_will_i_get.md"
        },
        {
          "title": "What else do you need to know?",
          "fileName": "forms/PIP_need.md"
        },
        {
          "title": "How will you be assessed?",
          "fileName": "forms/PIP_assessment.md"
        },
        {
          "title": "What happens next?",
          "fileName": "forms/PIP_next.md"
        },
        {
          "title": "Appeal a PIP decision",
          "fileName": "forms/appeal_a_decision.md",
          "whitelist": [
            "derbyshire"
          ]
        }
      ]
    },
    "AA1A": {
      "title": "Further information",
      "links": [
        {
          "title": "Top tips for completing the Attendance Allowance form",
          "fileName": "forms/AA1A_tips.md"
        },
        {
          "title": "Things you will need to complete your application",
          "fileName": "forms/AA1A_need.md"
        },
        {
          "title": "Care Needs guidance",
          "fileName": "forms/AA1A_care_needs.md"
        },
        {
          "title": "Video guidance to help you with your Attendance Allowance claim",
          "fileName": "forms/AA1A_videos.md"
        },
        {
          "title": "Where to send this form",
          "fileName": "forms/AA1A_send.md"
        },
        {
          "title": "How much will you get?",
          "fileName": "forms/AA1A_how_much_will_i_get.md"
        },
        {
          "title": "What happens next?",
          "fileName": "forms/AA1A_next.md"
        },
        {
          "title": "Attendance Allowance decision letter",
          "fileName": "forms/AA1A_decision.md",
          "blacklist": [
            "blackpool",
            "cambridgeshire",
            "cornwall",
            "ealing",
            "nottinghamshire",
            "wokingham"
          ]
        },
        {
          "title": "Attendance Allowance decision letter",
          "fileName": "forms/AA1A_decision_alternative.md",
          "whitelist": [
            "blackpool",
            "cambridgeshire",
            "cornwall",
            "ealing",
            "nottinghamshire",
            "wokingham"
          ]
        },
        {
          "title": "Attendance Allowance assessment",
          "fileName": "forms/AA1A_assessment.md"
        },
        {
          "title": "Appeal an Attendance Allowance decision",
          "fileName": "forms/appeal_a_decision.md"
        },
        {
          "title": "What to send with this form",
          "fileName": "forms/AA1A_include.md"
        }
      ]
    },
    "DS700SP": {
      "title": "Further information",
      "links": [
        {
          "title": "How much will you get?",
          "fileName": "forms/DS700SP_how_much_will_i_get.md",
          "blacklist": [
            "salford"
          ]
        },
        {
          "title": "How much will you get?",
          "fileName": "customer/salford/DS700SP_how_much_will_i_get.md",
          "whitelist": [
            "salford"
          ]
        },
        {
          "title": "What happens next?",
          "fileName": "forms/DS700SP_next.md"
        },
        {
          "title": "Is there a Carer's Allowance (if you get state pension) assessment?",
          "fileName": "forms/DS700SP_assessment.md"
        },
        {
          "title": "Carer's Allowance (if you get state pension) decision letter",
          "fileName": "forms/DS700SP_decision.md"
        },
        {
          "title": "Appeal a Carer's Allowance (if you get state pension) decision",
          "fileName": "forms/appeal_a_decision.md"
        },
        {
          "title": "What to send with this form",
          "fileName": "forms/DS700_include.md"
        }
      ]
    },
    "DS700": {
      "title": "Further information",
      "links": [
        {
          "title": "How much will you get?",
          "fileName": "forms/DS700_how_much_will_i_get.md"
        },
        {
          "title": "What happens next?",
          "fileName": "forms/DS700_next.md"
        },
        {
          "title": "Is there a Carer's Allowance assessment?",
          "fileName": "forms/DS700_assessment.md"
        },
        {
          "title": "Carer's Allowance decision letter",
          "fileName": "forms/DS700_decision.md"
        },
        {
          "title": "What to send with this form",
          "fileName": "forms/DS700_include.md"
        }
      ]
    },
    "DLA1A": {
      "title": "Further information",
      "links": [
        {
          "title": "Top 10 Tips when completing a Disability Living Allowance for Children (DLA1A) form",
          "fileName": "forms/DLA1A_top10.md"
        },
        {
          "title": "Where to send this form",
          "fileName": "forms/DLA1A_send.md"
        },
        {
          "title": "What happens next?",
          "fileName": "forms/DLA1A_next.md"
        },
        {
          "title": "Appeal a Disability Living Allowance for Children (DLA1A) decision",
          "fileName": "forms/appeal_a_decision.md"
        },
        {
          "title": "How much will you get?",
          "fileName": "forms/DLA1A_how_much_will_i_get.md"
        },
        {
          "title": "Disability Living Allowance for Children (DLA1A) assessment",
          "fileName": "forms/DLA1A_assessment.md"
        },
        {
          "title": "What to send with this form",
          "fileName": "forms/DLA1A_include.md"
        }
      ]
    },
    "ESA1": {
      "title": "Further information",
      "links": [
        {
          "title": "Where to send this form",
          "fileName": "forms/ESA1_send.md"
        },
        {
          "title": "What to send with this form",
          "fileName": "forms/ESA1_include.md"
        },
        {
          "title": "Video Guidance to help you with your ESA1 form",
          "fileName": "forms/ESA1_videos.md"
        },
        {
          "title": "How much will I get?",
          "fileName": "forms/ESA1_how_much.md"
        },
        {
          "title": "What happens next?",
          "fileName": "forms/ESA1_next.md"
        },
        {
          "title": "Employment & Support Allowance assessment",
          "fileName": "forms/ESA1_attending_assessment.md"
        },
        {
          "title": "Employment & Support Allowance decision",
          "fileName": "forms/ESA1_decision.md"
        },
        {
          "title": "Appeal an Employment & Support Allowance decision",
          "fileName": "forms/appeal_a_decision.md"
        }
      ]
    },
    "ESA50": {
      "title": "Further information",
      "links": [
        {
          "title": "Where to send this form",
          "fileName": "forms/ESA50_send.md"
        },
        {
          "title": "Video guidance to help you with your ESA50 form",
          "fileName": "forms/ESA50_videos.md"
        },
        {
          "title": "What happens next?",
          "fileName": "forms/ESA50_next.md"
        },
        {
          "title": "Employment & Support Allowance assessment",
          "fileName": "forms/ESA1_attending_assessment.md"
        },
        {
          "title": "ESA50 Decision",
          "fileName": "forms/ESA50_decision_letter.md"
        },
        {
          "title": "Appeal an Employment & Support Allowance decision",
          "fileName": "forms/appeal_a_decision.md"
        },
        {
          "title": "What to send with this form",
          "fileName": "forms/ESA50_include.md"
        }
      ]
    },
    "MRR": {
      "title": "Further information",
      "links": [
        {
          "title": "Mandatory Reconsideration (MR) Guidance",
          "fileName": "forms/MRR_Guidance.md"
        },
        {
          "title": "How to fill in the MR form",
          "fileName": "forms/MRR_filling_in_form.md"
        },
        {
          "title": "Video guidance to help you with your MR form",
          "fileName": "forms/MRR_videos.md"
        },
        {
          "title": "Where to send this form",
          "fileName": "forms/MRR_send.md"
        },
        {
          "title": "What to include with this form",
          "fileName": "forms/MRR_include.md"
        },
        {
          "title": "What happens next?",
          "fileName": "forms/MRR_next.md"
        }
      ]
    },
    "JSA": {
      "title": "Further information",
      "links": [
        {
          "title": "How much will I get?",
          "fileName": "forms/JSA_how_much_will_I_get.md"
        },
        {
          "title": "What happens next?",
          "fileName": "forms/JSA_next.md"
        },
        {
          "title": "What to take with you to your JSA interview",
          "fileName": "forms/JSA_include.md"
        }
      ]
    },
    "DHP_SALFORD": {
      "title": "Further information",
      "links": [
        {
          "title": "Things you will need to complete your application",
          "fileName": "forms/DHP_salford_need.md"
        },
        {
          "title": "How much will you get?",
          "fileName": "forms/DHP_salford_how_much_will_i_get.md"
        }
      ]
    },
    "UNIVERSAL_CREDIT_SALFORD": {
      "title": "Further information",
      "links": [
        {
          "title": "Important information",
          "fileName": "forms/UC_salford_important_info.md"
        },
        {
          "title": "Simple steps to be prepared for Universal Credit",
          "fileName": "forms/UC_salford_simple_steps.md"
        },
        {
          "title": "Budgeting support for Universal Credit",
          "fileName": "forms/UC_salford_budgeting_support.md"
        }
      ]
    },
    "UC_FULL_SERVICE": {
      "title": "Further information",
      "links": [
        {
          "title": "Important information",
          "fileName": "forms/UC_salford_important_info.md",
          "whitelist": [
            "salford"
          ]
        },
        {
          "title": "Simple steps to be prepared for Universal Credit",
          "fileName": "forms/UC_salford_simple_steps.md",
          "whitelist": [
            "salford"
          ]
        },
        {
          "title": "Budgeting support for Universal Credit",
          "fileName": "forms/UC_salford_budgeting_support.md",
          "whitelist": [
            "salford"
          ]
        },
        {
          "title": "Important information",
          "fileName": "forms/UC_derbyshire_important_info.md",
          "whitelist": [
            "derbyshire"
          ]
        },
        {
          "title": "Before you begin",
          "fileName": "forms/UC_before_you_begin.md"
        },
        {
          "title": "Opening an account",
          "fileName": "forms/UC_opening_an_account.md"
        },
        {
          "title": "How to make a claim",
          "fileName": "forms/UC_how_to_make_a_claim.md"
        },
        {
          "title": "Proving your identity with GOV.UK Verify",
          "fileName": "forms/UC_proving_your_identity.md"
        },
        {
          "title": "Your journal",
          "fileName": "forms/UC_your_journal.md"
        },
        {
          "title": "What happens next?",
          "fileName": "forms/UC_next.md"
        },
        {
          "title": "Working with your Work Coach",
          "fileName": "forms/UC_work_coach.md"
        }
      ]
    },
    "A1": {
      "title": "Further information",
      "links": [
        {
          "title": "Eligibility for Income Support",
          "fileName": "forms/A1_eligibility.md"
        },
        {
          "title": "Where to send this form",
          "fileName": "forms/A1_send.md"
        },
        {
          "title": "How much will I get?",
          "fileName": "forms/A1_how_much_will_i_get.md"
        },
        {
          "title": "What happens next?",
          "fileName": "forms/A1_next.md"
        },
        {
          "title": "Appeal an Income Support decision",
          "fileName": "forms/appeal_a_decision.md"
        },
        {
          "title": "What to send with this form",
          "fileName": "forms/A1_include.md"
        }
      ]
    },
    "DHP_INCOMMUNITIES": {
      "title": "Select your Local Authority...",
      "links": [
        {
          "title": "Bradford",
          "fileName": "customer/incommunities/DHP_bradford.md"
        },
        {
          "title": "Kirklees",
          "fileName": "customer/incommunities/DHP_kirklees.md"
        },
        {
          "title": "Rotherham",
          "fileName": "customer/incommunities/DHP_rotherham.md"
        },
        {
          "title": "Wakefield",
          "fileName": "customer/incommunities/DHP_wakefield.md"
        },
        {
          "title": "Not sure who is your Local Authority?",
          "fileName": "customer/incommunities/find_your_local_authority.md"
        }
      ]
    },
    "CTR_HB_INCOMMUNITIES": {
      "title": "Select your Local Authority...",
      "links": [
        {
          "title": "Bradford",
          "fileName": "customer/incommunities/HBCTR_bradford.md"
        },
        {
          "title": "Kirklees",
          "fileName": "customer/incommunities/HBCTR_kirklees.md"
        },
        {
          "title": "Rotherham",
          "fileName": "customer/incommunities/HBCTR_rotherham.md"
        },
        {
          "title": "Wakefield",
          "fileName": "customer/incommunities/HBCTR_wakefield.md"
        },
        {
          "title": "Not sure who is your Local Authority?",
          "fileName": "customer/incommunities/find_your_local_authority.md"
        }
      ]
    },
    "DHP_DERBYSHIRE": {
      "title": "Select your Local Authority...",
      "links": [
        {
          "title": "Amber Valley",
          "fileName": "customer/derbyshire/DHP_amber-valley.md"
        },
        {
          "title": "Bolsover",
          "fileName": "customer/derbyshire/DHP_bolsover.md"
        },
        {
          "title": "Chesterfield",
          "fileName": "customer/derbyshire/DHP_chesterfield.md"
        },
        {
          "title": "Derby",
          "fileName": "customer/derbyshire/DHP_derby.md"
        },
        {
          "title": "Derbyshire Dales",
          "fileName": "customer/derbyshire/DHP_derbyshire-dales.md"
        },
        {
          "title": "Erewash",
          "fileName": "customer/derbyshire/DHP_erewash.md"
        },
        {
          "title": "High Peak",
          "fileName": "customer/derbyshire/DHP_high-peak.md"
        },
        {
          "title": "North East Derbyshire",
          "fileName": "customer/derbyshire/DHP_ne-derbyshire.md"
        },
        {
          "title": "South Derbyshire",
          "fileName": "customer/derbyshire/DHP_s-derbyshire.md"
        },
        {
          "title": "Not sure who is your Local Authority?",
          "fileName": "customer/derbyshire/find_your_local_authority.md"
        }
      ]
    },
    "DHP_HAMPSHIRE": {
      "title": "Select your Local Authority...",
      "links": [
        {
          "title": "Basingstoke and Deane",
          "fileName": "customer/hampshire/DHP_basingstoke-and-deane.md"
        },
        {
          "title": "East Hampshire",
          "fileName": "customer/hampshire/DHP_east-hampshire.md"
        },
        {
          "title": "Eastleigh",
          "fileName": "customer/hampshire/DHP_eastleigh.md"
        },
        {
          "title": "Fareham",
          "fileName": "customer/hampshire/DHP_fareham.md"
        },
        {
          "title": "Gosport",
          "fileName": "customer/hampshire/DHP_gosport.md"
        },
        {
          "title": "Hart",
          "fileName": "customer/hampshire/DHP_hart.md"
        },
        {
          "title": "Havant",
          "fileName": "customer/hampshire/DHP_havant.md"
        },
        {
          "title": "New Forest",
          "fileName": "customer/hampshire/DHP_new-forest.md"
        },
        {
          "title": "Rushmoor",
          "fileName": "customer/hampshire/DHP_rushmoor.md"
        },
        {
          "title": "Test Valley",
          "fileName": "customer/hampshire/DHP_test-valley.md"
        },
        {
          "title": "Winchester",
          "fileName": "customer/hampshire/DHP_winchester.md"
        },
        {
          "title": "Not sure who is your Local Authority?",
          "fileName": "customer/hampshire/find_your_local_authority.md"
        }
      ]
    },
    "DHP_CAMBRIDGESHIRE": {
      "title": "Select your Local Authority...",
      "links": [
        {
          "title": "Cambridge",
          "fileName": "customer/cambridgeshire/DHP_cambridge.md"
        },
        {
          "title": "East Cambridgeshire",
          "fileName": "customer/cambridgeshire/DHP_east-cambs.md"
        },
        {
          "title": "Fenland",
          "fileName": "customer/cambridgeshire/DHP_fenland.md"
        },
        {
          "title": "Huntingdonshire",
          "fileName": "customer/cambridgeshire/DHP_huntingdonshire.md"
        },
        {
          "title": "South Cambridgeshire",
          "fileName": "customer/cambridgeshire/DHP_south-cambs.md"
        },
        {
          "title": "Not sure who is your Local Authority?",
          "fileName": "customer/cambridgeshire/find_your_local_authority.md"
        }
      ]
    },
    "DHP_WARWICKSHIRE": {
      "title": "Select your Local Authority...",
      "links": [
        {
          "title": "North Warwickshire",
          "fileName": "customer/warwickshire/DHP_north-warwickshire.md"
        },
        {
          "title": "Nuneaton and Bedworth",
          "fileName": "customer/warwickshire/DHP_nuneaton-bedworth.md"
        },
        {
          "title": "Rugby",
          "fileName": "customer/warwickshire/DHP_rugby.md"
        },
        {
          "title": "Stratford-on-Avon",
          "fileName": "customer/warwickshire/DHP_stratford-on-avon.md"
        },
        {
          "title": "Warwick",
          "fileName": "customer/warwickshire/DHP_warwick.md"
        },
        {
          "title": "Not sure who is your Local Authority?",
          "fileName": "customer/warwickshire/find_your_local_authority.md"
        }
      ]
    },
    "DHP_NOTTINGHAMSHIRE": {
      "title": "Select your Local Authority...",
      "links": [
        {
          "title": "Ashfield",
          "fileName": "customer/nottinghamshire/DHP_ashfield.md"
        },
        {
          "title": "Bassetlaw",
          "fileName": "customer/nottinghamshire/DHP_bassetlaw.md"
        },
        {
          "title": "Broxtowe",
          "fileName": "customer/nottinghamshire/DHP_broxtowe.md"
        },
        {
          "title": "Gedling",
          "fileName": "customer/nottinghamshire/DHP_gedling.md"
        },
        {
          "title": "Mansfield",
          "fileName": "customer/nottinghamshire/DHP_mansfield.md"
        },
        {
          "title": "Newark and Sherwood",
          "fileName": "customer/nottinghamshire/DHP_newark-sherwood.md"
        },
        {
          "title": "Nottingham",
          "fileName": "customer/nottinghamshire/DHP_nottingham.md"
        },
        {
          "title": "Rushcliffe",
          "fileName": "customer/nottinghamshire/DHP_rushcliffe.md"
        },
        {
          "title": "Not sure who is your Local Authority?",
          "fileName": "customer/nottinghamshire/find_your_local_authority.md"
        }
      ]
    },
    "DHP_LINCOLNSHIRE": {
      "title": "Select your Local Authority...",
      "links": [
        {
          "title": "Boston",
          "fileName": "customer/lincolnshire/DHP_boston.md"
        },
        {
          "title": "Lincoln",
          "fileName": "customer/lincolnshire/DHP_lincoln.md"
        },
        {
          "title": "East Lindsey",
          "fileName": "customer/lincolnshire/DHP_e-lindsey.md"
        },
        {
          "title": "West Lindsey",
          "fileName": "customer/lincolnshire/DHP_w-lindsey.md"
        },
        {
          "title": "North Kesteven",
          "fileName": "customer/lincolnshire/DHP_n-kesteven.md"
        },
        {
          "title": "South Kesteven",
          "fileName": "customer/lincolnshire/DHP_s-kesteven.md"
        },
        {
          "title": "South Holland",
          "fileName": "customer/lincolnshire/DHP_s-holland.md"
        },
        {
          "title": "Not sure who is your Local Authority?",
          "fileName": "customer/lincolnshire/find_your_local_authority.md"
        }
      ]
    },
    "EA_FCA": {
      "title": "Further information",
      "links": [
        {
          "title": "Information you will need to complete your financial assessment",
          "fileName": "forms/EA_FCA_need.md"
        },
        {
          "title": "What to do if your financial circumstances change",
          "fileName": "forms/EA_FCA_changeofcircs.md"
        },
        {
          "title": "Independent financial advice",
          "fileName": "customer/ealing/cfa_financial_advice.md"
        },
        {
          "title": "Disability-related expenditure",
          "fileName": "forms/EA_FCA_dre.md"
        },
        {
          "title": "What happens after a financial assessment",
          "fileName": "forms/EA_FCA_next.md"
        },
        {
          "title": "If you disagree with your financial contribution",
          "fileName": "forms/EA_FCA_disagree.md"
        }
      ]
    },
    "HB_DERBYSHIRE": {
      "title": "Select your Local Authority...",
      "links": [
        {
          "title": "Amber Valley",
          "fileName": "customer/derbyshire/HBCTS_amber-valley.md"
        },
        {
          "title": "Bolsover",
          "fileName": "customer/derbyshire/HBCTS_bolsover.md"
        },
        {
          "title": "Chesterfield",
          "fileName": "customer/derbyshire/HBCTS_chesterfield.md"
        },
        {
          "title": "Derby",
          "fileName": "customer/derbyshire/HBCTS_derby.md"
        },
        {
          "title": "Derbyshire Dales",
          "fileName": "customer/derbyshire/HBCTS_derbyshire-dales.md"
        },
        {
          "title": "Erewash",
          "fileName": "customer/derbyshire/HBCTS_erewash.md"
        },
        {
          "title": "High Peak",
          "fileName": "customer/derbyshire/HBCTS_high-peak.md"
        },
        {
          "title": "North East Derbyshire",
          "fileName": "customer/derbyshire/HBCTS_ne-derbyshire.md"
        },
        {
          "title": "South Derbyshire",
          "fileName": "customer/derbyshire/HBCTS_s-derbyshire.md"
        },
        {
          "title": "Not sure who is your Local Authority?",
          "fileName": "customer/derbyshire/find_your_local_authority.md"
        }
      ]
    },
    "CTS_DERBYSHIRE": {
      "title": "Select your Local Authority...",
      "links": [
        {
          "title": "Amber Valley",
          "fileName": "customer/derbyshire/HBCTS_amber-valley.md"
        },
        {
          "title": "Bolsover",
          "fileName": "customer/derbyshire/HBCTS_bolsover.md"
        },
        {
          "title": "Chesterfield",
          "fileName": "customer/derbyshire/HBCTS_chesterfield.md"
        },
        {
          "title": "Derby",
          "fileName": "customer/derbyshire/HBCTS_derby.md"
        },
        {
          "title": "Derbyshire Dales",
          "fileName": "customer/derbyshire/HBCTS_derbyshire-dales.md"
        },
        {
          "title": "Erewash",
          "fileName": "customer/derbyshire/HBCTS_erewash.md"
        },
        {
          "title": "High Peak",
          "fileName": "customer/derbyshire/HBCTS_high-peak.md"
        },
        {
          "title": "North East Derbyshire",
          "fileName": "customer/derbyshire/HBCTS_ne-derbyshire.md"
        },
        {
          "title": "South Derbyshire",
          "fileName": "customer/derbyshire/HBCTS_s-derbyshire.md"
        },
        {
          "title": "Not sure who is your Local Authority?",
          "fileName": "customer/derbyshire/find_your_local_authority.md"
        }
      ]
    },
    "CTS_HB_HAMPSHIRE": {
      "title": "Select your Local Authority...",
      "links": [
        {
          "title": "Basingstoke and Deane",
          "fileName": "customer/hampshire/HBCTS_basingstoke-and-deane.md"
        },
        {
          "title": "East Hampshire",
          "fileName": "customer/hampshire/HBCTS_east-hampshire.md"
        },
        {
          "title": "Eastleigh",
          "fileName": "customer/hampshire/HBCTS_eastleigh.md"
        },
        {
          "title": "Fareham",
          "fileName": "customer/hampshire/HBCTS_fareham.md"
        },
        {
          "title": "Gosport",
          "fileName": "customer/hampshire/HBCTS_gosport.md"
        },
        {
          "title": "Hart",
          "fileName": "customer/hampshire/HBCTS_hart.md"
        },
        {
          "title": "Havant",
          "fileName": "customer/hampshire/HBCTS_havant.md"
        },
        {
          "title": "New Forest",
          "fileName": "customer/hampshire/HBCTS_new-forest.md"
        },
        {
          "title": "Rushmoor",
          "fileName": "customer/hampshire/HBCTS_rushmoor.md"
        },
        {
          "title": "Test Valley",
          "fileName": "customer/hampshire/HBCTS_test-valley.md"
        },
        {
          "title": "Winchester",
          "fileName": "customer/hampshire/HBCTS_winchester.md"
        },
        {
          "title": "Not sure who is your Local Authority?",
          "fileName": "customer/hampshire/find_your_local_authority.md"
        }
      ]
    },
    "CTS_HB_CAMBRIDGESHIRE": {
      "title": "Select your Local Authority...",
      "links": [
        {
          "title": "Cambridge",
          "fileName": "customer/cambridgeshire/HBCTS_cambridge.md"
        },
        {
          "title": "East Cambridgeshire",
          "fileName": "customer/cambridgeshire/HBCTS_east-cambs.md"
        },
        {
          "title": "Fenland",
          "fileName": "customer/cambridgeshire/HBCTS_fenland.md"
        },
        {
          "title": "Huntingdonshire",
          "fileName": "customer/cambridgeshire/HBCTS_huntingdonshire.md"
        },
        {
          "title": "South Cambridgeshire",
          "fileName": "customer/cambridgeshire/HBCTS_south-cambs.md"
        },
        {
          "title": "Not sure who is your Local Authority?",
          "fileName": "customer/cambridgeshire/find_your_local_authority.md"
        }
      ]
    },
    "CTS_HB_LINCOLNSHIRE": {
      "title": "Select your Local Authority...",
      "links": [
        {
          "title": "Boston",
          "fileName": "customer/lincolnshire/HBCTS_boston.md"
        },
        {
          "title": "Lincoln",
          "fileName": "customer/lincolnshire/HBCTS_lincoln.md"
        },
        {
          "title": "East Lindsey",
          "fileName": "customer/lincolnshire/HBCTS_e-lindsey.md"
        },
        {
          "title": "West Lindsey",
          "fileName": "customer/lincolnshire/HBCTS_w-lindsey.md"
        },
        {
          "title": "North Kesteven",
          "fileName": "customer/lincolnshire/HBCTS_n-kesteven.md"
        },
        {
          "title": "South Kesteven",
          "fileName": "customer/lincolnshire/HBCTS_s-kesteven.md"
        },
        {
          "title": "South Holland",
          "fileName": "customer/lincolnshire/HBCTS_s-holland.md"
        },
        {
          "title": "Not sure who is your Local Authority?",
          "fileName": "customer/lincolnshire/find_your_local_authority.md"
        }
      ]
    },
    "CTS_HB_WARWICKSHIRE": {
      "title": "Select your Local Authority...",
      "links": [
        {
          "title": "North Warwickshire",
          "fileName": "customer/warwickshire/HBCTS_north-warwickshire.md"
        },
        {
          "title": "Nuneaton and Bedworth",
          "fileName": "customer/warwickshire/HBCTS_nuneaton-bedworth.md"
        },
        {
          "title": "Rugby",
          "fileName": "customer/warwickshire/HBCTS_rugby.md"
        },
        {
          "title": "Stratford-on-Avon",
          "fileName": "customer/warwickshire/HBCTS_stratford-on-avon.md"
        },
        {
          "title": "Warwick",
          "fileName": "customer/warwickshire/HBCTS_warwick.md"
        },
        {
          "title": "Not sure who is your Local Authority?",
          "fileName": "customer/warwickshire/find_your_local_authority.md"
        }
      ]
    },
    "CTS_HB_NOTTINGHAMSHIRE": {
      "title": "Select your Local Authority...",
      "links": [
        {
          "title": "Ashfield",
          "fileName": "customer/nottinghamshire/HBCTS_ashfield.md"
        },
        {
          "title": "Bassetlaw",
          "fileName": "customer/nottinghamshire/HBCTS_bassetlaw.md"
        },
        {
          "title": "Broxtowe",
          "fileName": "customer/nottinghamshire/HBCTS_broxtowe.md"
        },
        {
          "title": "Gedling",
          "fileName": "customer/nottinghamshire/HBCTS_gedling.md"
        },
        {
          "title": "Mansfield",
          "fileName": "customer/nottinghamshire/HBCTS_mansfield.md"
        },
        {
          "title": "Newark and Sherwood",
          "fileName": "customer/nottinghamshire/HBCTS_newark-sherwood.md"
        },
        {
          "title": "Nottingham",
          "fileName": "customer/nottinghamshire/HBCTS_nottingham.md"
        },
        {
          "title": "Rushcliffe",
          "fileName": "customer/nottinghamshire/HBCTS_rushcliffe.md"
        },
        {
          "title": "Not sure who is your Local Authority?",
          "fileName": "customer/nottinghamshire/find_your_local_authority.md"
        }
      ]
    },
    "LI_FCA": {
      "title": "Further information",
      "links": [
        {
          "title": "Before you start your financial assessment",
          "fileName": "forms/LI_FCA_entitlement-check.md"
        },
        {
          "title": "Information you will need to complete your assessment",
          "fileName": "forms/LI_FCA_need.md"
        },
        {
          "title": "Changes of circumstances",
          "fileName": "forms/LI_FCA_cic.md"
        },
        {
          "title": "How much will you have to pay for your care?",
          "fileName": "forms/LI_FCA_how_much_will_i_pay.md"
        },
        {
          "title": "Disability Related Expenditure (DRE)",
          "fileName": "forms/LI_FCA_DRE.md"
        }
      ]
    },
    "LI_RFCA": {
      "title": "Further information",
      "links": [
        {
          "title": "Before you start your financial assessment",
          "fileName": "forms/LI_RFCA_entitlement-check.md"
        },
        {
          "title": "Information you will need to complete your assessment",
          "fileName": "forms/LI_RFCA_need.md"
        },
        {
          "title": "Changes of circumstances",
          "fileName": "forms/LI_RFCA_cic.md"
        },
        {
          "title": "How much will you have to pay for your care?",
          "fileName": "forms/LI_RFCA_how_much_will_i_pay.md"
        },
        {
          "title": "Disability Related Expenditure (DRE)",
          "fileName": "forms/LI_RFCA_DRE.md"
        }
      ]
    },
    "NL_FCA": {
      "title": "Further information",
      "links": [
        {
          "title": "Information you will need to complete your assessment",
          "fileName": "forms/NL_FCA_need.md"
        },
        {
          "title": "Changes of circumstances",
          "fileName": "forms/NL_FCA_cic.md"
        },
        {
          "title": "Paying your assessed contribution",
          "fileName": "forms/NL_FCA_pay.md"
        },
        {
          "title": "Independent financial advice",
          "fileName": "forms/NL_FCA_financial_advice.md"
        },
        {
          "title": "What happens next?",
          "fileName": "forms/NL_FCA_next.md"
        }
      ]
    },
    "COV_FCA": {
      "title": "Further information",
      "links": [
        {
          "title": "Information you will need to complete your assessment",
          "fileName": "forms/COV_FCA_need.md"
        },
        {
          "title": "How much will you have to pay for your care?",
          "fileName": "forms/COV_FCA_how_much_will_i_pay.md"
        },
        {
          "title": "When will the charge start?",
          "fileName": "forms/COV_FCA_charge_start.md"
        },
        {
          "title": "Disability Related Expenditure (DRE) – what you need to know",
          "fileName": "forms/COV_FCA_DRE_need_to_know.md"
        }
      ]
    },
    "DE_FCA": {
      "title": "Further information",
      "links": [
        {
          "title": "Information you will need to complete your assessment",
          "fileName": "forms/DE_FCA_need.md"
        },
        {
          "title": "Paying for your care",
          "fileName": "forms/DE_FCA_paying.md"
        },
        {
          "title": "Personal budgets",
          "fileName": "forms/DE_FCA_personal_budget.md"
        },
        {
          "title": "Deferred payment scheme",
          "fileName": "forms/DE_FCA_deferred_payment.md"
        },
        {
          "title": "Independent financial advice",
          "fileName": "forms/DE_FCA_independent_financial_advice.md"
        },
        {
          "title": "Disability Related Expenditure (DRE) – what you need to know",
          "fileName": "forms/DE_FCA_DRE_need_to_know.md"
        }
      ]
    },
    "SOT_FCA": {
      "title": "Further information",
      "links": [
        {
          "title": "Information you will need to complete your financial assessment",
          "fileName": "forms/SOT_FCA_need.md"
        },
        {
          "title": "Change of circumstances",
          "fileName": "forms/SOT_FCA_changeofcircs.md"
        },
        {
          "title": "Paying your assessed contribution",
          "fileName": "forms/SOT_FCA_pay.md"
        },
        {
          "title": "Independent financial advice",
          "fileName": "customer/southampton/cfa_financial_advice.md"
        },
        {
          "title": "What happens next?",
          "fileName": "forms/SOT_FCA_next.md"
        }
      ]
    },
    "WF_FCA": {
      "title": "Further information",
      "links": [
        {
          "title": "Information you will need to complete your financial assessment",
          "fileName": "forms/WF_FCA_need.md"
        },
        {
          "title": "Change of circumstances",
          "fileName": "forms/WF_FCA_changeofcircs.md"
        },
        {
          "title": "Independent financial advice",
          "fileName": "customer/wakefield/cfa_financial_advice.md"
        },
        {
          "title": "What happens next?",
          "fileName": "forms/WF_FCA_next.md"
        }
      ]
    },
    "DO_FCA": {
      "title": "Further information",
      "links": [
        {
          "title": "Information you will need to complete your financial assessment",
          "fileName": "forms/DO_FCA_need.md"
        },
        {
          "title": "Paying your assessed contribution",
          "fileName": "forms/DO_FCA_pay.md"
        },
        {
          "title": "What to do if your financial circumstances change",
          "fileName": "forms/DO_FCA_changeofcircs.md"
        },
        {
          "title": "Independent financial advice",
          "fileName": "customer/doncaster/cfa_financial_advice.md"
        },
        {
          "title": "What happens next?",
          "fileName": "forms/DO_FCA_next.md"
        }
      ]
    },
    "SG_FCA": {
      "title": "Further information",
      "links": [
        {
          "title": "Information you will need to complete your financial assessment",
          "fileName": "forms/SG_FCA_need.md"
        },
        {
          "title": "What to do if your financial circumstances change",
          "fileName": "forms/SG_FCA_changeofcircs.md"
        },
        {
          "title": "Independent financial advice",
          "fileName": "customer/southglos/cfa_financial_advice.md"
        },
        {
          "title": "What happens next?",
          "fileName": "forms/SG_FCA_next.md"
        }
      ]
    },
    "CAL_FCA": {
      "title": "Further information",
      "links": [
        {
          "title": "Information you will need to complete your financial assessment",
          "fileName": "forms/CAL_FCA_need.md"
        },
        {
          "title": "Disability related expenditure (DRE)",
          "fileName": "forms/CAL_FCA_dre.md"
        },
        {
          "title": "Changes to your financial or personal circumstances",
          "fileName": "forms/CAL_FCA_changeofcircs.md"
        },
        {
          "title": "Independent financial advice",
          "fileName": "customer/calderdale/cfa_financial_advice.md"
        },
        {
          "title": "What happens next?",
          "fileName": "forms/CAL_FCA_next.md"
        }
      ]
    },
    "CO_FCA": {
      "title": "Further information",
      "links": [
        {
          "title": "Information you will need to complete your financial assessment",
          "fileName": "forms/CO_FCA_need.md"
        },
        {
          "title": "Disability related expenditure (DRE)",
          "fileName": "forms/CO_FCA_dre.md"
        },
        {
          "title": "Changes to your financial or personal circumstances",
          "fileName": "forms/CO_FCA_changeofcircs.md"
        },
        {
          "title": "Independent financial advice",
          "fileName": "customer/cornwall/cfa_financial_advice.md"
        },
        {
          "title": "What happens next?",
          "fileName": "forms/CO_FCA_next.md"
        }
      ]
    },
    "WN_FCA": {
      "title": "Further information",
      "links": [
        {
          "title": "Information you will need to complete your financial assessment",
          "fileName": "forms/WN_FCA_need.md"
        },
        {
          "title": "Disability related expenditure (DRE)",
          "fileName": "forms/WN_FCA_dre.md"
        },
        {
          "title": "Changes to your financial or personal circumstances",
          "fileName": "forms/WN_FCA_changeofcircs.md"
        },
        {
          "title": "Independent financial advice",
          "fileName": "customer/westnorthants/cfa_financial_advice.md"
        },
        {
          "title": "What happens next?",
          "fileName": "forms/WN_FCA_next.md"
        }
      ]
    },
    "BL_FCA": {
      "title": "Further information",
      "links": [
        {
          "title": "Information you will need to complete your financial assessment",
          "fileName": "forms/BL_FCA_need.md"
        },
        {
          "title": "What to do if your circumstances change",
          "fileName": "forms/BL_FCA_changeofcircs.md"
        },
        {
          "title": "Independent financial advice",
          "fileName": "customer/blackpool/cfa_financial_advice.md"
        },
        {
          "title": "What happens next?",
          "fileName": "forms/BL_FCA_next.md"
        }
      ]
    },
    "HA_FCA": {
      "title": "Further information",
      "links": [
        {
          "title": "Information you will need to complete your financial assessment",
          "fileName": "forms/HA_FCA_need.md"
        },
        {
          "title": "What to do if your circumstances change",
          "fileName": "forms/HA_FCA_changeofcircs.md"
        },
        {
          "title": "Independent financial advice",
          "fileName": "customer/halton/cfa_financial_advice.md"
        },
        {
          "title": "What happens next?",
          "fileName": "forms/HA_FCA_next.md"
        },
        {
          "title": "Paying for your care",
          "fileName": "forms/HA_FCA_directdebit.md"
        }
      ]
    },
    "WK_FCA": {
      "title": "Further information",
      "links": [
        {
          "title": "Information you will need to complete your financial assessment",
          "fileName": "forms/WK_FCA_need.md"
        },
        {
          "title": "What to do if your circumstances change",
          "fileName": "forms/WK_FCA_changeofcircs.md"
        },
        {
          "title": "Independent financial advice",
          "fileName": "customer/warwickshire/cfa_financial_advice.md"
        },
        {
          "title": "What happens next?",
          "fileName": "forms/WK_FCA_next.md"
        }
      ]
    },
    "NO_FCA": {
      "title": "Further information",
      "links": [
        {
          "title": "Information you will need to complete your financial assessment",
          "fileName": "forms/NO_FCA_need.md"
        },
        {
          "title": "What to do if your circumstances change",
          "fileName": "forms/NO_FCA_changeofcircs.md"
        },
        {
          "title": "Independent financial advice",
          "fileName": "customer/nottinghamshire/cfa_financial_advice.md"
        },
        {
          "title": "Disability related expenditure",
          "fileName": "forms/NO_FCA_dre.md"
        }
      ]
    },
    "HAM_FCA": {
      "title": "Further information",
      "links": [
        {
          "title": "Information you will need to complete your financial assessment",
          "fileName": "forms/HAM_FCA_need.md"
        },
        {
          "title": "What to do if your financial circumstances change",
          "fileName": "forms/HAM_FCA_changeofcircs.md"
        },
        {
          "title": "Independent financial advice",
          "fileName": "customer/hampshire/cfa_financial_advice.md"
        },
        {
          "title": "Disability-related expenditure",
          "fileName": "forms/HAM_FCA_dre.md"
        }
      ]
    },
    "SOM_FCA": {
      "title": "Further information",
      "links": [
        {
          "title": "Information you will need to complete your financial assessment",
          "fileName": "forms/SOM_FCA_need.md"
        },
        {
          "title": "What to do if your circumstances change",
          "fileName": "forms/SOM_FCA_changeofcircs.md"
        },
        {
          "title": "Independent financial advice",
          "fileName": "customer/somerset/cfa_financial_advice.md"
        },
        {
          "title": "Disability related expenditure",
          "fileName": "forms/SOM_FCA_dre.md"
        }
      ]
    },
    "CAM_FCA": {
      "title": "Further information",
      "links": [
        {
          "title": "Information you will need to complete your financial assessment",
          "fileName": "forms/CAM_FCA_need.md"
        },
        {
          "title": "What to do if your circumstances change",
          "fileName": "forms/CAM_FCA_changeofcircs.md"
        },
        {
          "title": "Independent financial advice",
          "fileName": "customer/cambridgeshire/cfa_financial_advice.md"
        },
        {
          "title": "Disability related expenditure",
          "fileName": "forms/CAM_FCA_dre.md"
        }
      ]
    },
    "HE_FCA": {
      "title": "Further information",
      "links": [
        {
          "title": "Information you will need to complete your financial assessment",
          "fileName": "forms/HE_FCA_need.md"
        },
        {
          "title": "What to do if your circumstances change",
          "fileName": "forms/HE_FCA_changeofcircs.md"
        },
        {
          "title": "Independent financial advice",
          "fileName": "customer/herefordshire/cfa_financial_advice.md"
        },
        {
          "title": "Disability-related expenditure",
          "fileName": "forms/HE_FCA_dre.md"
        },
        {
          "title": "Deprivation of assets",
          "fileName": "forms/HE_FCA_deprivation.md"
        },
        {
          "title": "Welfare benefits when in a care home",
          "fileName": "forms/HE_FCA_benefitsCareHome.md"
        },
        {
          "title": "What happens next?",
          "fileName": "forms/HE_FCA_next.md"
        },
        {
          "title": "How to pay",
          "fileName": "forms/HE_FCA_pay.md"
        }
      ]
    },
    "PE_FCA": {
      "title": "Further information",
      "links": [
        {
          "title": "Information you will need to complete your financial assessment",
          "fileName": "forms/PE_FCA_need.md"
        },
        {
          "title": "What to do if your circumstances change",
          "fileName": "forms/PE_FCA_changeofcircs.md"
        },
        {
          "title": "Independent financial advice",
          "fileName": "customer/peterborough/cfa_financial_advice.md"
        },
        {
          "title": "Disability-related expenditure",
          "fileName": "forms/PE_FCA_dre.md"
        }
      ]
    },
    "WA_FCA": {
      "title": "Further information",
      "links": [
        {
          "title": "Information you will need to complete your financial assessment",
          "fileName": "forms/WA_FCA_need.md"
        },
        {
          "title": "What to do if your circumstances change",
          "fileName": "forms/WA_FCA_changeofcircs.md"
        },
        {
          "title": "Independent financial advice",
          "fileName": "customer/walsall/cfa_financial_advice.md"
        },
        {
          "title": "Disability related expenditure",
          "fileName": "forms/WA_FCA_dre.md"
        }
      ]
    },
    "FCA": {
      "title": "Further information",
      "links": [
        {
          "title": "Information you will need to complete your assessment",
          "fileName": "forms/FCA_need.md"
        },
        {
          "title": "How much will you have to pay for your care?",
          "fileName": "forms/FCA_how_much_will_i_pay.md"
        },
        {
          "title": "Disability Related Expenditure (DRE) – what you need to know",
          "fileName": "forms/FCA_DRE_need_to_know.md"
        }
      ]
    },
    "HB_CTR_FSM": {
      "title": "Further information",
      "links": [
        {
          "title": "What is Housing Benefit and Council Tax Reduction?",
          "fileName": "forms/HB_what.md"
        },
        {
          "title": "Things you will need to complete the form",
          "fileName": "forms/HB_complete.md"
        },
        {
          "title": "How you will be paid",
          "fileName": "forms/HB_paid.md"
        },
        {
          "title": "How to appeal a decision",
          "fileName": "forms/HB_appeal.md"
        }
      ]
    },
    "TO_FCA": {
      "title": "Further information",
      "links": [
        {
          "title": "Information you will need to complete your financial assessment",
          "fileName": "forms/TO_FCA_need.md"
        },
        {
          "title": "What to do if your circumstances change",
          "fileName": "forms/TO_FCA_changeofcircs.md"
        },
        {
          "title": "Independent financial advice",
          "fileName": "customer/torbay/cfa_financial_advice.md"
        },
        {
          "title": "Disability related expenditure",
          "fileName": "forms/TO_FCA_dre.md"
        }
      ]
    },
    "WO_FCA": {
      "title": "Further information",
      "links": [
        {
          "title": "Information you will need to complete your financial assessment",
          "fileName": "forms/WO_FCA_need.md"
        },
        {
          "title": "What to do if your financial circumstances change",
          "fileName": "forms/WO_FCA_changeofcircs.md"
        },
        {
          "title": "Independent financial advice",
          "fileName": "customer/wokingham/cfa_financial_advice.md"
        },
        {
          "title": "Disability-related expenditure",
          "fileName": "forms/WO_FCA_dre.md"
        },
        {
          "title": "What happens after a financial assessment",
          "fileName": "forms/WO_FCA_next.md"
        }
      ]
    },
    "WOL_FCA": {
      "title": "Further information",
      "links": [
        {
          "title": "Information you will need to complete your financial assessment",
          "fileName": "forms/WOL_FCA_need.md"
        },
        {
          "title": "What to do if your financial circumstances change",
          "fileName": "forms/WOL_FCA_changeofcircs.md"
        },
        {
          "title": "Independent financial advice",
          "fileName": "customer/wolverhampton/cfa_financial_advice.md"
        },
        {
          "title": "Disability-related expenditure",
          "fileName": "forms/WOL_FCA_dre.md"
        },
        {
          "title": "What happens after a financial assessment",
          "fileName": "forms/WOL_FCA_next.md"
        }
      ]
    }
  }
}
