'use strict';

angular
  .module('kerp-forms.forms')
  .factory('Forms', ['configuration', '$translate', function (configuration, $translate) {
    var forms = [
      {
        id: "KITCHEN_SINK_SINGLE_PAGE_OPTIONAL",
        name: "Kitchen Sink - Single Page Optional",
        formService: "KS_SINGLE_PAGE_OPTIONAL_FORM",
        formSchema: "KS_OPTIONAL_SCHEMA",
        formConditions: "KS_CONDITIONS",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: true,
        has_external_form: false,
        has_details_page: true,
        is_draft_form: true,
        is_submit_once: true,
        type: 'application'
      }, {
        id: "KITCHEN_SINK_SINGLE_PAGE_REQUIRED",
        name: "Kitchen Sink - Single Page Required",
        formService: "KS_SINGLE_PAGE_REQUIRED_FORM",
        formSchema: "KS_REQUIRED_SCHEMA",
        formConditions: "KS_CONDITIONS",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: true,
        has_external_form: false,
        has_details_page: true,
        is_draft_form: true,
        is_submit_once: true,
        type: 'application'
      }, {
        id: "KITCHEN_SINK_MULTI_PAGE_REQUIRED",
        name: "Kitchen Sink - Multi-Page Required",
        formService: "KS_MULTI_PAGE_FORM",
        formSchema: "KS_REQUIRED_SCHEMA",
        formConditions: "KS_CONDITIONS",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: true,
        has_external_form: false,
        has_details_page: true,
        is_draft_form: true,
        is_submit_once: true,
        type: 'application'
      }, {
        id: "MRR",
        displayId: "MR",
        name: "Mandatory Reconsideration",
        formService: "MRR_FORM",
        formSchema: "MRR_SCHEMA",
        formMappings: 'MRR_MAPPINGS',
        has_interactive_PDF: true,
        has_flat_PDF: false,
        has_web_form: true,
        has_external_form: false,
        has_details_page: true,
        blacklist: ['enfield'],
        server_key_separator: '_',
        type: 'appeal'
      }, {
        id: "APPEAL_SSS",
        displayId: "APPEAL_SSS",
        name: "Challenge Social Security Scotland decisions",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.mygov.scot/disagree-decision/how-to-challenge-a-decision',
        externalFormActionTitle: 'Find out how to challenge a decision',
        whitelist: ['stirling'],
        type: 'appeal'
      }, {
        id: "SSCS1",
        displayId: "SSCS1",
        name: "Appeal to Tribunal (SSCS1)",
        has_interactive_PDF: true,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.gov.uk/appeal-benefit-decision/submit-appeal',
        blacklist: ['enfield'],
        type: 'appeal'
      }, {
        id: "ADP_SCOTLAND",
        name: "Adult Disability Payment",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.mygov.scot/adult-disability-payment/how-to-apply',
        externalFormActionTitle: 'Find out how to apply via mygov.scot',
        whitelist: ['stirling'],
        entitlements: ["PersonalIndependencePaymentInfo"],
        type: 'application'
      }, {
        id: "APS_INCOMMUNITIES",
        displayId: "APS_INCOMMUNITIES",
        name: "Assisted Purchase Scheme",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://espws.necsws.com/ords/pwslive/call_initial_apex_page.nes_selfsrv?service=NSF&la=brad&options=NSFFQ',
        whitelist: ['incommunities'],
        entitlements: ["SocialFund"],
        type: 'application'
      }, {
        id: "AA1A",
        displayId: "AA1A",
        name: "Attendance Allowance (AA1A)",
        formService: "AA1A_FORM",
        formSchema: "AA1A_SCHEMA",
        formMappings: 'AA1A_MAPPINGS',
        isBetterCareEligible: true,
        has_interactive_PDF: true,
        has_flat_PDF: true,
        has_web_form: true,
        has_external_form: false,
        has_details_page: true,
        server_key_separator: '.',
        entitlements: ["AttendanceAllowanceInfo"],
        blacklist: ['coventry', 'southampton'],
        type: 'application'
      }, {
        id: "AA",
        name: "Attendance Allowance",
        isBetterCareEligible: true,
        has_external_form: true,
        external_link: 'https://www.gov.uk/attendance-allowance',
        whitelist: ['demo-care'],
        type: 'application'
      }, {
        id: "ATTENDANCE_ALLOWANCE",
        name: "Attendance Allowance",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.gov.uk/attendance-allowance/how-to-claim',
        externalFormActionTitle: 'Find out how to claim via GOV.UK',
        whitelist: ['southampton'],
        type: 'application'
      }, {
        id: "E2",
        name: "Benefits calculator",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://www.entitledto.co.uk/benefits-calculator/',
        externalFormActionTitle: 'Check your benefit entitlements',
        whitelist: ['cambridgeshire', 'cornwall', 'ealing'],
        type: 'application'
      }, {
        id: "ESA",
        name: "Employment and Support Allowance",
        isBetterCareEligible: true,
        has_external_form: true,
        external_link: 'https://www.gov.uk/employment-support-allowance',
        whitelist: ['demo-care'],
        type: 'application'
      }, {
        id: "ESA_CAPABILITY",
        name: "ESA - Capability for Work Questionnaire ",
        isBetterCareEligible: true,
        has_external_form: true,
        external_link: 'https://www.gov.uk/government/publications/capability-for-work-questionnaire',
        whitelist: ['demo-care'],
        type: 'application'
      }, {
        id: "BEST_START_GRANT",
        displayId: "BSG",
        name: "Best Start Grant",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.mygov.scot/best-start-grant-best-start-foods/how-to-apply',
        externalFormActionTitle: 'Apply on mygov.scot',
        whitelist: ['stirling'],
        type: 'application'
      }, {
        id: "BSP",
        displayId: "BSP",
        name: "Bereavement Support Payment",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.gov.uk/bereavement-support-payment',
        externalFormActionTitle: 'Find out how to apply via GOV.UK',
        entitlements: ["BereavementAllowanceInfo", "WidowedParentsAllowanceInfo"],
        type: 'application'
      }, {
        id: "BLUE_BADGES_SALFORD",
        displayId: "BB",
        name: "Blue Badges",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.gov.uk/apply-blue-badge',
        externalFormActionTitle: 'Apply for a Blue Badge',
        whitelist: ['salford'],
        type: 'application'
      }, {
        id: "BLUE_BADGE_LEWISHAM",
        displayId: "BB",
        name: "Blue Badge",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://lewisham.gov.uk/myservices/parking/disabled-parking/how-to-apply-for-or-renew-a-blue-badge',
        whitelist: ['lewisham'],
        type: 'application'
      }, {
        id: "BG",
        displayId: "BG",
        name: "Boiler Grants",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.boilergrants.org.uk/',
        externalFormActionTitle: 'Find out if you qualify',
        blacklist: ['stirling'],
        entitlements: ["BoilerGrants"],
        type: 'application'
      }, {
        id: "BUS",
        displayId: "BUS",
        name: "Boiler Upgrade Scheme",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://www.gov.uk/apply-boiler-upgrade-scheme',
        externalFormActionTitle: 'Find out if you qualify',
        blacklist: ['stirling'],
        entitlements: ["BoilerGrants"],
        type: 'application'
      }, {
        id: "BL",
        displayId: "BL",
        name: "Budgeting Loans",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.gov.uk/budgeting-help-benefits/how-to-apply',
        externalFormActionTitle: 'Apply for a Budgeting Loan via GOV.UK',
        blacklist: ['coventry', 'enfield', 'southampton'],
        type: 'application'
      }, {
        id: "CLAS_CAMBRIDGESHIRE",
        displayId: "CLAS_CAMBRIDGESHIRE",
        name: "Cambridgeshire Local Assistance Scheme",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://www.cambridgeshire.gov.uk/residents/adults/money-and-benefits/cambridgeshire-local-assistance-scheme',
        whitelist: ['cambridgeshire'],
        entitlements: ["SocialFund"],
        type: 'application'
      }, {
        id: "KR_CA",
        name: "Financial Assistance for Carephone Package",
        formService: "KR_CA_FORM",
        formSchema: "KR_CA_SCHEMA",
        formConditions: "KR_CA_CONDITIONS",
        formMappings: 'KR_CA_MAPPINGS',
        defaultValidationMessage: $translate.instant('forms.validation.required'),
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: true,
        has_external_form: false,
        has_details_page: true,
        is_submit_once: true,
        whitelist: ['demo', 'demo-care', 'kirklees'],
        type: 'assessment'
      }, {
        id: "FCA",
        name: "Care Financial Assistance - full assessment",
        formService: "FCA_FORM",
        formSchema: "FCA_SCHEMA",
        formConditions: "FCA_CONDITIONS",
        formMappings: 'FCA_MAPPINGS',
        defaultValidationMessage: $translate.instant('forms.validation.required'),
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: true,
        has_external_form: false,
        has_details_page: true,
        is_submit_once: true,
        evidence: {
          customFields: {
            claim_ref_field: 'clientReference',
            uprn_model_field: 'person.details.address.uprn',
            anite_filesystem: 'CF'
          },
          include_in_PDF: true,
          types: [
            {id: 'id', docTypeCode: 'CFID'},
            {id: 'nino', docTypeCode: 'CFID'},
            {id: 'rent', docTypeCode: 'CFRENT'},
            {id: 'income', docTypeCode: 'CFWAGE'},
            {id: 'pension', docTypeCode: 'CFOCCPEN'},
            {id: 'award', docTypeCode: 'CFBENLTR'},
            {id: 'capital', docTypeCode: 'CFBANK'},
            {id: 'appointeeship', docTypeCode: 'CFPOA'}
          ]
        },
        whitelist: ['demo', 'demo-care', 'kirklees'],
        type: 'assessment'
      }, {
        id: "LI_CA",
        name: "Adult Care charges calculator",
        formService: "LI_CA_FORM",
        formSchema: "LI_CA_SCHEMA",
        formConditions: "LI_CA_CONDITIONS",
        formMappings: 'LI_CA_MAPPINGS',
        defaultValidationMessage: $translate.instant('forms.validation.required'),
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: true,
        has_external_form: false,
        has_details_page: true,
        is_completable: true,
        whitelist: ['lincolnshire', 'demo-care'],
        isBetterCareEligible: true,
        type: 'assessment'
      }, {
        id: "LI_FCA",
        name: "Financial Assessment Form (Adult Care)",
        formService: "LI_FCA_FORM",
        formSchema: "LI_FCA_SCHEMA",
        formConditions: "LI_FCA_CONDITIONS",
        formMappings: 'LI_FCA_MAPPINGS',
        defaultValidationMessage: $translate.instant('forms.validation.required'),
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: true,
        has_external_form: false,
        has_details_page: true,
        is_submit_once: true,
        evidence: {
          include_in_PDF: true,
          descriptions: [
            'Bank statement',
            'Bank statement showing your benefit/pension/income payments',
            'Letter from Jobcentre Plus',
            'Letter from HM Revenue and Customs (HMRC)',
            'Letter from the Pension Service',
            'National Insurance card',
            'P45',
            'P60',
            'Payslip',
            'Pension advice slip',
            'Rent book',
            'Salary statement',
            'Statement of charges from landlord',
            'Tenancy agreement',
            'Works pension statement',
            'BF57 confirming you as appointee',
            'Savings bank statement',
            'Benefit award notification letter',
            'Buildings insurance policy cover sheet',
            'Council Tax annual bill',
            'Council Tax Support statement and breakdown',
            'Court order confirming deputyship',
            'Court order for compulsory payment',
            'Letter from DWP confirming you as appointee',
            'Mortgage statement',
            'Official Power of Attorney',
            'Proof of child support',
            'Proof of maintenance payments',
            'Proof of ownership of bond',
            'Proof of ownership of investment',
            'Proof of ownership of property',
            'Property valuation',
            'Screenshot of Universal Credit entitlement',
            'Share certificate',
            'Water bills',
            'Self-funding residential care'
          ]
        },
        whitelist: ['lincolnshire'],
        type: 'assessment'
      }, {
        id: "LI_RFCA",
        name: "DRAFT Financial Assessment Form (Adult Care)",
        formService: "LI_RFCA_FORM",
        formSchema: "LI_RFCA_SCHEMA",
        formConditions: "LI_RFCA_CONDITIONS",
        formMappings: 'LI_RFCA_MAPPINGS',
        defaultValidationMessage: $translate.instant('forms.validation.required'),
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: true,
        has_external_form: false,
        has_details_page: true,
        is_submit_once: true,
        evidence: {
          include_in_PDF: true,
          descriptions: [
            'Bank statement',
            'Bank statement showing your benefit/pension/income payments',
            'Letter from Jobcentre Plus',
            'Letter from HM Revenue and Customs (HMRC)',
            'Letter from the Pension Service',
            'National Insurance card',
            'P45',
            'P60',
            'Payslip',
            'Pension advice slip',
            'Rent book',
            'Salary statement',
            'Statement of charges from landlord',
            'Tenancy agreement',
            'Works pension statement',
            'BF57 confirming you as appointee',
            'Savings bank statement',
            'Benefit award notification letter',
            'Buildings insurance policy cover sheet',
            'Council Tax annual bill',
            'Council Tax Support statement and breakdown',
            'Court order confirming deputyship',
            'Court order for compulsory payment',
            'Letter from DWP confirming you as appointee',
            'Mortgage statement',
            'Official Power of Attorney',
            'Proof of child support',
            'Proof of maintenance payments',
            'Proof of ownership of bond',
            'Proof of ownership of investment',
            'Proof of ownership of property',
            'Property valuation',
            'Screenshot of Universal Credit entitlement',
            'Share certificate',
            'Water bills',
            'Self-funding residential care'
          ]
        },
        whitelist: ['lincolnshire'],
        type: 'assessment',
        is_draft_form: true
      }, {
        id: "COV_FCA",
        name: "Financial Assessment Form (Adult Care)",
        formService: "COV_FCA_FORM",
        formSchema: "COV_FCA_SCHEMA",
        formConditions: "COV_FCA_CONDITIONS",
        formMappings: 'COV_FCA_MAPPINGS',
        defaultValidationMessage: $translate.instant('forms.validation.required'),
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: true,
        has_external_form: false,
        has_details_page: true,
        is_submit_once: true,
        evidence: {
          include_in_PDF: true,
          descriptions: [
            'Bank statement',
            'Bank statement showing your benefit/pension/income payments',
            'Letter from Jobcentre Plus',
            'Letter from HM Revenue and Customs (HMRC)',
            'Letter from the Pension Service',
            'National Insurance card',
            'P45',
            'P60',
            'Payslip',
            'Pension advice slip',
            'Rent book',
            'Salary statement',
            'Statement of charges from landlord',
            'Tenancy agreement',
            'Works pension statement',
            'BF57 confirming you as appointee',
            'Savings bank statement',
            'Benefit award notification letter',
            'Buildings insurance policy cover sheet',
            'Council Tax annual bill',
            'Council Tax Support statement and breakdown',
            'Court order confirming deputyship',
            'Court order for compulsory payment',
            'Letter from DWP confirming you as appointee',
            'Mortgage statement',
            'Official Power of Attorney',
            'Proof of child support',
            'Proof of maintenance payments',
            'Proof of ownership of bond',
            'Proof of ownership of investment',
            'Proof of ownership of property',
            'Property valuation',
            'Screenshot of Universal Credit entitlement',
            'Share certificate',
            'Water bills',
            'Self-funding residential care'
          ]
        },
        whitelist: ['coventry'],
        type: 'assessment',
        isBetterCareEligible: true
      }, {
        id: "NL_FCA",
        name: "Financial Assessment Form (Adult Care)",
        formService: "NL_FCA_FORM",
        formSchema: "NL_FCA_SCHEMA",
        formConditions: "NL_FCA_CONDITIONS",
        formMappings: 'NL_FCA_MAPPINGS',
        defaultValidationMessage: $translate.instant('forms.validation.required'),
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: true,
        has_external_form: false,
        has_details_page: true,
        is_submit_once: true,
        evidence: {
          include_in_PDF: true,
          descriptions: [
            'Bank statement',
            'Bank statement showing your benefit/pension/income payments',
            'Letter from Jobcentre Plus',
            'Letter from HM Revenue and Customs (HMRC)',
            'Letter from the Pension Service',
            'National Insurance card',
            'P45',
            'P60',
            'Payslip',
            'Pension advice slip',
            'Rent book',
            'Salary statement',
            'Statement of charges from landlord',
            'Tenancy agreement',
            'Works pension statement',
            'BF57 confirming you as appointee',
            'Savings bank statement',
            'Benefit award notification letter',
            'Buildings insurance policy cover sheet',
            'Council Tax annual bill',
            'Council Tax Support statement and breakdown',
            'Court order confirming deputyship',
            'Court order for compulsory payment',
            'Letter from DWP confirming you as appointee',
            'Mortgage statement',
            'Official Power of Attorney',
            'Proof of child support',
            'Proof of maintenance payments',
            'Proof of ownership of bond',
            'Proof of ownership of investment',
            'Proof of ownership of property',
            'Property valuation',
            'Screenshot of Universal Credit entitlement',
            'Share certificate',
            'Water bills',
            'Self-funding residential care'
          ]
        },
        whitelist: ['northlincs'],
        type: 'assessment',
        isBetterCareEligible: true
      }, {
        id: "SOT_FCA",
        name: "Financial Assessment Form (Adult Social Care)",
        formService: "SOT_FCA_FORM",
        formSchema: "SOT_FCA_SCHEMA",
        formConditions: "SOT_FCA_CONDITIONS",
        formMappings: 'SOT_FCA_MAPPINGS',
        defaultValidationMessage: $translate.instant('forms.validation.required'),
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: true,
        has_external_form: false,
        has_details_page: true,
        is_submit_once: true,
        evidence: {
          include_in_PDF: true,
          descriptions: [
            'Birth Certificate',
            'Marriage Certificate',
            'Driving licence',
            'Passport',
            'National identity card',
            'Utility bill',
            'National Insurance card',
            'Bank statement',
            'Bank statement showing your benefit/pension/income payments',
            'P45',
            'P60',
            'Payslip',
            'Salary statement',
            'Works pension statement',
            'Letter from Jobcentre Plus',
            'Letter from HM Revenue and Customs (HMRC)',
            'Letter from the Pension Service',
            'Mortgage statement',
            'Tenancy agreement',
            'Rent book',
            'Statement of charges from landlord',
            'Pension advice slip',
            'Benefit award notification letter',
            'Buildings insurance policy cover sheet',
            'Council Tax annual bill',
            'Council Tax Support statement and breakdown',
            'Court order confirming deputyship',
            'Court order for compulsory payment',
            'Letter from DWP confirming you as appointee',
            'Official Power of Attorney',
            'BF57 confirming you as appointee',
            'Proof of child support',
            'Proof of maintenance payments',
            'Proof of ownership of bond',
            'Proof of ownership of investment',
            'Proof of ownership of property',
            'Property valuation',
            'Screenshot of Universal Credit entitlement',
            'Share certificate',
            'Water bills',
            'Receipt'
          ]
        },
        whitelist: ['southampton'],
        type: 'assessment',
        isBetterCareEligible: true,
        blockSubmissionWithoutClientID: true
      }, {
        id: "DE_FCA",
        name: "Financial Assessment Form (Adult Social Care)",
        formService: "DE_FCA_FORM",
        formSchema: "DE_FCA_SCHEMA",
        formConditions: "DE_FCA_CONDITIONS",
        formMappings: 'DE_FCA_MAPPINGS',
        defaultValidationMessage: $translate.instant('forms.validation.required'),
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: true,
        has_external_form: false,
        has_details_page: true,
        is_submit_once: true,
        evidence: {
          include_in_PDF: true,
          descriptions: [
            'Birth Certificate',
            'Marriage Certificate',
            'Driving licence',
            'Passport',
            'National identity card',
            'Utility bill',
            'National Insurance card',
            'Bank statement',
            'Bank statement showing your benefit/pension/income payments',
            'P45',
            'P60',
            'Payslip',
            'Salary statement',
            'Works pension statement',
            'Letter from Jobcentre Plus',
            'Letter from HM Revenue and Customs (HMRC)',
            'Letter from the Pension Service',
            'Mortgage statement',
            'Tenancy agreement',
            'Rent book',
            'Statement of charges from landlord',
            'Pension advice slip',
            'Benefit award notification letter',
            'Buildings insurance policy cover sheet',
            'Council Tax annual bill',
            'Council Tax Support statement and breakdown',
            'Court order confirming deputyship',
            'Court order for compulsory payment',
            'Letter from DWP confirming you as appointee',
            'Official Power of Attorney',
            'BF57 confirming you as appointee',
            'Proof of child support',
            'Proof of maintenance payments',
            'Proof of ownership of bond',
            'Proof of ownership of investment',
            'Proof of ownership of property',
            'Property valuation',
            'Screenshot of Universal Credit entitlement',
            'Share certificate',
            'Water bills',
            'Receipt'
          ]
        },
        whitelist: ['derbyshire'],
        type: 'assessment',
        isBetterCareEligible: true
      }, {
        id: "WF_FCA",
        name: "Financial Assessment Form (Adult Social Care)",
        formService: "WF_FCA_FORM",
        formSchema: "WF_FCA_SCHEMA",
        formConditions: "WF_FCA_CONDITIONS",
        formMappings: 'WF_FCA_MAPPINGS',
        defaultValidationMessage: $translate.instant('forms.validation.required'),
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: true,
        has_external_form: false,
        has_details_page: true,
        is_submit_once: true,
        evidence: {
          include_in_PDF: true,
          descriptions: [
            'Birth Certificate',
            'Marriage Certificate',
            'Driving licence',
            'Passport',
            'National identity card',
            'Utility bill',
            'National Insurance card',
            'Bank statement',
            'Bank statement showing your benefit/pension/income payments',
            'P45',
            'P60',
            'Payslip',
            'Salary statement',
            'Works pension statement',
            'Letter from Jobcentre Plus',
            'Letter from HM Revenue and Customs (HMRC)',
            'Letter from the Pension Service',
            'Mortgage statement',
            'Tenancy agreement',
            'Rent book',
            'Statement of charges from landlord',
            'Pension advice slip',
            'Benefit award notification letter',
            'Buildings insurance policy cover sheet',
            'Council Tax annual bill',
            'Council Tax Support statement and breakdown',
            'Court order confirming deputyship',
            'Court order for compulsory payment',
            'Letter from DWP confirming you as appointee',
            'Official Power of Attorney',
            'BF57 confirming you as appointee',
            'Proof of child support',
            'Proof of maintenance payments',
            'Proof of ownership of bond',
            'Proof of ownership of investment',
            'Proof of ownership of property',
            'Property valuation',
            'Screenshot of Universal Credit entitlement',
            'Share certificate',
            'Water bills',
            'Receipt',
            'Transfers / Gifting'
          ]
        },
        whitelist: ['wakefield'],
        type: 'assessment',
        isBetterCareEligible: true
      }, {
        id: "WK_FCA",
        name: "Financial Assessment Form (Adult Social Care)",
        formService: "WK_FCA_FORM",
        formSchema: "WK_FCA_SCHEMA",
        formConditions: "WK_FCA_CONDITIONS",
        formMappings: 'WK_FCA_MAPPINGS',
        defaultValidationMessage: $translate.instant('forms.validation.required'),
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: true,
        has_external_form: false,
        has_details_page: true,
        is_submit_once: true,
        evidence: {
          include_in_PDF: true,
          descriptions: [
            'Birth Certificate',
            'Marriage Certificate',
            'Driving licence',
            'Passport',
            'National identity card',
            'Utility bill',
            'National Insurance card',
            'Bank statement',
            'Bank statement showing your benefit/pension/income payments',
            'P45',
            'P60',
            'Payslip',
            'Salary statement',
            'Works pension statement',
            'Letter from Jobcentre Plus',
            'Letter from HM Revenue and Customs (HMRC)',
            'Letter from the Pension Service',
            'Mortgage statement',
            'Tenancy agreement',
            'Rent book',
            'Statement of charges from landlord',
            'Pension advice slip',
            'Benefit award notification letter',
            'Buildings insurance policy cover sheet',
            'Council Tax annual bill',
            'Council Tax Support statement and breakdown',
            'Court order confirming deputyship',
            'Court order for compulsory payment',
            'Letter from DWP confirming you as appointee',
            'Official Power of Attorney',
            'BF57 confirming you as appointee',
            'Proof of child support',
            'Proof of maintenance payments',
            'Proof of ownership of bond',
            'Proof of ownership of investment',
            'Proof of ownership of property',
            'Property valuation',
            'Screenshot of Universal Credit entitlement',
            'Share certificate',
            'Water bills',
            'Receipt',
            'Transfers / Gifting'
          ]
        },
        whitelist: ['warwickshire'],
        type: 'assessment',
        isBetterCareEligible: true
      }, {
        id: "BL_FCA",
        name: "Financial Assessment Form (Adult Social Care)",
        formService: "BL_FCA_FORM",
        formSchema: "BL_FCA_SCHEMA",
        formConditions: "BL_FCA_CONDITIONS",
        formMappings: 'BL_FCA_MAPPINGS',
        defaultValidationMessage: $translate.instant('forms.validation.required'),
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: true,
        has_external_form: false,
        has_details_page: true,
        is_submit_once: true,
        evidence: {
          include_in_PDF: true,
          descriptions: [
            'Birth Certificate',
            'Marriage Certificate',
            'Driving licence',
            'Passport',
            'National identity card',
            'Utility bill',
            'National Insurance card',
            'Bank statement',
            'Bank statement showing your benefit/pension/income payments',
            'P45',
            'P60',
            'Payslip',
            'Salary statement',
            'Works pension statement',
            'Letter from Jobcentre Plus',
            'Letter from HM Revenue and Customs (HMRC)',
            'Letter from the Pension Service',
            'Mortgage statement',
            'Tenancy agreement',
            'Rent book',
            'Statement of charges from landlord',
            'Pension advice slip',
            'Benefit award notification letter',
            'Buildings insurance policy cover sheet',
            'Council Tax annual bill',
            'Council Tax Support statement and breakdown',
            'Court order confirming deputyship',
            'Court order for compulsory payment',
            'Letter from DWP confirming you as appointee',
            'Official Power of Attorney',
            'BF57 confirming you as appointee',
            'Proof of child support',
            'Proof of maintenance payments',
            'Proof of ownership of bond',
            'Proof of ownership of investment',
            'Proof of ownership of property',
            'Property valuation',
            'Screenshot of Universal Credit entitlement',
            'Share certificate',
            'Water bills',
            'Receipt',
            'Transfers / Gifting'
          ]
        },
        whitelist: ['blackpool'],
        type: 'assessment',
        isBetterCareEligible: true
      }, {
        id: "HA_FCA",
        name: "Financial Assessment Form (Adult Social Care)",
        formService: "HA_FCA_FORM",
        formSchema: "HA_FCA_SCHEMA",
        formConditions: "HA_FCA_CONDITIONS",
        formMappings: 'HA_FCA_MAPPINGS',
        defaultValidationMessage: $translate.instant('forms.validation.required'),
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: true,
        has_external_form: false,
        has_details_page: true,
        is_submit_once: true,
        evidence: {
          include_in_PDF: true,
          descriptions: [
            'Birth Certificate',
            'Marriage Certificate',
            'Driving licence',
            'Passport',
            'National identity card',
            'Utility bill',
            'National Insurance card',
            'Bank statement',
            'Bank statement showing your benefit/pension/income payments',
            'P45',
            'P60',
            'Payslip',
            'Salary statement',
            'Works pension statement',
            'Letter from Jobcentre Plus',
            'Letter from HM Revenue and Customs (HMRC)',
            'Letter from the Pension Service',
            'Mortgage statement',
            'Tenancy agreement',
            'Rent book',
            'Statement of charges from landlord',
            'Pension advice slip',
            'Benefit award notification letter',
            'Buildings insurance policy cover sheet',
            'Council Tax annual bill',
            'Council Tax Support statement and breakdown',
            'Court order confirming deputyship',
            'Court order for compulsory payment',
            'Letter from DWP confirming you as appointee',
            'Official Power of Attorney',
            'BF57 confirming you as appointee',
            'Proof of child support',
            'Proof of maintenance payments',
            'Proof of ownership of bond',
            'Proof of ownership of investment',
            'Proof of ownership of property',
            'Property valuation',
            'Screenshot of Universal Credit entitlement',
            'Share certificate',
            'Water bills',
            'Receipt',
            'Transfers / Gifting'
          ]
        },
        whitelist: ['halton'],
        type: 'assessment',
        isBetterCareEligible: true
      }, {
        id: "DO_FCA",
        name: "Financial Assessment Form (Adult Social Care)",
        formService: "DO_FCA_FORM",
        formSchema: "DO_FCA_SCHEMA",
        formConditions: "DO_FCA_CONDITIONS",
        formMappings: 'DO_FCA_MAPPINGS',
        defaultValidationMessage: $translate.instant('forms.validation.required'),
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: true,
        has_external_form: false,
        has_details_page: true,
        is_submit_once: true,
        evidence: {
          include_in_PDF: true,
          descriptions: [
            'Birth Certificate',
            'Marriage Certificate',
            'Driving licence',
            'Passport',
            'National identity card',
            'Utility bill',
            'National Insurance card',
            'Bank statement',
            'Bank statement showing your benefit/pension/income payments',
            'P45',
            'P60',
            'Payslip',
            'Salary statement',
            'Works pension statement',
            'Letter from Jobcentre Plus',
            'Letter from HM Revenue and Customs (HMRC)',
            'Letter from the Pension Service',
            'Mortgage statement',
            'Tenancy agreement',
            'Rent book',
            'Statement of charges from landlord',
            'Pension advice slip',
            'Benefit award notification letter',
            'Buildings insurance policy cover sheet',
            'Council Tax annual bill',
            'Council Tax Support statement and breakdown',
            'Court order confirming deputyship',
            'Court order for compulsory payment',
            'Letter from DWP confirming you as appointee',
            'Official Power of Attorney',
            'BF57 confirming you as appointee',
            'Proof of child support',
            'Proof of maintenance payments',
            'Proof of ownership of bond',
            'Proof of ownership of investment',
            'Proof of ownership of property',
            'Property valuation',
            'Screenshot of Universal Credit entitlement',
            'Share certificate',
            'Water bills',
            'Receipt',
            'Transfers / Gifting'
          ]
        },
        whitelist: ['doncaster'],
        type: 'assessment',
        isBetterCareEligible: true
      }, {
        id: "SG_FCA",
        name: "Financial Assessment form (Adult social care)",
        formService: "SG_FCA_FORM",
        formSchema: "SG_FCA_SCHEMA",
        formConditions: "SG_FCA_CONDITIONS",
        formMappings: 'SG_FCA_MAPPINGS',
        defaultValidationMessage: $translate.instant('forms.validation.required'),
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: true,
        has_external_form: false,
        has_details_page: true,
        is_submit_once: true,
        evidence: {
          include_in_PDF: true,
          descriptions: [
            'Birth Certificate',
            'Marriage Certificate',
            'Driving licence',
            'Passport',
            'National identity card',
            'Utility bill',
            'National Insurance card',
            'Bank statement',
            'Bank statement showing your benefit/pension/income payments',
            'P45',
            'P60',
            'Payslip',
            'Salary statement',
            'Works pension statement',
            'Letter from Jobcentre Plus',
            'Letter from HM Revenue and Customs (HMRC)',
            'Letter from the Pension Service',
            'Mortgage statement',
            'Tenancy agreement',
            'Rent book',
            'Statement of charges from landlord',
            'Pension advice slip',
            'Benefit award notification letter',
            'Buildings insurance policy cover sheet',
            'Council Tax annual bill',
            'Council Tax Support statement and breakdown',
            'Court order confirming deputyship',
            'Court order for compulsory payment',
            'Letter from DWP confirming you as appointee',
            'Official Power of Attorney',
            'BF57 confirming you as appointee',
            'Proof of child support',
            'Proof of maintenance payments',
            'Proof of ownership of bond',
            'Proof of ownership of investment',
            'Proof of ownership of property',
            'Property valuation',
            'Screenshot of Universal Credit entitlement',
            'Share certificate',
            'Water bills',
            'Receipt',
            'Transfers / Gifting'
          ]
        },
        whitelist: ['southglos'],
        type: 'assessment',
        isBetterCareEligible: true
      },
      {
        id: "NO_FCA",
        name: "Financial Assessment Form (Adult Social Care)",
        formService: "NO_FCA_FORM",
        formSchema: "NO_FCA_SCHEMA",
        formConditions: "NO_FCA_CONDITIONS",
        formMappings: 'NO_FCA_MAPPINGS',
        defaultValidationMessage: $translate.instant('forms.validation.required'),
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: true,
        has_external_form: false,
        has_details_page: true,
        is_submit_once: true,
        evidence: {
          include_in_PDF: true,
          descriptions: [
            'Birth Certificate',
            'Marriage Certificate',
            'Driving licence',
            'Passport',
            'National identity card',
            'Utility bill',
            'National Insurance card',
            'Bank statement',
            'Bank statement showing your benefit/pension/income payments',
            'P45',
            'P60',
            'Payslip',
            'Salary statement',
            'Works pension statement',
            'Letter from Jobcentre Plus',
            'Letter from HM Revenue and Customs (HMRC)',
            'Letter from the Pension Service',
            'Mortgage statement',
            'Tenancy agreement',
            'Rent book',
            'Statement of charges from landlord',
            'Pension advice slip',
            'Benefit award notification letter',
            'Buildings insurance policy cover sheet',
            'Council Tax annual bill',
            'Council Tax Support statement and breakdown',
            'Court order confirming deputyship',
            'Court order for compulsory payment',
            'Letter from DWP confirming you as appointee',
            'Official Power of Attorney',
            'BF57 confirming you as appointee',
            'Proof of child support',
            'Proof of maintenance payments',
            'Proof of ownership of bond',
            'Proof of ownership of investment',
            'Proof of ownership of property',
            'Property valuation',
            'Screenshot of Universal Credit entitlement',
            'Share certificate',
            'Water bills',
            'Receipt',
            'Transfers / Gifting'
          ]
        },
        whitelist: ['nottinghamshire'],
        type: 'assessment',
        isBetterCareEligible: true
      },
      {
        id: "WA_FCA",
        name: "Financial Assessment Form (Adult Social Care)",
        formService: "WA_FCA_FORM",
        formSchema: "WA_FCA_SCHEMA",
        formConditions: "WA_FCA_CONDITIONS",
        formMappings: 'WA_FCA_MAPPINGS',
        defaultValidationMessage: $translate.instant('forms.validation.required'),
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: true,
        has_external_form: false,
        has_details_page: true,
        is_submit_once: true,
        evidence: {
          include_in_PDF: true,
          descriptions: [
            'Birth Certificate',
            'Marriage Certificate',
            'Driving licence',
            'Passport',
            'National identity card',
            'Utility bill',
            'National Insurance card',
            'Bank statement',
            'Bank statement showing your benefit/pension/income payments',
            'P45',
            'P60',
            'Payslip',
            'Salary statement',
            'Works pension statement',
            'Letter from Jobcentre Plus',
            'Letter from HM Revenue and Customs (HMRC)',
            'Letter from the Pension Service',
            'Mortgage statement',
            'Tenancy agreement',
            'Rent book',
            'Statement of charges from landlord',
            'Pension advice slip',
            'Benefit award notification letter',
            'Buildings insurance policy cover sheet',
            'Council Tax annual bill',
            'Council Tax Support statement and breakdown',
            'Court order confirming deputyship',
            'Court order for compulsory payment',
            'Letter from DWP confirming you as appointee',
            'Official Power of Attorney',
            'BF57 confirming you as appointee',
            'Proof of child support',
            'Proof of maintenance payments',
            'Proof of ownership of bond',
            'Proof of ownership of investment',
            'Proof of ownership of property',
            'Property valuation',
            'Screenshot of Universal Credit entitlement',
            'Share certificate',
            'Water bills',
            'Receipt',
            'Transfers / Gifting'
          ]
        },
        whitelist: ['walsall'],
        type: 'assessment',
        isBetterCareEligible: true
      },
      {
        id: "CAL_FCA",
        name: "Financial Assessment Form (Adult Social Care)",
        formService: "CAL_FCA_FORM",
        formSchema: "CAL_FCA_SCHEMA",
        formConditions: "CAL_FCA_CONDITIONS",
        formMappings: 'CAL_FCA_MAPPINGS',
        defaultValidationMessage: $translate.instant('forms.validation.required'),
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: true,
        has_external_form: false,
        has_details_page: true,
        is_submit_once: true,
        evidence: {
          include_in_PDF: true,
          descriptions: [
            'Birth Certificate',
            'Marriage Certificate',
            'Driving licence',
            'Passport',
            'National identity card',
            'Utility bill',
            'National Insurance card',
            'Bank statement',
            'Bank statement showing your benefit/pension/income payments',
            'P45',
            'P60',
            'Payslip',
            'Salary statement',
            'Works pension statement',
            'Letter from Jobcentre Plus',
            'Letter from HM Revenue and Customs (HMRC)',
            'Letter from the Pension Service',
            'Mortgage statement',
            'Tenancy agreement',
            'Rent book',
            'Statement of charges from landlord',
            'Pension advice slip',
            'Benefit award notification letter',
            'Buildings insurance policy cover sheet',
            'Council Tax annual bill',
            'Council Tax Support statement and breakdown',
            'Court order confirming deputyship',
            'Court order for compulsory payment',
            'Letter from DWP confirming you as appointee',
            'Official Power of Attorney',
            'BF57 confirming you as appointee',
            'Proof of child support',
            'Proof of maintenance payments',
            'Proof of ownership of bond',
            'Proof of ownership of investment',
            'Proof of ownership of property',
            'Property valuation',
            'Screenshot of Universal Credit entitlement',
            'Share certificate',
            'Water bills',
            'Receipt',
            'Transfers / Gifting'
          ]
        },
        whitelist: ['calderdale'],
        type: 'assessment',
        isBetterCareEligible: true
      },
      {
        id: "CO_FCA",
        name: "Financial Assessment Form (Adult Social Care)",
        formService: "CO_FCA_FORM",
        formSchema: "CO_FCA_SCHEMA",
        formConditions: "CO_FCA_CONDITIONS",
        formMappings: 'CO_FCA_MAPPINGS',
        defaultValidationMessage: $translate.instant('forms.validation.required'),
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: true,
        has_external_form: false,
        has_details_page: true,
        is_submit_once: true,
        evidence: {
          include_in_PDF: true,
          descriptions: [
            'Birth Certificate',
            'Marriage Certificate',
            'Driving licence',
            'Passport',
            'National identity card',
            'Utility bill',
            'National Insurance card',
            'Bank statement',
            'Bank statement showing your benefit/pension/income payments',
            'P45',
            'P60',
            'Payslip',
            'Salary statement',
            'Works pension statement',
            'Letter from Jobcentre Plus',
            'Letter from HM Revenue and Customs (HMRC)',
            'Letter from the Pension Service',
            'Mortgage statement',
            'Tenancy agreement',
            'Rent book',
            'Statement of charges from landlord',
            'Pension advice slip',
            'Benefit award notification letter',
            'Buildings insurance policy cover sheet',
            'Council Tax annual bill',
            'Council Tax Support statement and breakdown',
            'Court order confirming deputyship',
            'Court order for compulsory payment',
            'Letter from DWP confirming you as appointee',
            'Official Power of Attorney',
            'BF57 confirming you as appointee',
            'Proof of child support',
            'Proof of maintenance payments',
            'Proof of ownership of bond',
            'Proof of ownership of investment',
            'Proof of ownership of property',
            'Property valuation',
            'Screenshot of Universal Credit entitlement',
            'Share certificate',
            'Water bills',
            'Receipt',
            'Transfers / Gifting'
          ]
        },
        whitelist: ['cornwall'],
        type: 'assessment',
        isBetterCareEligible: true,
        blockSubmissionWithoutClientID: true
      },
      {
        id: "WN_FCA",
        name: "Financial Assessment Form (Adult Social Care)",
        formService: "WN_FCA_FORM",
        formSchema: "WN_FCA_SCHEMA",
        formConditions: "WN_FCA_CONDITIONS",
        formMappings: 'WN_FCA_MAPPINGS',
        defaultValidationMessage: $translate.instant('forms.validation.required'),
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: true,
        has_external_form: false,
        has_details_page: true,
        is_submit_once: true,
        evidence: {
          include_in_PDF: true,
          descriptions: [
            'Birth Certificate',
            'Marriage Certificate',
            'Driving licence',
            'Passport',
            'National identity card',
            'Utility bill',
            'National Insurance card',
            'Bank statement',
            'Bank statement showing your benefit/pension/income payments',
            'P45',
            'P60',
            'Payslip',
            'Salary statement',
            'Works pension statement',
            'Letter from Jobcentre Plus',
            'Letter from HM Revenue and Customs (HMRC)',
            'Letter from the Pension Service',
            'Mortgage statement',
            'Tenancy agreement',
            'Rent book',
            'Statement of charges from landlord',
            'Pension advice slip',
            'Benefit award notification letter',
            'Buildings insurance policy cover sheet',
            'Council Tax annual bill',
            'Council Tax Support statement and breakdown',
            'Court order confirming deputyship',
            'Court order for compulsory payment',
            'Letter from DWP confirming you as appointee',
            'Official Power of Attorney',
            'BF57 confirming you as appointee',
            'Proof of child support',
            'Proof of maintenance payments',
            'Proof of ownership of bond',
            'Proof of ownership of investment',
            'Proof of ownership of property',
            'Property valuation',
            'Screenshot of Universal Credit entitlement',
            'Share certificate',
            'Water bills',
            'Receipt',
            'Transfers / Gifting'
          ]
        },
        whitelist: ['westnorthants'],
        type: 'assessment',
        isBetterCareEligible: true
      },
      {
        id: "HE_FCA",
        name: "Financial Assessment Form and Social Care charges calculator",
        formService: "HE_FCA_FORM",
        formSchema: "HE_FCA_SCHEMA",
        formConditions: "HE_FCA_CONDITIONS",
        formMappings: 'HE_FCA_MAPPINGS',
        defaultValidationMessage: $translate.instant('forms.validation.required'),
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: true,
        has_external_form: false,
        has_details_page: true,
        is_submit_once: true,
        evidence: {
          include_in_PDF: true,
          descriptions: [
            'Utility bill',
            'Bank statement',
            'Bank statement showing your benefit/pension/income payments',
            'P45',
            'P60',
            'Payslip',
            'Salary statement',
            'Works pension statement',
            'Letter from Jobcentre Plus',
            'Letter from HM Revenue and Customs (HMRC)',
            'Letter from the Pension Service',
            'Mortgage statement',
            'Tenancy agreement',
            'Rent book',
            'Statement of charges from landlord',
            'Pension advice slip',
            'Benefit award notification letter',
            'Buildings insurance policy cover sheet',
            'Council Tax annual bill',
            'Council Tax Support statement and breakdown',
            'Court order confirming deputyship',
            'Court order for compulsory payment',
            'Letter from DWP confirming you as appointee',
            'Official Power of Attorney',
            'BF57 confirming you as appointee',
            'Proof of child benefit',
            'Proof of child support',
            'Proof of maintenance payments',
            'Proof of ownership of bond',
            'Proof of ownership of investment',
            'Proof of ownership of property',
            'Property valuation',
            'Screenshot of Universal Credit entitlement',
            'Share certificate',
            'Water bills',
            'Receipt',
            'Transfers / Gifting'
          ]
        },
        whitelist: ['herefordshire'],
        type: 'assessment',
        isBetterCareEligible: true,
        review: {
          declaration: 'show',
        },

      },
      {
        id: "SOM_FCA",
        name: "Financial Assessment Form (Adult Social Care)",
        formService: "SOM_FCA_FORM",
        formSchema: "SOM_FCA_SCHEMA",
        formConditions: "SOM_FCA_CONDITIONS",
        formMappings: 'SOM_FCA_MAPPINGS',
        defaultValidationMessage: $translate.instant('forms.validation.required'),
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: true,
        has_external_form: false,
        has_details_page: true,
        is_submit_once: true,
        evidence: {
          include_in_PDF: true,
          descriptions: [
            'Birth Certificate',
            'Marriage Certificate',
            'Driving licence',
            'Passport',
            'National identity card',
            'Utility bill',
            'National Insurance card',
            'Bank statement',
            'Bank statement showing your benefit/pension/income payments',
            'P45',
            'P60',
            'Payslip',
            'Salary statement',
            'Works pension statement',
            'Letter from Jobcentre Plus',
            'Letter from HM Revenue and Customs (HMRC)',
            'Letter from the Pension Service',
            'Mortgage statement',
            'Tenancy agreement',
            'Rent book',
            'Statement of charges from landlord',
            'Pension advice slip',
            'Benefit award notification letter',
            'Buildings insurance policy cover sheet',
            'Council Tax annual bill',
            'Council Tax Support statement and breakdown',
            'Court order confirming deputyship',
            'Court order for compulsory payment',
            'Letter from DWP confirming you as appointee',
            'Official Power of Attorney',
            'BF57 confirming you as appointee',
            'Proof of child support',
            'Proof of maintenance payments',
            'Proof of ownership of bond',
            'Proof of ownership of investment',
            'Proof of ownership of property',
            'Property valuation',
            'Screenshot of Universal Credit entitlement',
            'Share certificate',
            'Water bills',
            'Receipt',
            'Transfers / Gifting'
          ]
        },
        whitelist: ['somerset'],
        type: 'assessment',
        isBetterCareEligible: true
      },
      {
        id: "CAM_FCA",
        name: "Financial Assessment Form (Adult Social Care)",
        formService: "CAM_FCA_FORM",
        formSchema: "CAM_FCA_SCHEMA",
        formConditions: "CAM_FCA_CONDITIONS",
        formMappings: 'CAM_FCA_MAPPINGS',
        defaultValidationMessage: $translate.instant('forms.validation.required'),
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: true,
        has_external_form: false,
        has_details_page: true,
        is_submit_once: true,
        evidence: {
          include_in_PDF: true,
          descriptions: [
            'Birth Certificate',
            'Marriage Certificate',
            'Driving licence',
            'Passport',
            'National identity card',
            'Utility bill',
            'National Insurance card',
            'Bank statement',
            'Bank statement showing your benefit/pension/income payments',
            'P45',
            'P60',
            'Payslip',
            'Salary statement',
            'Works pension statement',
            'Letter from Jobcentre Plus',
            'Letter from HM Revenue and Customs (HMRC)',
            'Letter from the Pension Service',
            'Mortgage statement',
            'Tenancy agreement',
            'Rent book',
            'Statement of charges from landlord',
            'Pension advice slip',
            'Benefit award notification letter',
            'Buildings insurance policy cover sheet',
            'Council Tax annual bill',
            'Council Tax Support statement and breakdown',
            'Court order confirming deputyship',
            'Court order for compulsory payment',
            'Letter from DWP confirming you as appointee',
            'Official Power of Attorney',
            'BF57 confirming you as appointee',
            'Proof of child support',
            'Proof of maintenance payments',
            'Proof of ownership of bond',
            'Proof of ownership of investment',
            'Proof of ownership of property',
            'Property valuation',
            'Screenshot of Universal Credit entitlement',
            'Share certificate',
            'Water bills',
            'Receipt',
            'Transfers / Gifting'
          ]
        },
        whitelist: ['cambridgeshire'],
        type: 'assessment',
        isBetterCareEligible: true,
        blockSubmissionWithoutClientID: true

      },
      {
        id: "PE_FCA",
        name: "Financial Assessment Form (Adult Social Care)",
        formService: "PE_FCA_FORM",
        formSchema: "PE_FCA_SCHEMA",
        formConditions: "PE_FCA_CONDITIONS",
        formMappings: 'PE_FCA_MAPPINGS',
        defaultValidationMessage: $translate.instant('forms.validation.required'),
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: true,
        has_external_form: false,
        has_details_page: true,
        is_submit_once: true,
        evidence: {
          include_in_PDF: true,
          descriptions: [
            'Birth Certificate',
            'Marriage Certificate',
            'Driving licence',
            'Passport',
            'National identity card',
            'Utility bill',
            'National Insurance card',
            'Bank statement',
            'Bank statement showing your benefit/pension/income payments',
            'P45',
            'P60',
            'Payslip',
            'Salary statement',
            'Works pension statement',
            'Letter from Jobcentre Plus',
            'Letter from HM Revenue and Customs (HMRC)',
            'Letter from the Pension Service',
            'Mortgage statement',
            'Tenancy agreement',
            'Rent book',
            'Statement of charges from landlord',
            'Pension advice slip',
            'Benefit award notification letter',
            'Buildings insurance policy cover sheet',
            'Council Tax annual bill',
            'Council Tax Support statement and breakdown',
            'Court order confirming deputyship',
            'Court order for compulsory payment',
            'Letter from DWP confirming you as appointee',
            'Official Power of Attorney',
            'BF57 confirming you as appointee',
            'Proof of child support',
            'Proof of maintenance payments',
            'Proof of ownership of bond',
            'Proof of ownership of investment',
            'Proof of ownership of property',
            'Property valuation',
            'Screenshot of Universal Credit entitlement',
            'Share certificate',
            'Water bills',
            'Receipt',
            'Transfers / Gifting'
          ]
        },
        whitelist: ['peterborough'],
        type: 'assessment',
        isBetterCareEligible: true
      },
      {
        id: "HAM_FCA",
        name: "Financial Assessment Form (Adult Social Care)",
        formService: "HAM_FCA_FORM",
        formSchema: "HAM_FCA_SCHEMA",
        formConditions: "HAM_FCA_CONDITIONS",
        formMappings: 'HAM_FCA_MAPPINGS',
        defaultValidationMessage: $translate.instant('forms.validation.required'),
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: true,
        has_external_form: false,
        has_details_page: true,
        is_submit_once: true,
        evidence: {
          include_in_PDF: true,
          types: [
            { id: "birth_certificate", docTypeCode: "birth-certificate" },
            { id: "marriage_certificate", docTypeCode: "marriage-certificate" },
            { id: "driving_licence", docTypeCode: "driving-licence" },
            { id: "passport", docTypeCode: "passport" },
            { id: "national_identity_card", docTypeCode: "national-identity-card" },
            { id: "utility_bill", docTypeCode: "utility-bill" },
            { id: "national_insurance_card", docTypeCode: "national-insurance-card" },
            { id: "bank_statement", docTypeCode: "bank-statement" },
            { id: "bank_statement_with_income", docTypeCode: "bank-statement-with-income" },
            { id: "p45", docTypeCode: "p45" },
            { id: "p60", docTypeCode: "p60" },
            { id: "payslip", docTypeCode: "payslip" },
            { id: "salary_statement", docTypeCode: "salary-statement" },
            { id: "works_pension", docTypeCode: "works-pension" },
            { id: "letter_from_jobcentre", docTypeCode: "letter-from-jobcentre" },
            { id: "letter_from_hmrc", docTypeCode: "letter-from-hmrc" },
            { id: "letter_from_pension_service", docTypeCode: "letter-from-pension-service" },
            { id: "mortgage_statement", docTypeCode: "mortgage-statement" },
            { id: "tenancy_agreement", docTypeCode: "tenancy-agreement" },
            { id: "rent_book", docTypeCode: "rent-book" },
            { id: "statement_from_landlord", docTypeCode: "statement-from-landlord" },
            { id: "pension_advice_slip", docTypeCode: "pension-advice-slip" },
            { id: "benefit_award_letter", docTypeCode: "benefit-award-letter" },
            { id: "buildings_insurance_policy", docTypeCode: "buildings-insurance-policy" },
            { id: "council_tax_bill", docTypeCode: "council-tax-bill" },
            { id: "council_tax_support_statement", docTypeCode: "council-tax-support-statement" },
            { id: "court_order_deputyship", docTypeCode: "court-order-deputyship" },
            { id: "court_order_compulsory_payments", docTypeCode: "court-order-compulsory-payments" },
            { id: "dwp_letter_confirming_appointeeship", docTypeCode: "dwp-letter-confirming-appointeeship" },
            { id: "official_power_of_attorney", docTypeCode: "official-power-of-attorney" },
            { id: "bf57", docTypeCode: "bf57" },
            { id: "child_support", docTypeCode: "child-support" },
            { id: "maintenance_payments", docTypeCode: "maintenance-payments" },
            { id: "bond_ownership", docTypeCode: "bond-ownership" },
            { id: "investment_ownership", docTypeCode: "investment-ownership" },
            { id: "property_ownership", docTypeCode: "property-ownership" },
            { id: "property_valuation", docTypeCode: "property-valuation" },
            { id: "company_ownership", docTypeCode: "company-ownership" },
            { id: "uc_entitlement", docTypeCode: "uc-entitlement" },
            { id: "company_tax_return", docTypeCode: "company-tax-return" },
            { id: "share_certificate", docTypeCode: "share-certificate" },
            { id: "water_bill", docTypeCode: "water-bill" },
            { id: "receipt", docTypeCode: "receipt" },
            { id: "transfers_and_gifting", docTypeCode: "transfers-and-gifting" },

          ]
        },
        whitelist: ['hampshire'],
        type: 'assessment',
        isBetterCareEligible: true
      },
      {
        id: "DS700",
        displayId: "DS700",
        name: "Carer's Allowance (DS700)",
        isBetterCareEligible: true,
        formService: "DS700_FORM",
        formSchema: "DS700_SCHEMA",
        has_interactive_PDF: false,
        has_flat_PDF: true,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.carersallowance.service.gov.uk/allowance/benefits',
        externalFormActionIntro: 'This application can be made on the GOV.UK website.',
        externalFormActionTitle: 'Apply via GOV.UK',
        entitlements: ["CarersAllowanceClient", "CarersAllowancePartner", "CarersAllowanceInfo"],
        type: 'application'
      }, {
        id: "DS700SP",
        displayId: "DS700SP",
        name: "Carer's Allowance if you get state pension (DS700SP)",
        isBetterCareEligible: true,
        formService: "DS700SP_FORM",
        formSchema: "DS700SP_SCHEMA",
        has_interactive_PDF: false,
        has_flat_PDF: true,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.carersallowance.service.gov.uk/allowance/benefits',
        externalFormActionIntro: 'This application can be made on the GOV.UK website.',
        externalFormActionTitle: 'Apply via GOV.UK',
        entitlements: ["CarersAllowanceClient", "CarersAllowancePartner", "CarersAllowanceInfo"],
        type: 'application'
      }, {
        id: "CARERS_ALLOWANCE_SUPPLEMENT",
        displayId: "CASUP",
        name: "Carer's Allowance Supplement",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.mygov.scot/carers-allowance-supplement',
        externalFormActionTitle: 'Find out if you qualify here',
        entitlements: ["CarersAllowanceClient", "CarersAllowancePartner", "CarersAllowanceInfo"],
        whitelist: ['stirling'],
        type: 'application'
      }, {
        id: "CARERS_CREDIT",
        displayId: "CARERS_CREDIT",
        name: "Carer's Credit",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://www.gov.uk/carers-credit',
        entitlements: ["CarersCredit"],
        type: 'application'
      }, {
        id: "CH2",
        displayId: "CH2",
        name: "Child Benefit (CH2)",
        isBetterCareEligible: true,
        formService: "CH2_FORM",
        formSchema: "CH2_SCHEMA",
        has_interactive_PDF: false,
        has_flat_PDF: true,
        has_web_form: false,
        has_external_form: false,
        has_details_page: true,
        blacklist: ['coventry', 'southampton'],
        entitlements: ["ChildBenefit"],
        type: 'application'
      }, {
        id: "CH2CS",
        displayId: "CH2CS",
        name: "Child Benefit additional Children (CH2CS)",
        formService: "CH2CS_FORM",
        formSchema: "CH2CS_SCHEMA",
        has_interactive_PDF: false,
        has_flat_PDF: true,
        has_web_form: false,
        has_external_form: false,
        has_details_page: true,
        entitlements: ["ChildBenefit"],
        type: 'application'
      }, {
        id: "CDP_SCOTLAND",
        name: "Child Disability Payment",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.mygov.scot/child-disability-payment/how-to-apply',
        externalFormActionTitle: 'Find out how to apply via mygov.scot',
        whitelist: ['stirling'],
        entitlements: ["ChildDisabledNotClaiming"],
        type: 'application'
      }, {
        id: "TC600C",
        displayId: "TC600C",
        name: "Child Tax Credits (TC600)",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.gov.uk/child-tax-credit',
        externalFormActionTitle: 'Find out more about applying for Tax Credits here',
        whitelist: ['enfield'],
        entitlements: ["FinalTaxCreditAward"],
        type: 'application'
      }, {
        id: "TC600C_FULL_SERVICE",
        displayId: "TC600C_FULL_SERVICE",
        name: "Child Tax Credits (Full service UC)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.gov.uk/universal-credit/how-to-claim',
        externalFormActionIntro: 'Universal Credit has replaced Child Tax Credits in full service areas',
        externalFormActionTitle: 'Apply for Universal Credit instead',
        blacklist: ['coventry', 'enfield', 'southampton'],
        entitlements: ["FinalTaxCreditAward"],
        type: 'application'
      }, {
        id: "COLD_WEATHER_PAYMENT",
        displayId: "COLD_WEATHER_PAYMENT",
        name: "Cold Weather Payment",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://www.gov.uk/cold-weather-payment',
        entitlements: ["ColdWeatherPayments"],
        type: 'application'
      }, {
        id: "CSG_LEICESTER",
        displayId: "CSG",
        name: "Community Support Grant",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://www.leicester.gov.uk/your-community/benefits-and-other-support/community-support-grant',
        whitelist: ['leicester'],
        entitlements: ["SocialFund"],
        type: 'application'
      }, {
        id: "CACA_CORNWALL",
        displayId: "CACA_CORNWALL",
        name: "Crisis and Care Awards",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://www.cornwall.gov.uk/benefits-and-support/crisis-and-care-awards/',
        whitelist: ['cornwall'],
        entitlements: ["SocialFund"],
        type: 'application'
      }, {
        id: "CTR_SOUTHGLOS",
        displayId: "CTR_SOUTHGLOS",
        name: "Council Tax Reduction",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://beta.southglos.gov.uk/apply-for-council-tax-reduction/',
        whitelist: ['southglos'],
        entitlements: ["CouncilTaxBenefit"],
        type: 'application'
      }, {
        id: "CTR_WESTNORTHANTS",
        displayId: "CTR_WESTNORTHANTS",
        name: "Council Tax Reduction (including change in circumstances)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.westnorthants.gov.uk/council-tax-reductions-discounts-and-exemptions/apply-council-tax-reduction',
        externalFormActionTitle: 'Apply for Council Tax Reduction',
        whitelist: ['westnorthants'],
        entitlements: ["CouncilTaxBenefit"],
        type: 'application'
      }, {
        id: "CTR_HEREFORDSHIRE",
        displayId: "CTR_HEREFORDSHIRE",
        name: "Council Tax Reduction (CTR)",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://www.herefordshire.gov.uk/council-tax-1/claim-council-tax-reduction',
        whitelist: ['herefordshire'],
        entitlements: ["CouncilTaxBenefit"],
        type: 'application'
      }, {
        id: "CTR_INCOMMUNITIES",
        displayId: "CTR_INCOMMUNITIES",
        name: "Council Tax Reduction (CTR)",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://www.bradford.gov.uk/benefits/applying-for-benefits/housing-benefit-and-council-tax-reduction',
        whitelist: ['incommunities'],
        entitlements: ["CouncilTaxBenefit"],
        type: 'application'
      }, {
        id: "CTR_SOMERSET",
        displayId: "CTR_SOMERSET",
        name: "Council Tax Reduction (CTR)",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://www.somerset.gov.uk/benefits-and-payments/housing-benefit/claim-council-tax-reduction/',
        whitelist: ['somerset'],
        entitlements: ["CouncilTaxBenefit"],
        type: 'application'
      }, {
        id: "CTR_STIRLING",
        displayId: "CTR_STIRLING",
        name: "Council Tax Reduction (CTR)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.stirling.gov.uk/council-tax/discounts-and-exemptions/council-tax-reduction/apply-for-a-council-tax-reduction-and-or-housing-benefit',
        whitelist: ['stirling'],
        entitlements: ["CouncilTaxBenefit"],
        type: 'application'
      }, {
        id: "CTR_SALFORD",
        displayId: "CTR_SALFORD",
        name: "Council Tax Reduction (CTR)",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://espws.necsws.com/ords/pwslive/call_initial_apex_page.nes_selfsrv?service=NEB&la=SALF',
        externalFormActionTitle: 'Apply for HB/CTR online',
        whitelist: ['salford'],
        entitlements: ["CouncilTaxBenefit"],
        type: 'application'
      }, {
        id: "CTS_SPD_HEREFORDSHIRE",
        displayId: "CTS_SPD_HEREFORDSHIRE",
        name: "Council Tax - Single Person Discount",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://www.herefordshire.gov.uk/council-tax-1/council-tax-discounts-exemptions/3',
        whitelist: ['herefordshire'],
        entitlements: ["CouncilTaxBenefit"],
        type: 'application'
      }, {
        id: "CTS_SPD_INCOMMUNITIES",
        displayId: "CTS_SPD_INCOMMUNITIES",
        name: "Council Tax - Single Person Discount",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://www.bradford.gov.uk/council-tax/apply-for-discounts-reductions-and-exemptions/single-person-discount-for-the-bradford-district',
        whitelist: ['incommunities'],
        entitlements: ["CouncilTaxBenefit"],
        type: 'application'
      }, {
        id: "CTS_SPD_STIRLING",
        displayId: "CTS_SPD_STIRLING",
        name: "Council Tax Discounts and Exemptions",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://www.stirling.gov.uk/council-tax/discounts-and-exemptions/',
        whitelist: ['stirling'],
        entitlements: ["CouncilTaxBenefit"],
        type: 'application'
      }, {
        id: "CTS_SPD_SALFORD",
        displayId: "CTS_SPD_SALFORD",
        name: "Council Tax - Single Person Discount",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://salfordcc-self.achieveservice.com/service/Council_Tax___Apply_for__cancel_or_continue_your_single_person_discount',
        whitelist: ['salford'],
        entitlements: ["CouncilTaxBenefit"],
        type: 'application'
      }, {
        id: "COUNCIL_TAX_SALFORD_STUDENT",
        displayId: "COUNCIL_TAX_SALFORD_STUDENT",
        name: "Council Tax - Student Discount or Exemption",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://salfordcc-self.achieveservice.com/service/Council_Tax___Apply_for_student_discount',
        whitelist: ['salford'],
        entitlements: ["CouncilTaxBenefit"],
        type: 'application'
      }, {
        id: "CTS_DERBYSHIRE",
        displayId: "CTS_DERBYSHIRE",
        name: "Council Tax Support (including Change in Circumstances)",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: false,
        has_details_page: true,
        whitelist: ['derbyshire'],
        entitlements: ["CouncilTaxBenefit"],
        type: 'application'
      }, {
        id: "Derbyshire_Discretionary_Fund",
        displayId: "DDF",
        name: "Derbyshire Discretionary Fund (DDF)",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.derbyshire.gov.uk/social-health/adult-care-and-wellbeing/benefits-debt-and-legal-matters/derbyshire-discretionary-fund/derbyshire-discretionary-fund-ddf.aspx',
        externalFormActionIntro: 'You can apply by phoning 01629 533399',
        externalFormActionTitle: 'Find out more about DDF',
        whitelist: ['derbyshire'],
        entitlements: ["SocialFund"],
        type: 'application'
      }, {
        id: "DFG",
        displayId: "DFG",
        name: "Disabled Facilities Grant",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://www.gov.uk/disabled-facilities-grants',
        entitlements: ["DisabledFacilitiesGrant"],
        type: 'application'
      }, {
        id: "DLA1A",
        displayId: "DLA1A",
        name: "Disability Living Allowance for children (DLA1A)",
        isBetterCareEligible: true,
        formService: "DLA1A_FORM",
        formSchema: "DLA1A_SCHEMA",
        has_interactive_PDF: true,
        has_flat_PDF: true,
        has_web_form: false,
        has_external_form: false,
        has_details_page: true,
        blacklist: ['coventry', 'southampton', 'stirling'],
        entitlements: ["ChildDisabledNotClaiming"],
        type: 'application'
      }, {
        id: "DLA1A_SCOTLAND",
        displayId: "DLA1A_SCOTLAND",
        name: "Disability Living Allowance for children (DLA1A)",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.mygov.scot/child-disability-payment/how-to-apply',
        externalFormActionIntro: 'Child Disability Payment has replaced DLA1A in Scotland',
        externalFormActionTitle: 'Apply for Child Disability Payment instead',
        whitelist: ['stirling'],
        entitlements: ["ChildDisabledNotClaiming"],
        type: 'application'
      }, {
        id: "DHP",
        displayId: "DHP",
        name: "Discretionary Housing Payment (DHP)",
        formService: "DHP_FORM",
        formSchema: "DHP_SCHEMA",
        has_interactive_PDF: false,
        has_flat_PDF: true,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://my.kirklees.gov.uk/service/Housing___Apply_for_a_discretionary_housing_payment',
        whitelist: ['kirklees'],
        entitlements: ["DiscretionaryHousingPayment"],
        type: 'application'
      }, {
        id: "DHP_HEREFORDSHIRE",
        displayId: "DHP_HEREFORDSHIRE",
        name: "Discretionary Housing Payment (DHP)",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.herefordshire.gov.uk/downloads/file/1319/discretionary_housing_payments_form',
        externalFormActionTitle: 'Download a DHP application form',
        whitelist: ['herefordshire'],
        entitlements: ["DiscretionaryHousingPayment"],
        type: 'application'
      }, {
        id: "DHP_INCOMMUNITIES",
        displayId: "DHP_INCOMMUNITIES",
        name: "Discretionary Housing Payment (DHP)",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: false,
        has_details_page: true,
        whitelist: ['incommunities'],
        entitlements: ["DiscretionaryHousingPayment"],
        type: 'application'
      }, {
        id: "DHP_CAMBRIDGESHIRE",
        displayId: "DHP_CAMBRIDGESHIRE",
        name: "Discretionary Housing Payment (DHP)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: false,
        has_details_page: true,
        whitelist: ['cambridgeshire'],
        entitlements: ["DiscretionaryHousingPayment"],
        type: 'application'
      }, {
        id: "DHP_DERBYSHIRE",
        displayId: "DHP_DERBYSHIRE",
        name: "Discretionary Housing Payment (DHP)",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: false,
        has_details_page: true,
        whitelist: ['derbyshire'],
        entitlements: ["DiscretionaryHousingPayment"],
        type: 'application'
      }, {
        id: "DHP_HAMPSHIRE",
        displayId: "DHP_HAMPSHIRE",
        name: "Discretionary Housing Payment (DHP)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: false,
        has_details_page: true,
        whitelist: ['hampshire'],
        entitlements: ["DiscretionaryHousingPayment"],
        type: 'application'
      }, {
        id: "DHP_LINCOLNSHIRE",
        displayId: "DHP_LINCOLNSHIRE",
        name: "Discretionary Housing Payment (DHP)",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: false,
        has_details_page: true,
        whitelist: ['lincolnshire'],
        entitlements: ["DiscretionaryHousingPayment"],
        type: 'application'
      }, {
        id: "DHP_SOMERSET",
        displayId: "DHP_SOMERSET",
        name: "Discretionary Housing Payment (DHP)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.somerset.gov.uk/benefits-and-payments/housing-benefit/claim-a-discretionary-award/',
        whitelist: ['somerset'],
        entitlements: ["DiscretionaryHousingPayment"],
        type: 'application'
      }, {
        id: "DHP_STIRLING",
        displayId: "DHP_STIRLING",
        name: "Discretionary Housing Payment (DHP)",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.stirling.gov.uk/council-tax/discretionary-housing-payment/discretionary-housing-payment-application/',
        whitelist: ['stirling'],
        entitlements: ["DiscretionaryHousingPayment"],
        type: 'application'
      }, {
        id: "DHP_SALFORD",
        displayId: "DHP_SALFORD",
        name: "Discretionary Housing Payment (DHP)",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.salford.gov.uk/benefits/discretionary-housing-payments/',
        externalFormActionTitle: 'Apply for a DHP online',
        whitelist: ['salford'],
        entitlements: ["DiscretionaryHousingPayment"],
        type: 'application'
      }, {
        id: "DHP_WAKEFIELD",
        displayId: "DHP_WAKEFIELD",
        name: "Discretionary Housing Payment (DHP)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://myaccount.wakefield.gov.uk/forms/discretionary-housing-payments/',
        externalFormActionTitle: 'Apply for DHP',
        externalFormActionIntro: 'After selecting the link below you\'ll be asked to register or log in to a MyAccount',
        whitelist: ['wakefield'],
        entitlements: ["DiscretionaryHousingPayment"],
        type: 'application'
      }, {
        id: "DHP_WARWICKSHIRE",
        displayId: "DHP_WARWICKSHIRE",
        name: "Discretionary Housing Payment (DHP)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: false,
        has_details_page: true,
        whitelist: ['warwickshire'],
        entitlements: ["DiscretionaryHousingPayment"],
        type: 'application'
      }, {
        id: "DHP_NOTTINGHAMSHIRE",
        displayId: "DHP_NOTTINGHAMSHIRE",
        name: "Discretionary Housing Payment (DHP)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: false,
        has_details_page: true,
        whitelist: ['nottinghamshire'],
        entitlements: ["DiscretionaryHousingPayment"],
        type: 'application'
      }, {
        id: "DHP_DONCASTER",
        displayId: "DHP_DONCASTER",
        name: "Discretionary Housing Payment (DHP)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.doncaster.gov.uk/doitonline/discretionary-housing-payment-application-form',
        externalFormActionTitle: 'Apply for DHP',
        whitelist: ['doncaster'],
        entitlements: ["DiscretionaryHousingPayment"],
        type: 'application'
      }, {
        id: "DHP_HALTON",
        displayId: "DHP_HALTON",
        name: "Discretionary Housing Payment (DHP)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://halton.me/discretionary-housing-payments/',
        externalFormActionTitle: 'Apply for DHP',
        whitelist: ['halton'],
        entitlements: ["DiscretionaryHousingPayment"],
        type: 'application'
      }, {
        id: "DHP_CALDERDALE",
        displayId: "DHP_CALDERDALE",
        name: "Discretionary Housing Payments (DHPs)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://calderdalecouncil.custhelp.com/app/apply-dhp',
        externalFormActionTitle: 'Apply for DHPs',
        whitelist: ['calderdale'],
        entitlements: ["DiscretionaryHousingPayment"],
        type: 'application'
      }, {
        id: "DHP_PETERBOROUGH",
        displayId: "DHP_PETERBOROUGH",
        name: "Discretionary Housing Payment (DHP)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://forms.office.com/r/drbS9VwJgw',
        externalFormActionTitle: 'Apply for DHP',
        whitelist: ['peterborough'],
        entitlements: ["DiscretionaryHousingPayment"],
        type: 'application'
      }, {
        id: "DHP_EALING",
        displayId: "DHP_EALING",
        name: "Discretionary Housing Payments (DHP)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.ealing.gov.uk/info/201103/housing_benefit_and_council_tax_support/304/discretionary_housing_payments_and_discretionary_council_tax_discounts',
        externalFormActionTitle: 'Apply for DHP',
        whitelist: ['ealing'],
        entitlements: ["DiscretionaryHousingPayment"],
        type: 'application'
      }, {
        id: "DHP_WOKINGHAM",
        displayId: "DHP_WOKINGHAM",
        name: "Discretionary Housing Payments (DHP)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.wokingham.gov.uk/benefits/discretionary-housing-payments',
        externalFormActionTitle: 'Download the DHP form',
        whitelist: ['wokingham'],
        entitlements: ["DiscretionaryHousingPayment"],
        type: 'application'
      }, {
        id: "DHP_WOLVERHAMPTON",
        displayId: "DHP_WOLVERHAMPTON",
        name: "Discretionary Housing Payment (DHP)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.wolverhampton.gov.uk/node/17826',
        externalFormActionTitle: 'Apply for DHP',
        whitelist: ['wolverhampton'],
        entitlements: ["DiscretionaryHousingPayment"],
        type: 'application'
      }, {
        id: "DHP_TORBAY",
        displayId: "DHP_TORBAY",
        name: "Discretionary Housing Payments (DHP)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.torbay.gov.uk/benefits/other-help/dhp/',
        externalFormActionTitle: 'Download the DHP form',
        whitelist: ['torbay'],
        entitlements: ["DiscretionaryHousingPayment"],
        type: 'application'
      }, {
        id: "DHP_CORNWALL",
        displayId: "DHP_CORNWALL",
        name: "Discretionary Housing Payments (DHP)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://secure.cornwall.gov.uk/form/auto/benef_dhp',
        externalFormActionTitle: 'Apply for DHP',
        whitelist: ['cornwall'],
        entitlements: ["DiscretionaryHousingPayment"],
        type: 'application'
      }, {
        id: "DHP_WALSALL",
        displayId: "DHP_WALSALL",
        name: "Discretionary Housing Payment (DHP)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://forms.walsall.gov.uk/Discretionary-Housing-Payment-form',
        externalFormActionTitle: 'Apply for DHP',
        whitelist: ['walsall'],
        entitlements: ["DiscretionaryHousingPayment"],
        type: 'application'
      }, {
        id: "DHP_SOUTHGLOS",
        displayId: "DHP_SOUTHGLOS",
        name: "Discretionary Housing Payment (DHP)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://espws.necsws.com/ords/pwslive/pam_user.std_oauth2.go?provider=406&app=CAD&context=SGLCS:ENG',
        whitelist: ['southglos'],
        entitlements: ["DiscretionaryHousingPayment"],
        type: 'application'
      }, {
        id: "DHP_WESTNORTHANTS",
        displayId: "DHP_WESTNORTHANTS",
        name: "Discretionary Housing Payment (DHP)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.westnorthants.gov.uk/claiming-benefits/apply-discretionary-housing-payment',
        externalFormActionTitle: 'Apply for DHP',
        whitelist: ['westnorthants'],
        entitlements: ["DiscretionaryHousingPayment"],
        type: 'application'
      }, {
        id: "DHP_LEICESTER",
        displayId: "DHP_LEICESTER",
        name: "Discretionary Payments",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://my.leicester.gov.uk/en/AchieveForms/?form_uri=sandbox-publish://AF-Process-4690a4cf-5865-4dc9-a569-5abc56127cd4/AF-Stage-066adfea-7b2d-430e-81ae-a98bd5468f2e/definition.json&redirectlink=%2F&cancelRedirectLink=%2F&category=AF-Category-c162276a-8319-4a1d-a268-c104cc157d9b',
        externalFormActionTitle: 'Apply for DHP and/or CTDR',
        externalFormActionIntro: 'After selecting the link below you\'ll be asked to register or log in to an account',
        whitelist: ['leicester'],
        entitlements: ["DiscretionaryHousingPayment"],
        type: 'application'
      }, {
        id: "DHP_LEWISHAM",
        displayId: "DHP_LEWISHAM",
        name: "Discretionary Housing Payments",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://lewisham.gov.uk/myservices/benefits/housing-benefit/discretionary-housing-payments',
        whitelist: ['lewisham'],
        entitlements: ["DiscretionaryHousingPayment"],
        type: 'application'
      }, {
        id: "DHP_BLACKPOOL",
        displayId: "DHP_BLACKPOOL",
        name: "Discretionary Housing Payment",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://www.blackpool.gov.uk/Residents/Benefits/Extra-help-with-housing-and-council-tax-costs-.aspx',
        whitelist: ['blackpool'],
        entitlements: ["DiscretionaryHousingPayment"],
        type: 'application'
      }, {
        id: "DSS_BLACKPOOL",
        displayId: "DSS_BLACKPOOL",
        name: "Discretionary Support Scheme",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://www.blackpool.gov.uk/Residents/Advice-and-support/Discretionary-support-scheme.aspx',
        whitelist: ['blackpool'],
        entitlements: ["SocialFund"],
        type: 'application'
      }, {
        id: "DSS_HALTON",
        displayId: "DSS_HALTON",
        name: "Discretionary Support Scheme",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://www3.halton.gov.uk/Pages/CouncilandBenefits/Discretionary-Support.aspx',
        whitelist: ['halton'],
        entitlements: ["SocialFund"],
        type: 'application'
      }, {
        id: "LWP_HEREFORDSHIRE",
        displayId: "LWP_HEREFORDSHIRE",
        name: "Emergency Welfare Support",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.herefordshire.gov.uk/family-support/support-emergency-welfare-support/3',
        externalFormActionTitle: 'Apply online using the referral form',
        whitelist: ['herefordshire'],
        entitlements: ["SocialFund"],
        type: 'application'
      }, {
        id: "ESA1",
        displayId: "ESA1",
        name: "Employment and Support Allowance Claim (ESA1)",
        formService: "ESA1_FORM",
        formSchema: "ESA1_SCHEMA",
        formMappings: 'ESA1_MAPPINGS',
        has_interactive_PDF: true,
        has_flat_PDF: true,
        has_web_form: true,
        has_external_form: false,
        has_details_page: true,
        server_key_separator: '-',
        whitelist: ['enfield'],
        entitlements: ["IncomeRelatedESA"],
        type: 'application'
      }, {
        id: "ESA_NEW_STYLE",
        displayId: "ESA_NEW_STYLE",
        name: "Employment and Support Allowance - New Style (ESA)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.apply-new-style-employment-support-allowance.service.gov.uk/who-is-applying',
        externalFormActionTitle: 'Apply for New Style ESA online',
        blacklist: ['coventry', 'enfield', 'southampton'],
        entitlements: ["IncomeRelatedESA"],
        type: 'application'
      }, {
        id: "ESA50",
        displayId: "ESA50",
        name: "ESA - Capability for Work questionnaire (ESA50)",
        formService: "ESA50_FORM",
        formSchema: "ESA50_SCHEMA",
        formMappings: 'ESA50_MAPPINGS',
        isBetterCareEligible: true,
        has_interactive_PDF: true,
        has_flat_PDF: false,
        has_web_form: true,
        has_external_form: false,
        has_details_page: true,
        server_key_separator: '.',
        blacklist: ['coventry', 'southampton'],
        entitlements: ["IncomeRelatedESA"],
        type: 'application'
      }, {
        id: "HS",
        displayId: "HS",
        name: "Free school meals and Healthy Start food vouchers",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.gov.uk/apply-free-school-meals',
        externalFormActionTitle: 'Apply for free school meals via GOV.UK',
        whitelist: ['kirklees', 'enfield'],
        entitlements: ["FreeSchoolMeals"],
        type: 'application'
      }, {
        id: "FSM_LEWISHAM",
        displayId: "FSM_LEWISHAM",
        name: "Free School Meals",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://lewisham.gov.uk/myservices/education/student-pupil-support/free-school-meals-and-extra-support-for-your-child',
        whitelist: ['lewisham'],
        entitlements: ["FreeSchoolMeals"],
        type: 'application'
      }, {
        id: "TV_LICENCE",
        displayId: "TV_LICENCE",
        name: "Free TV licence",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://www.gov.uk/free-discount-tv-licence',
        externalFormActionTitle: 'Find out more',
        entitlements: ["FreeTVLicence"],
        type: 'application'
      }, {
        id: "FG",
        displayId: "FG",
        name: "Funeral Expenses Payment",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.gov.uk/funeral-payments/how-to-claim',
        externalFormActionTitle: 'Find out how to claim via GOV.UK',
        entitlements: ["FuneralGrant"],
        blacklist: ['coventry', 'southampton', 'stirling'],
        type: 'application'
      }, {
        id: "FG_SCOTLAND",
        displayId: "FG_SCOTLAND",
        name: "Funeral Expenses Payment",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.mygov.scot/funeral-support-payment/how-to-apply',
        externalFormActionIntro: 'Funeral Support Payment has replaced Funeral Expenses Payment in Scotland',
        externalFormActionTitle: 'Apply for Funeral Support Payment instead',
        whitelist: ['stirling'],
        entitlements: ["FuneralGrant"],
        type: 'application'
      }, {
        id: "FSP_SCOTLAND",
        name: "Funeral Support Payment",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.mygov.scot/funeral-support-payment/how-to-apply',
        externalFormActionTitle: 'Find out how to apply via mygov.scot',
        whitelist: ['stirling'],
        entitlements: ["FuneralGrant"],
        type: 'application'
      }, {
        id: "GA",
        displayId: "GA",
        name: "Guardian's Allowance",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.gov.uk/government/publications/guardians-allowance-claim-form-bg1',
        entitlements: ["GuardiansAllowanceInfo"],
        blacklist: ['coventry', 'southampton'],
        type: 'application'
      }, {
        id: "HEALTHY_START_LEWISHAM",
        displayId: "HEALTHY_START_LEWISHAM",
        name: "Healthy Start and Vitamin D",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://applyforhealthystart.nhsbsa.nhs.uk/apply-for-healthy-start',
        externalFormActionTitle: 'Get help to buy food and milk (Healthy Start)',
        whitelist: ['lewisham'],
        type: 'application'
      }, {
        id: "HELP_PREGNANT",
        displayId: "HELP_PREGNANT",
        name: "Help for Pregnant Women",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://www.moneyhelper.org.uk/en/benefits/benefits-if-you-have-children/free-prescriptions-and-nhs-dental-care-in-pregnancy',
        entitlements: ["Pregnant"],
        type: 'application'
      }, {
        id: "HSF",
        displayId: "HSF",
        name: "Household Support Fund",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://www.gov.uk/cost-living-help-local-council',
        blacklist: ['blackpool', 'calderdale', 'cambridgeshire', 'cornwall', 'derbyshire', 'doncaster', 'ealing', 'halton', 'herefordshire', 'incommunities', 'kirklees', 'leicester', 'lewisham', 'peterborough', 'salford', 'somerset', 'southglos', 'stirling', 'torbay', 'wakefield', 'walsall', 'warwickshire', 'westnorthants', 'wokingham', 'wolverhampton'],
        entitlements: ["SocialFund"],
        type: 'application'
      }, {
        id: "HSF_SALFORD",
        displayId: "HSF_SALFORD",
        name: "Household Support Fund",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://www.salford.gov.uk/advice-and-support/household-support-fund/',
        whitelist: ['salford'],
        entitlements: ["SocialFund"],
        type: 'application'
      }, {
        id: "HSF_LEWISHAM",
        displayId: "HSF_LEWISHAM",
        name: "Household Support Fund",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://lewisham.gov.uk/myservices/benefits/the-household-fund',
        whitelist: ['lewisham'],
        entitlements: ["SocialFund"],
        type: 'application'
      }, {
        id: "HSF_WOLVERHAMPTON",
        displayId: "HSF_WOLVERHAMPTON",
        name: "Household Support Fund",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://www.wolverhampton.gov.uk/cost-of-living-support/household-support-fund',
        whitelist: ['wolverhampton'],
        entitlements: ["SocialFund"],
        type: 'application'
      }, {
        id: "HEALTHY_START",
        displayId: "HEALTHY_START",
        name: "Healthy Start Vouchers",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://families.leicester.gov.uk/family-information/family-health/healthy-start-vouchers/',
        whitelist: ['leicester'],
        type: 'application'
      }, {
        id: "ENERGY_EFFICIENCY",
        displayId: "ENERGY_EFFICIENCY",
        name: "Help with fuel costs",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://www.leicester.gov.uk/your-community/benefits-and-other-support/help-with-fuel-costs/',
        whitelist: ['leicester'],
        type: 'application'
      }, {
        id: "HSF_PETERBOROUGH",
        displayId: "HSF_PETERBOROUGH",
        name: "Household Support Fund",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://www.peterborough.gov.uk/council/benefits/household-support-fund',
        whitelist: ['peterborough'],
        entitlements: ["SocialFund"],
        type: 'application'
      }, {
        id: "HSF_WESTNORTHANTS",
        displayId: "HSF_WESTNORTHANTS",
        name: "Household Support Fund",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://www.westnorthants.gov.uk/claiming-benefits/household-support-fund',
        whitelist: ['westnorthants'],
        entitlements: ["SocialFund"],
        type: 'application'
      }, {
        id: "HB_CTR_FSM",
        displayId: "HB/CTR",
        name: "Housing Benefit and Council Tax Reduction",
        formService: "HB_CTR_FSM_FORM",
        formSchema: "HB_CTR_FSM_SCHEMA",
        formConditions: "HB_CTR_FSM_CONDITIONS",
        formMappings: 'HB_CTR_FSM_MAPPINGS',
        defaultValidationMessage: $translate.instant('forms.validation.required'),
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: true,
        has_external_form: false,
        has_details_page: true,
        whitelist: ['kirklees', 'demo'],
        entitlements: ["CouncilTaxBenefit", "HousingBenefit"],
        is_submit_once: true,
        evidence: {
          customFields: {
            anite_filesystem: 'BE',
            uprn_model_field: 'applicant_uprn'
          },
          types: [
            {id: 'id', docTypeCode: 'ID'},
            {id: 'nino', docTypeCode: 'ID'},
            {id: 'rent', docTypeCode: 'RENT'},
            {id: 'income', docTypeCode: 'WAGE'},
            {id: 'pension', docTypeCode: 'OCCPEN'},
            {id: 'award', docTypeCode: 'BENLTR'},
            {id: 'childcare', docTypeCode: 'CCC'},
            {id: 'capital', docTypeCode: 'BANK'},
            {id: 'other', docTypeCode: 'FI'}
          ]
        },
        type: 'application'
      }, {
        id: "FCR",
        displayId: "FCR",
        name: "Full Claim Review",
        formService: "FCR_FORM",
        formSchema: "FCR_SCHEMA",
        formConditions: "FCR_CONDITIONS",
        formMappings: 'FCR_MAPPINGS',
        defaultValidationMessage: $translate.instant('forms.validation.required'),
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: true,
        has_external_form: false,
        has_details_page: true,
        whitelist: ['kirklees', 'demo'],
        entitlements: ["CouncilTaxBenefit", "HousingBenefit"],
        is_submit_once: true,
        evidence: {
          customFields: {
            claim_ref_field: 'claim_reference',
            uprn_model_field: 'applicant_uprn',
            anite_filesystem: 'BE'
          },
          types: [
            {id: 'id', docTypeCode: 'ID'},
            {id: 'nino', docTypeCode: 'ID'},
            {id: 'rent', docTypeCode: 'RENT'},
            {id: 'income', docTypeCode: 'WAGE'},
            {id: 'pension', docTypeCode: 'OCCPEN'},
            {id: 'award', docTypeCode: 'BENLTR'},
            {id: 'childcare', docTypeCode: 'CCC'},
            {id: 'capital', docTypeCode: 'BANK'},
            {id: 'other', docTypeCode: 'FI'}
          ]
        },
        type: 'application',
        review: {
          animations: 'hide',
          cloning: 'hide'
        },

      }, {
        id: "CTR_HB_HEREFORDSHIRE",
        displayId: "CTR_HB_HEREFORDSHIRE",
        name: "Housing Benefit and Council Tax Reduction",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.herefordshire.gov.uk/eclaim',
        whitelist: ['herefordshire'],
        entitlements: ["CouncilTaxBenefit", "HousingBenefit"],
        type: 'application'
      }, {
        id: "CTR_HB_INCOMMUNITIES",
        displayId: "CTR_HB_INCOMMUNITIES",
        name: "Housing Benefit and Council Tax Reduction (inc Change in Circumstances)",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: false,
        has_details_page: true,
        whitelist: ['incommunities'],
        entitlements: ["CouncilTaxBenefit", "HousingBenefit"],
        type: 'application'
      }, {
        id: "CTS_HB_CAMBRIDGESHIRE",
        displayId: "CTS_HB_CAMBRIDGESHIRE",
        name: "Housing Benefit and Council Tax Support (inc Change in Circumstances)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: false,
        has_details_page: true,
        whitelist: ['cambridgeshire'],
        entitlements: ["CouncilTaxBenefit", "HousingBenefit"],
        type: 'application'
      }, {
        id: "HB_DERBYSHIRE",
        displayId: "HB_DERBYSHIRE",
        name: "Housing Benefit (including Change in Circumstances)",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: false,
        has_details_page: true,
        whitelist: ['derbyshire'],
        entitlements: ["HousingBenefit"],
        type: 'application'
      }, {
        id: "CTS_HB_HAMPSHIRE",
        displayId: "CTS_HB_HAMPSHIRE",
        name: "Housing Benefit and Council Tax Support (inc Change in Circumstances)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: false,
        has_details_page: true,
        whitelist: ['hampshire'],
        entitlements: ["CouncilTaxBenefit", "HousingBenefit"],
        type: 'application'
      }, {
        id: "CTS_HB_NOTTINGHAMSHIRE",
        displayId: "CTS_HB_NOTTINGHAMSHIRE",
        name: "Housing Benefit and Council Tax Support (inc Change in Circumstances)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: false,
        has_details_page: true,
        whitelist: ['nottinghamshire'],
        entitlements: ["CouncilTaxBenefit", "HousingBenefit"],
        type: 'application'
      }, {
        id: "CTS_HB_PETERBOROUGH",
        displayId: "CTS_HB_PETERBOROUGH",
        name: "Housing Benefit and Council Tax Support (inc Change in Circumstances)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://lwa.financialassessments.co.uk/LocalWelfareAssistance/Home/Redirector/Index/?id=39f89043-66ea-48a6-80c9-ae64f5d39efd&mod=OA&casetype=PET&formname=ADECLAIM',
        whitelist: ['peterborough'],
        entitlements: ["CouncilTaxBenefit", "HousingBenefit"],
        type: 'application'
      }, {
        id: "CTS_HB_SOMERSET",
        displayId: "CTS_HB_SOMERSET",
        name: "Housing Benefit and Council Tax Reduction (inc Change in Circumstances)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.somerset.gov.uk/benefits-and-payments/housing-benefit/claim-housing-benefit',
        whitelist: ['somerset'],
        entitlements: ["CouncilTaxBenefit", "HousingBenefit"],
        type: 'application'
      }, {
        id: "CTS_HB_WARWICKSHIRE",
        displayId: "CTS_HB_WARWICKSHIRE",
        name: "Housing Benefit and Council Tax Support (inc Change in Circumstances)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: false,
        has_details_page: true,
        whitelist: ['warwickshire'],
        entitlements: ["CouncilTaxBenefit", "HousingBenefit"],
        type: 'application'
      }, {
        id: "CTS_HB_WOLVERHAMPTON",
        displayId: "CTS_HB_WOLVERHAMPTON",
        name: "Housing Benefit and Council Tax Support (inc Change in Circumstances)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: false,
        has_details_page: true,
        whitelist: ['wolverhampton'],
        entitlements: ["CouncilTaxBenefit", "HousingBenefit"],
        type: 'application'
      }, {
        id: "CTS_HB_LEICESTER",
        displayId: "CTS_HB_LEICESTER",
        name: "Housing Benefit and Council Tax Support (inc Change of Circumstances)",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://my.leicester.gov.uk/AchieveForms/?mode=fill&consentMessage=yes&form_uri=sandbox-publish://AF-Process-7b718e62-a2a5-4c9c-8353-c2affa716df2/AF-Stage-e08f28ff-336f-4911-ab41-8cc5c21651ec/definition.json&process=1&process_uri=sandbox-processes://AF-Process-7b718e62-a2a5-4c9c-8353-c2affa716df2&process_id=AF-Process-7b718e62-a2a5-4c9c-8353-c2affa716df2',
        externalFormActionTitle: 'Apply for HB/CTS online',
        whitelist: ['leicester'],
        entitlements: ["CouncilTaxBenefit", "HousingBenefit"],
        type: 'application'
      }, {
        id: "CTS_HB_LINCOLNSHIRE",
        displayId: "CTS_HB_LINCOLNSHIRE",
        name: "Housing Benefit and Council Tax Support (inc Change in Circumstances)",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: false,
        has_details_page: true,
        whitelist: ['lincolnshire'],
        entitlements: ["CouncilTaxBenefit", "HousingBenefit"],
        type: 'application'
      }, {
        id: "CTR_HB_COUNCIL_STIRLING",
        displayId: "CTR_HB_COUNCIL_STIRLING",
        name: "Housing Benefit and Council Tax Reduction - Council Tenants",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.stirling.gov.uk/council-tax/discounts-and-exemptions/council-tax-reduction/apply-for-a-council-tax-reduction-and-or-housing-benefit/',
        whitelist: ['stirling'],
        entitlements: ["CouncilTaxBenefit", "HousingBenefit"],
        type: 'application'
      }, {
        id: "CTR_HB_PRIVATE_STIRLING",
        displayId: "CTR_HB_PRIVATE_STIRLING",
        name: "Housing Benefit and Council Tax Reduction - Private Tenants",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.stirling.gov.uk/council-tax/discounts-and-exemptions/council-tax-reduction/apply-for-a-council-tax-reduction-and-or-housing-benefit/',
        whitelist: ['stirling'],
        entitlements: ["CouncilTaxBenefit", "HousingBenefit"],
        type: 'application'
      }, {
        id: "CTR_HB_SALFORD",
        displayId: "CTR_HB_SALFORD",
        name: "Housing Benefit and Council Tax Reduction (inc Change of Circumstances)",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://espws.necsws.com/ords/pwslive/call_initial_apex_page.nes_selfsrv?service=NEB&la=SALF',
        externalFormActionTitle: 'Apply for HB/CTR online',
        whitelist: ['salford'],
        entitlements: ["CouncilTaxBenefit", "HousingBenefit"],
        type: 'application'
      }, {
        id: "CTR_HB_WAKEFIELD",
        displayId: "CTR_HB_WAKEFIELD",
        name: "Housing Benefit and Council Tax Support (inc Change of Circumstances)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://wakefield-hbnewclaim.egovhub.net/HBNewClaim/launch',
        whitelist: ['wakefield'],
        entitlements: ["CouncilTaxBenefit", "HousingBenefit"],
        type: 'application'
      }, {
        id: "CTR_HB_LEWISHAM",
        displayId: "CTR_HB_LEWISHAM",
        name: "Housing Benefit and / or Council Tax Reduction (inc Change of Circumstances)",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://lewisham.gov.uk/myservices/benefits/housing-benefit/apply-for-housing-benefit',
        whitelist: ['lewisham'],
        entitlements: ["CouncilTaxBenefit", "HousingBenefit"],
        type: 'application'
      }, {
        id: "CTR_HB_DONCASTER",
        displayId: "CTR_HB_DONCASTER",
        name: "Housing Benefit and Council Tax Reduction (inc Change of Circumstances)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.doncaster.gov.uk/doitonline/apply-for-housing-benefit-and-council-tax-support',
        whitelist: ['doncaster'],
        entitlements: ["CouncilTaxBenefit", "HousingBenefit"],
        type: 'application'
      }, {
        id: "CTR_HB_HALTON",
        displayId: "CTR_HB_HALTON",
        name: "Housing Benefit and Council Tax Reduction (inc Change in Circumstances)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://espws.necsws.com/ords/pwslive/call_initial_apex_page.nes_selfsrv?service=NEB&la=halt',
        whitelist: ['halton'],
        entitlements: ["CouncilTaxBenefit", "HousingBenefit"],
        type: 'application'
      }, {
        id: "CTR_HB_CALDERDALE",
        displayId: "CTR_HB_CALDERDALE",
        name: "Housing Benefit and Council Tax Reduction (inc Change in Circumstances)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://new.calderdale.gov.uk/benefits/apply',
        whitelist: ['calderdale'],
        entitlements: ["CouncilTaxBenefit", "HousingBenefit"],
        type: 'application'
      }, {
        id: "CTR_HB_EALING",
        displayId: "CTR_HB_EALING",
        name: "Housing Benefit and Council Tax Reduction (inc Change in Circumstances)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.ealing.gov.uk/info/201103/housing_benefit_and_council_tax_support/2021/apply_for_housing_benefit_and_council_tax_support',
        whitelist: ['ealing'],
        entitlements: ["CouncilTaxBenefit", "HousingBenefit"],
        type: 'application'
      }, {
        id: "CTR_HB_WOKINGHAM",
        displayId: "CTR_HB_WOKINGHAM",
        name: "Housing Benefit and Council Tax Reduction (inc Change in Circumstances)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.wokingham.gov.uk/council-tax/council-tax-reduction/apply-housing-benefit-andor-local-council-tax-reduction',
        whitelist: ['wokingham'],
        entitlements: ["CouncilTaxBenefit", "HousingBenefit"],
        type: 'application'
      }, {
        id: "CTR_HB_TORBAY",
        displayId: "CTR_HB_TORBAY",
        name: "Housing Benefit and Council Tax Support (including change in circumstances)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.torbay.gov.uk/W2URB/lg/BenefitApplication/Welcome.page?org.apache.shale.dialog.DIALOG_NAME=msBenefitApplication',
        whitelist: ['torbay'],
        entitlements: ["CouncilTaxBenefit", "HousingBenefit"],
        type: 'application'
      }, {
        id: "CTR_HB_CORNWALL",
        displayId: "CTR_HB_CORNWALL",
        name: "Housing Benefit and Council Tax Reduction (inc Change in Circumstances)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.cornwall.gov.uk/benefits-and-support/housing-benefit/apply-for-housing-benefit/',
        whitelist: ['cornwall'],
        entitlements: ["CouncilTaxBenefit", "HousingBenefit"],
        type: 'application'
      }, {
        id: "CTR_HB_WALSALL",
        displayId: "CTR_HB_WALSALL",
        name: "Housing Benefit and Council Tax Reduction (inc Change of Circumstances)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://espws.necsws.com/ords/pwslive/call_initial_apex_page.nes_selfsrv?service=NEB&la=WAL&language=ENG',
        whitelist: ['walsall'],
        entitlements: ["CouncilTaxBenefit", "HousingBenefit"],
        type: 'application'
      }, {
        id: "CTR_HB_BLACKPOOL",
        displayId: "CTR_HB_BLACKPOOL",
        name: "Housing Benefit and Council Tax Reduction (inc Change in Circumstance)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.blackpool.gov.uk/Residents/Benefits/Make-a-claim-for-housing-benefit-and-council-tax-reduction.aspx',
        externalFormActionTitle: 'Find out more about HB/CTR',
        whitelist: ['blackpool'],
        entitlements: ["CouncilTaxBenefit", "HousingBenefit"],
        type: 'application'
      }, {
        id: "HB_SOUTHGLOS",
        displayId: "HB_SOUTHGLOS",
        name: "Housing Benefit (including change of circumstances)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://beta.southglos.gov.uk/claim-housing-benefit/#how-to-claim',
        whitelist: ['southglos'],
        entitlements: ["HousingBenefit"],
        type: 'application'
      }, {
        id: "HB_WESTNORTHANTS",
        displayId: "HB_WESTNORTHANTS",
        name: "Housing Benefit (including change in circumstances)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.westnorthants.gov.uk/claiming-benefits/apply-universal-credit-and-housing-benefit',
        externalFormActionTitle: 'Apply for Housing Benefit',
        whitelist: ['westnorthants'],
        entitlements: ["HousingBenefit"],
        type: 'application'
      }, {
        id: "CHANGE_OF_CIRCUMSTANCES_HEREFORDSHIRE",
        displayId: "CHANGE_OF_CIRCUMSTANCES_HEREFORDSHIRE",
        name: "Housing Benefit and Council Tax Reduction - Change of Circumstances",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.herefordshire.gov.uk/echange',
        externalFormActionTitle: 'Report your change of circumstances',
        whitelist: ['herefordshire'],
        type: 'application'
      }, {
        id: "CHANGE_OF_CIRCUMSTANCES_STIRLING",
        displayId: "CHANGE_OF_CIRCUMSTANCES_STIRLING",
        name: "Housing Benefit and Council Tax Reduction - Change of Circumstances",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.stirling.gov.uk/council-tax/notify-us-of-a-change-in-your-circumstances/',
        externalFormActionTitle: 'Report your change of circumstances',
        whitelist: ['stirling'],
        entitlements: ["CouncilTaxBenefit", "HousingBenefit"],
        type: 'application'
      }, {
        id: "A1",
        displayId: "A1",
        name: "Income Support (A1)",
        formService: "A1_FORM",
        formSchema: "A1_SCHEMA",
        has_interactive_PDF: true,
        has_flat_PDF: true,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.gov.uk/income-support',
        externalFormActionIntro: 'The quickest way to apply for Income Support is by phone',
        externalFormActionTitle: 'Find out how to claim Income Support here',
        whitelist: ['enfield'],
        entitlements: ["IncomeSupport"],
        type: 'application'
      }, {
        id: "A1_FULL_SERVICE",
        displayId: "A1_FULL_SERVICE",
        name: "Income Support (Full service UC)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.gov.uk/universal-credit/how-to-claim',
        externalFormActionIntro: 'Universal Credit has replaced Income Support in full service areas',
        externalFormActionTitle: 'Apply for Universal Credit instead',
        blacklist: ['coventry', 'enfield', 'southampton'],
        entitlements: ["IncomeSupport"],
        type: 'application'
      }, {
        id: "IIDB",
        displayId: "IIDB",
        name: "Industrial Injuries Disablement Benefit (IIDB)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.gov.uk/industrial-injuries-disablement-benefit/how-to-claim',
        externalFormActionTitle: 'Find out how to apply via GOV.UK',
        blacklist: ['coventry', 'southampton'],
        type: 'application'
      }, {
        id: "JSA",
        displayId: "JSA",
        name: "Jobseeker's Allowance (JSA)",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.apply-for-new-style-jsa.dwp.gov.uk/',
        whitelist: ['enfield'],
        entitlements: ["JSA"],
        type: 'application'
      }, {
        id: "JSA_NEW_STYLE",
        displayId: "JSA_NEW_STYLE",
        name: "Jobseeker's Allowance - New Style (JSA)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.apply-for-new-style-jsa.dwp.gov.uk/',
        externalFormActionTitle: 'Apply for New Style JSA online',
        blacklist: ['coventry', 'enfield', 'southampton'],
        entitlements: ["JSA"],
        type: 'application'
      }, {
        id: "LAS_DONCASTER",
        displayId: "LAS",
        name: "Local Assistance Scheme",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://www.doncaster.gov.uk/services/council-tax-benefits/local-assistance-scheme',
        whitelist: ['doncaster'],
        entitlements: ["SocialFund"],
        type: 'application'
      }, {
        id: "LAS_SOMERSET",
        displayId: "LAS_SOMERSET",
        name: "Local Assistance Scheme",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://www.somerset.gov.uk/care-and-support-for-adults/help-with-basic-household-essentials/',
        whitelist: ['somerset'],
        entitlements: ["SocialFund"],
        type: 'application'
      }, {
        id: "LWA_CALDERDALE",
        displayId: "LWA_CALDERDALE",
        name: "Local Welfare Assistance",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://www.calderdale.gov.uk/v2/residents/council-tax-and-benefits/benefits/local-welfare-assistance',
        whitelist: ['calderdale'],
        entitlements: ["SocialFund"],
        type: 'application'
      }, {
        id: "LWA_EALING",
        displayId: "LWA_EALING",
        name: "Local Welfare Assistance",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://www.ealing.gov.uk/info/201305/other_financial_support/1532/local_welfare_assistance',
        whitelist: ['ealing'],
        entitlements: ["SocialFund"],
        type: 'application'
      }, {
        id: "LWS",
        displayId: "LWS",
        name: "Local Welfare Scheme",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://www.kirklees.gov.uk/beta/benefits/local-welfare-provision.aspx',
        whitelist: ['kirklees'],
        entitlements: ["SocialFund"],
        type: 'application'
      }, {
        id: "LWS_WARWICKSHIRE",
        displayId: "LWS_WARWICKSHIRE",
        name: "Local Welfare Scheme",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://www.warwickshire.gov.uk/localwelfarescheme',
        whitelist: ['warwickshire'],
        entitlements: ["SocialFund"],
        type: 'application'
      }, {
        id: "LWP_WAKEFIELD",
        displayId: "LWP",
        name: "Local Welfare Provision",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://www.wakefield.gov.uk/housing/information-for-tenants/need-help-with-your-rent/local-welfare-provision',
        whitelist: ['wakefield'],
        entitlements: ["SocialFund"],
        type: 'application'
      }, {
        id: "LWP_WOKINGHAM",
        displayId: "LWP_WOKINGHAM",
        name: "Local Welfare Provision",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://www.wokingham.gov.uk/benefits/local-welfare-provision',
        whitelist: ['wokingham'],
        entitlements: ["SocialFund"],
        type: 'application'
      }, {
        id: "LOWER_COST_BROADBAND",
        displayId: "LOWER_COST_BROADBAND",
        name: "Lower Cost Broadband",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://www.ofcom.org.uk/phones-and-broadband/saving-money/social-tariffs',
        entitlements: ["CheapBroadband"],
        type: 'application'
      }, {
        id: "MA",
        displayId: "MA",
        name: "Maternity Allowance",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.gov.uk/maternity-allowance',
        externalFormActionTitle: 'Find out how to apply via GOV.UK',
        blacklist: ['coventry', 'southampton'],
        entitlements: ["MaternityAllowanceInfo"],
        type: 'application'
      }, {
        id: "MIR",
        displayId: "MIR",
        name: "Mortgage Interest Run On",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.gov.uk/mortgage-interest-run-on',
        externalFormActionTitle: 'Find out more about claiming MIR here',
        blacklist: ['coventry', 'southampton'],
        entitlements: ["MortgageInterestRunOnInfo"],
        type: 'application'
      }, {
        id: "NHS_HEALTH_COSTS",
        displayId: "NHS_HEALTH_COSTS",
        name: "Help with NHS Health Costs",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://www.gov.uk/help-nhs-costs',
        entitlements: ["FreePrescriptionsAndEyeTests"],
        type: 'application'
      }, {
        id: "HC1",
        displayId: "HC1",
        name: "NHS Low Income Scheme (HC1)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.nhsbsa.nhs.uk/nhs-low-income-scheme',
        externalFormActionTitle: 'Find out more about how to apply',
        blacklist: ['coventry', 'southampton'],
        entitlements: ["NHSLowIncomeSchemeOver60s", "NHSLowIncomeSchemeUnder60s"],
        type: 'application'
      }, {
        id: "CT_DISCOUNTS_LEWISHAM",
        displayId: "CT_DISCOUNTS_LEWISHAM",
        name: "Other Council Tax Discounts and Exemptions",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://lewisham.gov.uk/myservices/counciltax/council-tax-reductions-and-exemptions',
        whitelist: ['lewisham'],
        entitlements: ["CouncilTaxBenefit"],
        type: 'application'
      }, {
        id: "PC",
        displayId: "PC",
        name: "Pension Credit",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.gov.uk/pension-credit/how-to-claim',
        externalFormActionTitle: 'Find out how to apply via GOV.UK',
        entitlements: ["PensionCredit", "GuaranteeCredit", "SavingsCredit"],
        type: 'application'
      }, {
        id: "PIP",
        displayId: "PIP",
        name: "Personal Independence Payment (PIP)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.gov.uk/pip',
        externalFormActionIntro: 'Start your claim by calling the DWP on 0800 917 2222',
        externalFormActionTitle: 'Read more about PIP here',
        entitlements: ["PersonalIndependencePaymentInfo"],
        blacklist: ['stirling'],
        type: 'application'
      }, {
        id: "PIP_SCOTLAND",
        displayId: "PIP_SCOTLAND",
        name: "Personal Independence Payment (PIP)",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.mygov.scot/adult-disability-payment/how-to-apply',
        externalFormActionIntro: 'Adult Disability Payment has replaced PIP in Scotland',
        externalFormActionTitle: 'Apply for Adult Disability Payment instead',
        whitelist: ['stirling'],
        entitlements: ["PersonalIndependencePaymentInfo"],
        type: 'application'
      }, {
        id: "SALFORD_ASSIST",
        displayId: "SALFORD_ASSIST",
        name: "Salford Assist",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.salford.gov.uk/advice-and-support/salford-assist/how-do-you-apply-for-help-from-salford-assist/',
        externalFormActionTitle: 'Find out how to apply',
        entitlements: ["SocialFund"],
        whitelist: ['salford'],
        type: 'application'
      }, {
        id: "APPEAL_UPPER_TRIBUNAL",
        displayId: "APPEAL_UPPER_TRIBUNAL",
        name: "Appeal to the Upper Tribunal",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.gov.uk/administrative-appeals-tribunal',
        externalFormActionTitle: 'Find out more on GOV.UK',
        whitelist: ['leicester'],
        type: 'appeal'
      }, {
        id: "SSCS5",
        displayId: "SSCS5",
        name: "Appeal a Tax Credit, Child Benefit or Guardian's Allowance decision (SSCS5)",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.gov.uk/government/publications/appeal-an-hmrc-decision-on-a-benefit-or-credit-form-sscs5',
        externalFormActionIntro: 'Follow the link below to fill in a claim form online and print off for posting',
        externalFormActionTitle: 'Link to SSCS5 claim form',
        blacklist: ['enfield'],
        type: 'appeal'
      }, {
        id: "SCOTTISH_CHILD_PAYMENT",
        displayId: "SCP",
        name: "Scottish Child Payment",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.mygov.scot/scottish-child-payment',
        externalFormActionTitle: 'Apply on mygov.scot',
        whitelist: ['stirling'],
        type: 'application'
      }, {
        id: "SWF_STIRLING",
        displayId: "SWF_STIRLING",
        name: "Scottish Welfare Fund",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://lwa.financialassessments.co.uk/LocalWelfareAssistance/Home/Redirector/Index/?id=382e3bd3-8cb6-45ae-8f91-0a1ecf03e1d6&mod=OA',
        externalFormActionTitle: 'Apply online for the Scottish Welfare Fund',
        whitelist: ['stirling'],
        entitlements: ["SocialFund"],
        type: 'application'
      }, {
        id: "SSG",
        displayId: "SSG",
        name: "Single Support Grant for resettlement in Lewisham",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://lewisham.gov.uk/myservices/benefits/single-support-grant',
        whitelist: ['lewisham'],
        type: 'application'
      }, {
        id: "SHSF",
        displayId: "SHSF",
        name: "Somerset Household Support Fund",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://www.somerset.gov.uk/care-and-support-for-adults/somerset-household-support-fund/',
        whitelist: ['somerset'],
        entitlements: ["SocialFund"],
        type: 'application'
      }, {
        id: "SSP1",
        displayId: "SSP1",
        name: "Statutory Sick Pay and an employee’s claim for benefit (SSP1)",
        isBetterCareEligible: true,
        formService: "SSP1_FORM",
        formSchema: "SSP1_SCHEMA",
        has_interactive_PDF: true,
        has_flat_PDF: true,
        has_web_form: false,
        has_external_form: false,
        has_details_page: true,
        blacklist: ['coventry', 'derbyshire', 'southampton'],
        type: 'application'
      }, {
        id: "SSP",
        displayId: "SSP",
        name: "Statutory Sick Pay (SSP)",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.gov.uk/statutory-sick-pay',
        externalFormActionTitle: 'Find out more about SSP on GOV.UK',
        whitelist: ['derbyshire'],
        type: 'application'
      }, {
        id: "SMI",
        displayId: "SMI",
        name: "Support for Mortgage Interest",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.gov.uk/support-for-mortgage-interest',
        externalFormActionTitle: 'Find out more about claiming SMI here',
        blacklist: ['coventry', 'southampton'],
        entitlements: ["SupportMortgageInterestInfo"],
        type: 'application'
      }, {
        id: "X2_COUNCIL_TAX_REDUCTION_AND_HOUSING_BENEFIT_FORM",
        displayId: "X2_COUNCIL_TAX_REDUCTION_AND_HOUSING_BENEFIT_FORM",
        name: "Housing Benefit and Council Tax Support",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://enfieldclaim.entitledto.co.uk/',
        whitelist: ['enfield'],
        entitlements: ["CouncilTaxBenefit", "HousingBenefit"],
        type: 'application'
      }, {
        id: "UC_FULL_SERVICE",
        displayId: "UC_FULL_SERVICE",
        name: "Universal Credit (UC)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.gov.uk/universal-credit/how-to-claim',
        externalFormActionTitle: 'Apply for Universal Credit at gov.uk',
        blacklist: ['coventry', 'enfield', 'southampton'],
        entitlements: ["UniversalCredit"],
        type: 'application'
      }, {
        id: "UC50",
        displayId: "UC50",
        name: "Universal Credit - Capability for Work questionnaire (UC50)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://assets.publishing.service.gov.uk/media/6554a35f371898000dd29710/uc50-interactive.pdf',
        externalFormActionTitle: 'Download the UC50 form (PDF)',
        blacklist: ['coventry', 'southampton'],
        entitlements: ["UniversalCredit"],
        type: 'application'
      }, {
        id: "UC",
        name: "Universal Credit",
        isBetterCareEligible: true,
        has_external_form: true,
        external_link: 'https://www.gov.uk/universal-credit',
        whitelist: ['southampton'],
        type: 'application'
      }, {
        id: "WCSS",
        displayId: "WCSS",
        name: "Walsall Crisis Support Services (WCSS)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://go.walsall.gov.uk/benefits/extra-help-crisis-support-and-dhp',
        whitelist: ['walsall'],
        entitlements: ["SocialFund"],
        type: 'application'
      }, {
        id: "WHD",
        displayId: "WHD",
        name: "Warm Home Discount Scheme",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.gov.uk/the-warm-home-discount-scheme',
        externalFormActionTitle: 'Find out how to apply',
        entitlements: ["WarmHomesDiscount"],
        type: 'application'
      }, {
        id: "WWP",
        displayId: "WWP",
        name: "War Widow(er) Pension",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.gov.uk/war-widow-pension',
        externalFormActionTitle: 'Find out how to apply via GOV.UK',
        entitlements: ["WarWidow(er)PensionInfo"],
        type: 'application'
      }, {
        id: "WGS_SOUTHGLOS",
        displayId: "WGS_SOUTHGLOS",
        name: "Welfare Grant Scheme",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://beta.southglos.gov.uk/welfare-grant-scheme',
        whitelist: ['southglos'],
        entitlements: ["SocialFund"],
        type: 'application'
      }, {
        id: "WSF_TORBAY",
        displayId: "WSF_TORBAY",
        name: "Welfare Support Fund",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://www.torbay.gov.uk/benefits/other-help/welfare-support/',
        whitelist: ['torbay'],
        entitlements: ["SocialFund"],
        type: 'application'
      }, {
        id: "WPA",
        displayId: "WPA",
        name: "Widowed Parent's Allowance",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.gov.uk/widowed-parents-allowance',
        externalFormActionTitle: 'Find out how to apply via GOV.UK',
        entitlements: ["WidowedParentsAllowanceInfo"],
        type: 'application'
      }, {
        id: "WFP",
        displayId: "WFP",
        name: "Winter Fuel Payments",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: false,
        external_link: 'https://www.gov.uk/winter-fuel-payment',
        entitlements: ["WinterFuelPayments"],
        type: 'application'
      }, {
        id: "TC600",
        displayId: "TC600",
        name: "Working Tax Credits (TC600)",
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.gov.uk/working-tax-credit',
        externalFormActionTitle: 'Find out more about applying for Tax Credits here',
        whitelist: ['enfield'],
        entitlements: ["FinalTaxCreditAward"],
        type: 'application'
      }, {
        id: "TC600_FULL_SERVICE",
        displayId: "TC600_FULL_SERVICE",
        name: "Working Tax Credits (Full service UC)",
        isBetterCareEligible: true,
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: false,
        has_external_form: true,
        has_details_page: true,
        external_link: 'https://www.gov.uk/universal-credit/how-to-claim',
        externalFormActionIntro: 'Universal Credit has replaced Working Tax Credits in full service areas',
        externalFormActionTitle: 'Apply for Universal Credit instead',
        blacklist: ['coventry', 'enfield', 'southampton'],
        entitlements: ["FinalTaxCreditAward"],
        type: 'application'
      }, {
        id: "TO_FCA",
        name: "Financial Assessment Form (Adult Social Care)",
        formService: "TO_FCA_FORM",
        formSchema: "TO_FCA_SCHEMA",
        formConditions: "TO_FCA_CONDITIONS",
        formMappings: 'TO_FCA_MAPPINGS',
        defaultValidationMessage: $translate.instant('forms.validation.required'),
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: true,
        has_external_form: false,
        has_details_page: true,
        is_submit_once: true,
        evidence: {
          include_in_PDF: true,
          descriptions: [
            'Birth Certificate',
            'Marriage Certificate',
            'Driving licence',
            'Passport',
            'National identity card',
            'Utility bill',
            'National Insurance card',
            'Bank statement',
            'Bank statement showing your benefit/pension/income payments',
            'P45',
            'P60',
            'Payslip',
            'Salary statement',
            'Works pension statement',
            'Letter from Jobcentre Plus',
            'Letter from HM Revenue and Customs (HMRC)',
            'Letter from the Pension Service',
            'Mortgage statement',
            'Tenancy agreement',
            'Rent book',
            'Statement of charges from landlord',
            'Pension advice slip',
            'Benefit award notification letter',
            'Buildings insurance policy cover sheet',
            'Council Tax annual bill',
            'Council Tax Support statement and breakdown',
            'Court order confirming deputyship',
            'Court order for compulsory payment',
            'Letter from DWP confirming you as appointee',
            'Official Power of Attorney',
            'BF57 confirming you as appointee',
            'Proof of child support',
            'Proof of maintenance payments',
            'Proof of ownership of bond',
            'Proof of ownership of investment',
            'Proof of ownership of property',
            'Property valuation',
            'Screenshot of Universal Credit entitlement',
            'Share certificate',
            'Water bills',
            'Receipt',
            'Transfers / Gifting'
          ]
        },
        whitelist: ['torbay'],
        type: 'assessment',
        isBetterCareEligible: true
      },
      {
        id: "WO_FCA",
        name: "Financial Assessment Form (Adult Social Care)",
        formService: "WO_FCA_FORM",
        formSchema: "WO_FCA_SCHEMA",
        formConditions: "WO_FCA_CONDITIONS",
        formMappings: 'WO_FCA_MAPPINGS',
        defaultValidationMessage: $translate.instant('forms.validation.required'),
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: true,
        has_external_form: false,
        has_details_page: true,
        is_submit_once: true,
        evidence: {
          include_in_PDF: true,
          descriptions: [
            'Birth Certificate',
            'Marriage Certificate',
            'Driving licence',
            'Passport',
            'National identity card',
            'Utility bill',
            'National Insurance card',
            'Bank statement',
            'Bank statement showing your benefit/pension/income payments',
            'P45',
            'P60',
            'Payslip',
            'Salary statement',
            'Works pension statement',
            'Letter from Jobcentre Plus',
            'Letter from HM Revenue and Customs (HMRC)',
            'Letter from the Pension Service',
            'Mortgage statement',
            'Tenancy agreement',
            'Rent book',
            'Statement of charges from landlord',
            'Pension advice slip',
            'Benefit award notification letter',
            'Buildings insurance policy cover sheet',
            'Council Tax annual bill',
            'Council Tax Support statement and breakdown',
            'Court order confirming deputyship',
            'Court order for compulsory payment',
            'Letter from DWP confirming you as appointee',
            'Official Power of Attorney',
            'BF57 confirming you as appointee',
            'Proof of child support',
            'Proof of maintenance payments',
            'Proof of ownership of bond',
            'Proof of ownership of investment',
            'Proof of ownership of property',
            'Property valuation',
            'Screenshot of Universal Credit entitlement',
            'Share certificate',
            'Water bills',
            'Receipt',
            'Transfers / Gifting'
          ]
        },
        whitelist: ['wokingham'],
        type: 'assessment',
        isBetterCareEligible: true
      },
      {
        id: "EA_FCA",
        name: "Financial Assessment Form (Adult Social Care)",
        formService: "EA_FCA_FORM",
        formSchema: "EA_FCA_SCHEMA",
        formConditions: "EA_FCA_CONDITIONS",
        formMappings: 'EA_FCA_MAPPINGS',
        defaultValidationMessage: $translate.instant('forms.validation.required'),
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: true,
        has_external_form: false,
        has_details_page: true,
        is_submit_once: true,
        evidence: {
          include_in_PDF: true,
          descriptions: [
            'Birth Certificate',
            'Marriage Certificate',
            'Driving licence',
            'Passport',
            'National identity card',
            'Utility bill',
            'National Insurance card',
            'Bank statement',
            'Bank statement showing your benefit/pension/income payments',
            'P45',
            'P60',
            'Payslip',
            'Salary statement',
            'Works pension statement',
            'Letter from Jobcentre Plus',
            'Letter from HM Revenue and Customs (HMRC)',
            'Letter from the Pension Service',
            'Mortgage statement',
            'Tenancy agreement',
            'Rent book',
            'Statement of charges from landlord',
            'Pension advice slip',
            'Benefit award notification letter',
            'Buildings insurance policy cover sheet',
            'Council Tax annual bill',
            'Council Tax Support statement and breakdown',
            'Court order confirming deputyship',
            'Court order for compulsory payment',
            'Letter from DWP confirming you as appointee',
            'Official Power of Attorney',
            'BF57 confirming you as appointee',
            'Proof of child support',
            'Proof of maintenance payments',
            'Proof of ownership of bond',
            'Proof of ownership of investment',
            'Proof of ownership of property',
            'Property valuation',
            'Screenshot of Universal Credit entitlement',
            'Share certificate',
            'Water bills',
            'Receipt',
            'Transfers / Gifting'
          ]
        },
        whitelist: ['ealing'],
        type: 'assessment',
        isBetterCareEligible: true
      },
      {
        id: "WOL_FCA",
        name: "Financial Assessment Form (Adult Social Care)",
        formService: "WOL_FCA_FORM",
        formSchema: "WOL_FCA_SCHEMA",
        formConditions: "WOL_FCA_CONDITIONS",
        formMappings: 'WOL_FCA_MAPPINGS',
        defaultValidationMessage: $translate.instant('forms.validation.required'),
        has_interactive_PDF: false,
        has_flat_PDF: false,
        has_web_form: true,
        has_external_form: false,
        has_details_page: true,
        is_submit_once: true,
        evidence: {
          include_in_PDF: true,
          descriptions: [
            'Birth Certificate',
            'Marriage Certificate',
            'Driving licence',
            'Passport',
            'National identity card',
            'Utility bill',
            'National Insurance card',
            'Bank statement',
            'Bank statement showing your benefit/pension/income payments',
            'P45',
            'P60',
            'Payslip',
            'Salary statement',
            'Works pension statement',
            'Letter from Jobcentre Plus',
            'Letter from HM Revenue and Customs (HMRC)',
            'Letter from the Pension Service',
            'Mortgage statement',
            'Tenancy agreement',
            'Rent book',
            'Statement of charges from landlord',
            'Pension advice slip',
            'Benefit award notification letter',
            'Buildings insurance policy cover sheet',
            'Council Tax annual bill',
            'Council Tax Support statement and breakdown',
            'Court order confirming deputyship',
            'Court order for compulsory payment',
            'Letter from DWP confirming you as appointee',
            'Official Power of Attorney',
            'BF57 confirming you as appointee',
            'Proof of child support',
            'Proof of maintenance payments',
            'Proof of ownership of bond',
            'Proof of ownership of investment',
            'Proof of ownership of property',
            'Property valuation',
            'Screenshot of Universal Credit entitlement',
            'Share certificate',
            'Water bills',
            'Receipt',
            'Transfers / Gifting'
          ]
        },
        whitelist: ['wolverhampton'],
        type: 'assessment',
        isBetterCareEligible: true
      }
    ];

    // KERP-649: Apply any environment-specific form configuration
    angular.forEach(configuration.forms, function (envFormConfig) {

      var form = _.find(forms, function (form) {
        return form.id === envFormConfig.id;
      });

      if (form) {
        _.forOwn(envFormConfig, function (value, key) {

          if (key !== 'id') {
            form[key] = value;
            console.log('Environment-specific form config set "', key, '=', value, '" for form ID:', form.id);
          }
        });
      } else {
        console.error("Failed to apply environment-specific config for form ID: ", envFormConfig.id);
      }
    });

    var visibleForms = forms.filter(function (form) {

      // If we're running BetterCare, only consider those forms with isBetterCareEligible enabled
      if (configuration.isBetterCare && !form.isBetterCareEligible) {
        return false;
      }

      // some forms are restricted to certain customers
      var isCustomerApplicable = true;

      var hasBlacklist = Array.isArray(form.blacklist) && form.blacklist.length;
      var hasWhitelist = Array.isArray(form.whitelist) && form.whitelist.length;

      if (hasBlacklist && hasWhitelist) {
        throw new Error('A blacklist and a whitelist are specified for the form: ' + form.id +
          '. At least one of these lists must be removed.');

      } else if (hasBlacklist) {
        isCustomerApplicable = form.blacklist.indexOf(configuration.customer) === -1;

      } else if (hasWhitelist) {
        isCustomerApplicable = form.whitelist.indexOf(configuration.customer) > -1;
      }

      // if the form is a draft form then only shown it in environments that allow them
      var isDraftForm = _.get(form, 'is_draft_form', false);
      var isEnvApplicable = !isDraftForm || configuration.ui.showDraftForms;

      return isCustomerApplicable && isEnvApplicable;
    });

    return {
      getFormsByEntitlement: function (entitlement) {
        return this.getForms().filter(function (form) {
          return form.hasOwnProperty('entitlements') && form.entitlements.indexOf(entitlement) > -1;
        });
      },

      /**
       * Get the configuration of a form by ID
       * @param formId
       * @returns {*} a single form's configuration
       */
      getForm: function (formId) {
        var matchingForms = this.getForms().filter(function (form) {
          return form.id === formId;
        });

        if (matchingForms.length === 1) {
          return matchingForms[0];
        } else {
          throw new Error('No form found with ID: ' + formId);
        }
      },

      /**
       * Get the configuration of all visible forms
       * @returns {Array.<*>} a list of form configurations
       */
      getForms: function () {
        return visibleForms;
      }
    };
  }]);
